import { Component, OnInit } from '@angular/core';
import {ReceiptService} from 'app/services/receipt.service';
import { Receipt } from 'app/admin/receipt';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'ngx-editreceipt',
  templateUrl: './editreceipt.component.html',
  styleUrls: ['./editreceipt.component.scss']
})
export class EditreceiptComponent implements OnInit {

  editrecform: FormGroup;
  title = 'Autocomplete';
  autoCompletArr = new Array<Receipt>();
  constructor(private fb: FormBuilder,private dataService: ReceiptService,private router:Router) {
    

    
    
   }

  ngOnInit() { 
    this.editrecform = new FormGroup({
      r_id: new FormControl(),
      case_no: new FormControl(),
      case_name: new FormControl(),
      client_name: new FormControl(),
      rdate: new FormControl(),
      agreed_amount: new FormControl(),
      paid: new FormControl(),
      due: new FormControl(),
      balance: new FormControl()

   });
  if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');
    let user_id = localStorage.getItem('user_id');
    let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['admin/expense']);
      return;
    }
  this.dataService.getReceipt(+Id,client_id)
      .subscribe( data => {
       //this.viewform.controls[this.client_mobile].setValue('client_name')
        //this.client_email.nativeElement.value = 'This is new value';
        this.editrecform.patchValue({
          r_id:data[0].r_id, case_no: data[0].case_no, case_name: data[0].case_name, client_name: data[0].client_name,rdate: data[0].rdate,agreed_amount: data[0].agreed_amount,paid: data[0].paid,due: data[0].due,balance: data[0].balance
       });
      });

    }

  $(document).on('click', function (event) {
    if (!$(event.target).closest('#spnauto').length ) {
      $('#ulautocomplete').hide();
    }
    else{
      $('#ulautocomplete').show();
    }
    
  });

}

autoComplete($event) {

  if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');
    let user_id = localStorage.getItem('user_id');
  let case_no = (<HTMLInputElement>document.getElementById('case_no')).value;
  
  if (case_no.length > 2) {
  this.dataService.autoComplete(client_id,case_no).subscribe(response =>
    {
      this.autoCompletArr = response.map(item =>
      {
         return new Receipt(
         
          item.r_id,
          item.or_id,
          item.client_id,
          item.user_id,
          item.receipt_no,
          item.rdate,
            item.case_no,
            item.paid,
            item.due,
            item.balance,
            item.case_name,
            item.firm_name,
            item.client_name,
            item.agreed_amount,
            item.total_due,
            item.total_paid,
            item.paid,
            item.installment
          
            
              
        );
      });
      
      
     
    });
    
  }

  }
  
}

  back(): void {
    this.router.navigate(['admin/receipt']);
  };

  showcase(case_no)
  {
 
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.getcasedetails(client_id,case_no)
              .subscribe((data: any) => {
                this.editrecform.patchValue({
                  case_name:data[0].case_name, client_name: data[0].client_name, agreed_amount: data[0].agreed_amount,due: data[0].due, balance: data[0].balance
         
               });
               
            });
          
          }

  }

  setValue(index,$event)
  {
    (<HTMLInputElement>document.getElementById('case_no')).value= this.autoCompletArr[index].case_no;
     this.autoCompletArr = [];
     $('#ulautocomplete').hide();
      $event.stopPropagation();
  }

  showdue(paid,agreed_amount,balance)
  {
    
   let total_balance=parseInt(balance)+ parseInt(paid);
   let due=parseInt(agreed_amount)-(parseInt(balance)+ parseInt(paid));
   this.editrecform.patchValue({
    due:due,balance:total_balance

 });
 
  }

  editreceipt(editrecform)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(editrecform.value.rdate);
      let d = ("0" + as.getDate()).slice(-2);
      let m = ("0" + (as.getMonth() + 1)).slice(-2);
      let y = as.getFullYear();
      let c_date= y + "-" + m + "-" + d;
      
    
    this.dataService.updateReceipt(editrecform.value.r_id,user_id,editrecform.value.case_no,editrecform.value.case_name,editrecform.value.client_name,c_date,editrecform.value.paid,editrecform.value.due,editrecform.value.balance,client_id)
 
    .pipe()
    .subscribe(
        data => {
            this.router.navigate(['admin/receipt']);
        },
        error => {
        });
      }
  }
         
  get r_id() { return this.editrecform.get('r_id'); }
  get case_no() { return this.editrecform.get('case_no'); }
  get case_name() { return this.editrecform.get('case_name'); }
  get client_name() { return this.editrecform.get('client_name'); }
  get rdate() { return this.editrecform.get('rdate'); }
  get paid() { return this.editrecform.get('paid'); }
  get due() { return this.editrecform.get('due'); }
  get balance() { return this.editrecform.get('balance'); }

 

}
