import { Component, OnInit } from '@angular/core';
import {ReceiptService} from 'app/services/receipt.service';
import { Receipt } from 'app/admin/receipt';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'ngx-addreceipt',
  templateUrl: './addreceipt.component.html',
  styleUrls: ['./addreceipt.component.scss']
})
export class AddreceiptComponent implements OnInit {

  recform: FormGroup;
  title = 'Autocomplete';
  autoCompletArr = new Array<Receipt>();
  due1:string;
  balance1:string;
  clientNames:any= [];
  caseNames:any= [];
  constructor(private fb: FormBuilder,private dataService: ReceiptService,private router:Router) {
   
   }

  ngOnInit() { 
    this.recform = new FormGroup({
      case_no: new FormControl(),
      case_name: new FormControl(),
      client_name: new FormControl(),
      rdate: new FormControl(),
      agreed_amount: new FormControl(),
      paid: new FormControl(),
      due: new FormControl(),
      balance: new FormControl(),
       installment: new FormControl()

   });

   this.recform = this.fb.group({
    case_no: ['', Validators.required],
    case_name: ['', Validators.required],
    client_name: ['', Validators.required],
    agreed_amount: [],
    paid:  ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
    rdate: ['', Validators.required],
    due: [],
    balance: [],
    installment:['', Validators.required]
    
   
  });

  if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');
    let user_id = localStorage.getItem('user_id');
  this.dataService.getclientnames(client_id)
              .subscribe((data: any) => {
                this.clientNames=data;
               
            });
   }

}

get f(){
  return this.recform.controls;
}

autoComplete($event) {

  if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');
    let user_id = localStorage.getItem('user_id');
  let case_no = (<HTMLInputElement>document.getElementById('case_no')).value;
  
  if (case_no.length > 2) {
  this.dataService.autoComplete(client_id,case_no).subscribe(response =>
    {
      this.autoCompletArr = response.map(item =>
      {
         return new Receipt(
         
          item.r_id,
          item.or_id,
          item.client_id,
          item.user_id,
          item.receipt_no,
          item.rdate,
            item.case_no,
            item.paid,
            item.due,
            item.balance,
            item.case_name,
            item.firm_name,
            item.client_name,
            item.agreed_amount,
            item.total_due,
            item.total_paid,
            item.paid,
            item.installment
          
              
        );
      });
      
      
     
    });
    
  }

  }
  
}

  back(): void {
    this.router.navigate(['admin/receipt']);
  };

  showcase(client_name)
  {
   
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
  

      this.dataService.getcasenames(client_id,client_name)
          .subscribe((data: any) => {
            this.caseNames=data;
           
        });

        
    }

  }

  showcaseno(case_name,client_name)
  {
    
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');


        this.dataService.getcaseno(client_id,client_name,case_name)
    .subscribe( data => {
      
          this.recform.patchValue({
            case_no:data[0].case_no
          });

          this.dataService.getcasedetails(client_id,data[0].case_no)
          .subscribe((data: any) => {
            this.recform.patchValue({
              agreed_amount: data[0].agreed_amount,due: data[0].due, balance: data[0].balance
      
           });
           this.due1=data[0].due;
            this.balance1=data[0].balance;
           
        });

      
    });
   
    }
  }

  showdue(paid,agreed_amount,balance)
  {
   let total_balance=parseInt(this.balance1)+ parseInt(paid);
   let due=parseInt(agreed_amount)-(parseInt(this.balance1)+ parseInt(paid));
   this.recform.patchValue({
    due:due,balance:total_balance

 });
 
  }
  addreceipt(recform)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(recform.value.rdate);
    let d = ("0" + as.getDate()).slice(-2);
    let m = ("0" + (as.getMonth() + 1)).slice(-2);
    let y = as.getFullYear();
    let rdate= y + "-" + m + "-" + d;

      this.dataService.validatecasereceipt(recform.value.case_no,recform.value.case_name,recform.value.client_name,client_id)
      .pipe()
      .subscribe(
        
          data => {
            if(data[0]["result"]=="Already Inserted")
            {
              alert("Total amount is already received");
            }
          
            else if(data[0]["result"]=="Still Due"){


   this.dataService.addReceipt(user_id,recform.value.case_no,recform.value.case_name,recform.value.client_name,rdate,recform.value.paid,recform.value.due,recform.value.balance,recform.value.installment,client_id)
      .pipe()
      .subscribe(
          data => {
              this.router.navigate(['admin/receipt']);
          },
          error => {
          });
              
            } 
              
          },
          
          error => {
          });
        
    
        }
  }

  
         
  
  get case_no() { return this.recform.get('case_no'); }
  get case_name() { return this.recform.get('case_name'); }
  get client_name() { return this.recform.get('client_name'); }
  get rdate() { return this.recform.get('rdate'); }
  get paid() { return this.recform.get('paid'); }
  get due() { return this.recform.get('due'); }
  get balance() { return this.recform.get('balance'); }

 

}
