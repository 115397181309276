import { Component, OnInit } from '@angular/core';
import {TaskService} from 'app/services/task.service';
import { Task } from 'app/admin/task';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-edittask',
  templateUrl: './edittask.component.html',
  styleUrls: ['./edittask.component.scss']
})
export class EdittaskComponent implements OnInit {

  edittaskform: FormGroup;
  employees:any= [];
  caseNames:any= [];
  case:boolean=false;

  constructor(private fb: FormBuilder,private dataService: TaskService,private router:Router) { }

  ngOnInit(){
    this.edittaskform = new FormGroup({
      task_id: new FormControl(),
      emp_name: new FormControl(),
      task_name: new FormControl(),
      task_date: new FormControl(),
      description: new FormControl(),
      case_name: new FormControl(),
      start_date:new FormControl(),
      end_date: new FormControl(),
      status: new FormControl(),
      note: new FormControl()

    

   });
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
    this.dataService.getemp(client_id)
              .subscribe((data: any) => {
                this.employees=data;
               
            });
            this.dataService.getcasenames(client_id)
            .subscribe((data: any) => {
              this.caseNames=data;
             
          });

          let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['employee/task']);
      return;
    }
    this.dataService.gettaskId(+Id,client_id)
      .subscribe( data => {
       //this.viewform.controls[this.client_mobile].setValue('client_name')
        //this.client_email.nativeElement.value = 'This is new value';
        if(data[0].case_name==null || data[0].case_name=="" )
       {
         this.case=false;
        this.edittaskform.patchValue({
          task_id:data[0].task_id,emp_name: data[0].emp_id, task_name: data[0].task_name, task_date: data[0].task_date,description: data[0].description,status: data[0].status,start_date: data[0].start_date,end_date: data[0].end_date,note: data[0].note
      
       });

       }
       else
       {
         this.case=true;
        this.edittaskform.patchValue({
          task_id:data[0].task_id, case_name: data[0].case_name, emp_name: data[0].emp_id, task_name: data[0].task_name, task_date: data[0].task_date,description: data[0].description,status: data[0].status,start_date: data[0].start_date,end_date: data[0].end_date,note: data[0].note
      
       });
       }
      });
          }
  }

  edittask(edittaskform)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
      
      var as=new Date(edittaskform.value.start_date);
      let d = ("0" + as.getDate()).slice(-2);
      let m = ("0" + (as.getMonth() + 1)).slice(-2);
      let y = as.getFullYear();
      let start_date= y + "-" + m + "-" + d;

      var as1=new Date(edittaskform.value.end_date);
      let d1 = ("0" + as1.getDate()).slice(-2);
      let m1 = ("0" + (as1.getMonth() + 1)).slice(-2);
      let y1 = as1.getFullYear();
      let end_date= y1 + "-" + m1+ "-" + d1;

      var as2=new Date(edittaskform.value.task_date);
      let d2 = ("0" + as2.getDate()).slice(-2);
      let m2 = ("0" + (as2.getMonth() + 1)).slice(-2);
      let y2 = as2.getFullYear();
      let task_date= y2 + "-" + m2 + "-" + d2;
    
    this.dataService.updatetasksemp(edittaskform.value.task_id,user_id,edittaskform.value.emp_name,edittaskform.value.case_name,task_date,edittaskform.value.task_name,edittaskform.value.description,edittaskform.value.status,start_date,end_date,edittaskform.value.note,client_id)
 
    .pipe()
    .subscribe(
        data => {
            this.router.navigate(['employee/task']);
        },
        error => {
        });
      }
  }

          back(): void {
            this.router.navigate(['employee/task']);
          };
          get case_name() { return this.edittaskform.get('case_name'); }
          get task_date() { return this.edittaskform.get('task_date'); }
          get task_name() { return this.edittaskform.get('task_name'); }
          get description() { return this.edittaskform.get('description'); }
          get note() { return this.edittaskform.get('note'); }
          get emp_name() { return this.edittaskform.get('emp_name'); }
          get status() { return this.edittaskform.get('status'); }
          get start_date() { return this.edittaskform.get('start_date'); }
          get end_date() { return this.edittaskform.get('end_date'); }

}
