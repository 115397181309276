<div class="row"  >
    <div class="col-md-3" >
    </div>
    <div class="col-md-6 col-md-offset-3" >
        <div class="jumbotron" style="float:center">
            <div class="container-fluid">
            <h5 class="text-center">Login</h5>
            <br>
            <form [formGroup]="loginform" (ngSubmit)="testdata(loginform)"  autocomplete="off" >
                <div class="form-group">
                    
                    <div class="input-container">
                        <i class="fa fa-envelope icon"></i>
                    <input type="email" name="email" formControlName="email" autocomplete="off" class="form-control input-sm" placeholder="Email Address">
                    
                    
                    
                </div>
                <div *ngIf="f.email.touched && f.email.invalid" class="error">
                    <div *ngIf="f.email.errors.required" style="color:red" >Email is required.</div>
                    <div *ngIf="f.email.errors.email" style="color:red">Enter valid email id</div>
                   
                </div>
                </div>
                <div class="form-group">

                    
                    <div class="input-container">
                        <i class="fa fa-key icon"></i>
                    <input  [type]="fieldTextType ? 'text' : 'password'" name="Password" formControlName="password"  autocomplete="off" class="form-control input-sm" placeholder="Password">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i
                            class="fa"
                            [ngClass]="{
                              'fa-eye-slash': !fieldTextType,
                              'fa-eye': fieldTextType
                            }"
                            (click)="toggleFieldTextType()"
                          ></i>
                        </span>
                     </div>
                </div>
                <div *ngIf="f.password.touched && f.password.invalid" class="error">
                    <div *ngIf="f.password.errors.required" style="color:red">Password is required.</div>
                </div> 
                     
                </div>
                <button type="submit" class="btn" [disabled]="!loginform.valid">Login</button><br><br>
                <p style="color:gray"><a (click)="forgotpassword()" style="cursor:pointer">Forgot Password?</a></p>
            </form>
        </div>
    </div>
    </div>
   
    <div class="col-md-3">
    </div>
 
</div>









