import { Component, OnInit } from '@angular/core';
import {ReceiptService} from 'app/services/receipt.service';
import { Receipt } from 'app/admin/receipt';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';


@Component({
  selector: 'ngx-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {
  receipts: Receipt[];
  otherreceipts: Receipt[];
  cat:number;
  public searchText:string;
  constructor(private dataService: ReceiptService ,private router:Router) { }

  ngOnInit() {
    this.getrecdetails();
    this.getotherrecdetails();
  }
  getrecdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getallreceipts(this.cat,client_id).subscribe(response =>
      {
        this.receipts = response.map(item =>
        {
          return new Receipt(
            item.r_id,
            item.or_id,
            item.client_id,
            item.user_id,
            item.receipt_no,
            item.rdate,
              item.case_no,
              item.paid,
              item.due,
              item.balance,
              item.case_name,
              item.firm_name,
              item.client_name,
              item.agreed_amount,
              item.total_due,
              item.total_paid,
              item.paid,
              item.installment    
          );
        });
      });

    }
  }

  getotherrecdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getallotherreceipts(this.cat,client_id).subscribe(response =>
      {
        this.otherreceipts = response.map(item =>
        {
          return new Receipt(
            item.r_id,
            item.or_id,
            item.client_id,
            item.user_id,
            item.receipt_no,
            item.rdate,
            item.case_no,
              item.paid,
              item.due,
              item.balance,
              item.case_name,
              item.firm_name,
              item.client_name,
              item.agreed_amount,
              item.total_due,
              item.total_paid,
              item.paid,
              item.installment
            
              
              
          );
        });
      });

    }
  }
  
  addrec(): void {
    this.router.navigate(['admin/addreceipt']);
  };
  back(): void {
    this.router.navigate(['admin/receipt']);
  };
  
  viewrec(emp: Receipt): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", emp.r_id.toString());
    this.router.navigate(['admin/viewreceipt']);
  };
  deleterec(emp:Receipt)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removeReceipt(emp.r_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getrecdetails();
    })
  }
  }
  updaterec(emp: Receipt): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", emp.r_id.toString());
    this.router.navigate(['admin/editreceipt']);
  };

  otherreceipt(): void {
    this.router.navigate(['admin/otherreceipts']);
  };

  receipt(): void {
    this.router.navigate(['admin/receipt']);
  };

  addotherrec(): void {
    this.router.navigate(['admin/addotherreceipts']);
  };
  otherback(): void {
    this.router.navigate(['admin/otherreceipts']);
  };
  
  viewotherrec(emp: Receipt): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", emp.or_id.toString());
    this.router.navigate(['admin/viewotherreceipts']);
  };
  deleteotherrec(emp:Receipt)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removeotherReceipt(emp.or_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getotherrecdetails();
    })
  }
  }

  printrec(data)
  {
    if (localStorage.length > 0) {
      let user_id = localStorage.getItem('user_id');
      let username = localStorage.getItem('name');
      let client_id = localStorage.getItem('client_id');
      this.dataService.getheader(client_id)
      .subscribe((data4: any) => {

    var path="https://kaalacoat.com/logo/";
    var logo=data4[0].logo;
    var fullpath=path.concat(logo);

    this.dataService.getReceipt(data.r_id,client_id)
    .subscribe((data: any) => {
    var product="";
      let len=data.length;
 
      for(var i=0;i<len;i++)
      {
         product=product
  
         +"<tr>"
         +"<td style='border:1px solid gray;text-align: center;font-weight:normal;padding-top:5px;padding-bottom:10px;padding-left:5px;'>"+(i+1)+"</td>"				
           +"<td  style='border:1px solid gray;width:30%;text-align: left;font-weight:normal;padding-top:5px;padding-bottom:10px;padding-left:5px;'>"+data[i]["case_name"]+"<br>"+data[i]["installment"]+"</td>"
           +"<td style='border:1px solid gray;text-align: left;font-weight:normal;padding-top:5px;padding-bottom:10px;padding-left:5px;'><b>"+data[i]["paid"]+"/-</b></td>"		
           +"</tr>"

      }

    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
		
    mywindow.document.write('<html><head><meta cha$et="utf-8" /><style type="text/css">body {-webkit-print-color-adjust: exact; font-family: Arial; }</style> ');
    mywindow.document.write('</head><body>');
    mywindow.document.write("<table style='width:100%;'>"						    							
    +"<tr>"
    +"<td style='width:25%;'><center><img src='"+fullpath+"'  style='weight: 115px; height:75px;'></center></td>"
    +"<td style='width:10%;'></td>"
    +"<td style='width:65%;text-align:left'><span style='color:black;font-size:12px;'><b>Office: "+data4[0].client_address+"</b></span><br><hr><span style='color:black;font-size:12px;'><b>Contact: "+data4[0]["client_mobile"]+"</span>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp<span style='color:black;font-size:14px;line-height:1.8'>Email: "+data4[0]["client_email"]+"</span></td>"
    +"</tr>"		    						
    +"</table>");
    mywindow.document.write("<label style='float:right;font-size:14px'><b>Date: "+data[0]["r_date"]+"</b></label>");
    mywindow.document.write("<label style='float:left;font-size:14px;line-height:1.8'><b>To,<br>"+data[0]["client_name"]+ ", <br>Subject: Receipt</b><br><br>Sir/Madam,</label><br><br><br><br>");
    mywindow.document.write("<br><br><br><label style='text-align:center;font-size:16px;line-height:1.6'><center><u>Receipt</u></center></label><br>");
    mywindow.document.write("<table style='width:100%;border:1px solid gray;border-collapse: collapse;'>"
     +"<tr>"
     +"<th style='text-align:left;padding-left:3px;padding-top:3px;padding-bottom:3px' border-left:1px gray solid;border-top:1px gray solid'><h5 style='margin:0px;'>Sr.No</h5></th>"
     +"<th style='text-align:left;width:50%;padding-left:3px;padding-top:3px;padding-bottom:3px; border-left:1px gray solid;border-top:1px gray solid'><h5 style='margin:0px;'>Particulars</h5></th>"
     +"<th style='text-align:left;padding-left:3px;padding-top:3px;padding-bottom:3px; border-left:1px gray solid;border-top:1px gray solid'><h5 style='margin:0px;'>Amount (in Rs.)</h5></th>"
     +"</tr>"
     +product
     +"<tr>"
     +"<td colspan=2 style='text-align: right;padding-top:3px;padding-bottom:3px'><h5 style='margin:0px;'></h5></td>"
    +"<td  style='text-align: left;padding-left:10px;padding-top:3px;padding-bottom:3px;border-left:1px gray solid'><h5 style='margin:0px;'><b> "+data[0]["paid"]+" ("+data[0]["amount_in_words"]+")</b></h5></td>"
     +"</tr>"     
     +"</tr>"
     +"</table>");
                 mywindow.document.write("<br><br><label style='float:left;font-size:14px'>Received with Thanks.</label>");
                 mywindow.document.write("<br><br><br><br><br><label style='float:right;font-size:14px'><b>Yours sincerely,</b></label>");
                 mywindow.document.write("<br><label style='float:right;text-align:left;font-size:14px'><br><br><b>Kalpak Mainkar, Advocate.<br>LEGAL ARMA</b></label>");
                
    mywindow.document.write('</body></html>');
    mywindow.document.close();
    mywindow.focus(); 
    setTimeout(function() {
     mywindow.print();
     mywindow.close();
 }, 250); 
   
      });
    });

  }
}
  
  }