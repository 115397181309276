import { Component, OnInit } from '@angular/core';
import {CourtService} from 'app/services/court.service';
import { Court } from 'app/admin/court';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-addcourt',
  templateUrl: './addcourt.component.html',
  styleUrls: ['./addcourt.component.scss']
})
export class AddcourtComponent implements OnInit {
  courtform: FormGroup;
  roles:any[]= [];
  
  constructor(private fb: FormBuilder,private dataService: CourtService,private router:Router) {
    

    
   }
  

  ngOnInit() {
    this.courtform = new FormGroup({
      court_name: new FormControl(),
      judge_name: new FormControl(),
      court_type: new FormControl(),
      city: new FormControl(),
      
   });
    this.courtform = this.fb.group({
      court_name: ['', Validators.required],
      court_type: ['', Validators.required],
      judge_name: ['', Validators.required],
      city: ['', Validators.required]
    });
  }
  back(): void {
    this.router.navigate(['admin/court']);
  };

  get f(){
    return this.courtform.controls;
  }

  createcourt(courtform)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      this.dataService.validatecourt(courtform.value.court_name,client_id)
      .pipe()
      .subscribe(
        
          data => {
            if(data[0]["result"]=="Already Inserted")
            {
              alert("Court already exists");
            }
            else{
    this.dataService.addCourts(courtform.value.court_name,courtform.value.judge_name,courtform.value.court_type,courtform.value.city,client_id)
      .pipe()
      .subscribe(
          data => {
              this.router.navigate(['admin/court']);
          },
          error => {
          });
        }
        },
        error => {
        });
    }
  }
  

  get court_name() { return this.courtform.get('court_name'); }
  get court_type() { return this.courtform.get('court_type'); }
  get judge_name() { return this.courtform.get('judge_name'); }
  get city() { return this.courtform.get('city'); }
  
 

}



