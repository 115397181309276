<nb-card>
    <nb-card-header><h5>Calls</h5></nb-card-header>
    <nb-card-body>
  <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
        </div>
        </div>   
        <div class="col-md-6">
          <button class="btn btn-primary"  (click)="addcalls()" style="float:right"> Add </button>
        </div>    
        <div class="col-md-12">
          <!--<h2 class="text-center" >Clients</h2>-->
          
          <br><br>
          <table class="table table-hover" >
            <thead>
            <tr>
              <th>Sr.No</th>
              <th>Employee</th>
              <th>Case Name</th>
              <th>Task Name</th>
              <th>Call Date</th>
              <th>Status</th>  
              <th>Action</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let t of calls | filter: searchText; let i = index"  >
     
              <td>{{i + 1}}</td>
              <td>{{t.emp_id}}</td>
              <td>{{t.case_name}}</td>
              <td>{{t.task_name}}</td>
              <td>{{t.call_date}}</td>
              <td>{{t.status}}</td>
              <td><a (click)="editstatus(t)" *ngIf="t.status=='Open'">
                <i class="fa fa-check" style="color: #00A0F0;font-size: 17px;margin-left:10px;" ></i></a>
                <div [style.display]="viewformModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="viewModal" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h6 class="modal-title" id="viewModal">Court Task</h6>
                      </div>
                      <div class="modal-body">
                        <form name="form" [formGroup]="callsform"  (ngSubmit)="statusupdate(callsform)"  autocomplete="off" role="form" >
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="status" class="label">Status*</label>
                                <input type="hidden"  nbInput fullWidth id="call_id" name="call_id" formControlName="call_id" autocomplete="off" >
                                <select name="status" formControlName="status" class="form-control" >
                                  <option value="Open" >Open</option>
                                  <option value="Close">Close</option>
                                </select>
                              </div>
                            </div>
                          </div>
                         
                 <button type="submit" class="btn btn-primary" style="float:right">Update</button>
                 
                    </form>
     </div>
               
                      <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click) = "hide()">Cancel</button>
                      </div>
                    </div>
                  </div>
                  </div>  </td>
                <td><a (click)="updatecalls(t)"  >
                  <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a>  </td>
                  <td><a (click)="deletecalls(t)" >
                    <i class="fa fa-trash" style="color:maroon;font-size: 17px;margin-left:10px;"></i></a>  </td>
              </tr>
            </tbody>
          </table>
          <!--<div class="alert alert-info alert-dismissible" role="alert" >
            <i class="fa fa-info-circle"></i> 
            No Records found
          </div>-->
        </div>
      </div>
    </div>
    </nb-card-body>
    </nb-card>
  
