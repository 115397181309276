export class Court {
    public court_id: number;
    public client_id:string;
    public court_name: string;
    public judge_name:string;
    public court_type:string;
    public city:string;
    
    
    
    constructor(court_id:number,client_id:string,court_name: string,judge_name:string,court_type:string,city:string) {
      this.court_id = court_id;
      this.client_id=client_id;
      this.court_name=court_name;
      this.judge_name=judge_name;
      this.court_type = court_type;
      this.city=city;
      
    }
}
      