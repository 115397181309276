import { Component, OnInit } from '@angular/core';
import {SelftaskService} from 'app/services/selftask.service';
import {Selftask} from 'app/employee/selftask'
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-addselftask',
  templateUrl: './addselftask.component.html',
  styleUrls: ['./addselftask.component.scss']
})
export class AddselftaskComponent implements OnInit {
  addtaskform: FormGroup;
  employees:any= [];
  caseNames:any= [];
  case:boolean=false;

  constructor(private fb: FormBuilder,private dataService: SelftaskService,private router:Router) { }

  ngOnInit(){
    this.case=true;
    this.addtaskform = new FormGroup({
      task_name: new FormControl(),
      start_date: new FormControl(),
      end_date: new FormControl(),
      description: new FormControl(),
      case_name: new FormControl(),
      status: new FormControl()
   });

   this.addtaskform = this.fb.group({
    selecttype: ['casetype'],
    task_name: ['', Validators.required],
    start_date: ['', [Validators.required]],
    end_date: [''],
    description: [''],
    case_name: [''],
    status: ['Open', Validators.required],

    
   
  });
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
            this.dataService.getcasenames(client_id)
            .subscribe((data: any) => {
              this.caseNames=data;
             
          });
          }
  }

  get f(){
    return this.addtaskform.controls;
  }

  showcase(selecttype)
  {
    if(selecttype=="casetype")
    {
      this.case=true;
    }
    else
    {
      this.case=false;
    }
  }


          back(): void {
            this.router.navigate(['employee/selftask']);
          };
        
          createtask(addtaskform)
          {
            if (localStorage.length > 0) {
              let client_id = localStorage.getItem('client_id');
              let username = localStorage.getItem('name');
              let user_id = localStorage.getItem('user_id');

              var as=new Date(addtaskform.value.start_date);
      let d = ("0" + as.getDate()).slice(-2);
      let m = ("0" + (as.getMonth() + 1)).slice(-2);
      let y = as.getFullYear();
      let start_date= y + "-" + m + "-" + d;

      var as1=new Date(addtaskform.value.end_date);
      let d1 = ("0" + as1.getDate()).slice(-2);
      let m1 = ("0" + (as1.getMonth() + 1)).slice(-2);
      let y1 = as1.getFullYear();
      let end_date= y1 + "-" + m1+ "-" + d1;

                      this.dataService.addselftasks(user_id,addtaskform.value.case_name,start_date,end_date,addtaskform.value.task_name,addtaskform.value.description,addtaskform.value.status,client_id)
              .pipe()
              .subscribe(
                  data => {
                      this.router.navigate(['employee/selftask']);
                  },
                  error => {
                  });
                      
                    } 
                
                
            
                  }
        
          get case_name() { return this.addtaskform.get('case_name'); }
          get start_date() { return this.addtaskform.get('start_date'); }
          get task_name() { return this.addtaskform.get('task_name'); }
          get end_date() { return this.addtaskform.get('end_date'); }
          get description() { return this.addtaskform.get('description'); }
        
        }
        