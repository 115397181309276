import { Component, OnInit } from '@angular/core';
import {SelftaskService} from 'app/services/selftask.service';
import {Selftask} from 'app/employee/selftask';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-selftask',
  templateUrl: './selftask.component.html',
  styleUrls: ['./selftask.component.scss']
})
export class SelftaskComponent implements OnInit {
  selftasks: Selftask[];
  cat:number;
  public searchText:string;
  constructor(private dataService: SelftaskService ,private router:Router) { }

  ngOnInit(): void {
    this.gettaskdetails();
  }
  gettaskdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.getalltasks(this.cat,client_id,user_id).subscribe(response =>
      {
        this.selftasks = response.map(item =>
        {
          return new Selftask(
            item.selftask_id,
            item.client_id,
            item.user_id,
            item.emp_id,
             item.case_name,
              item.task_name, 
              item.description, 
              item.start_date,
              item.end_date,
              item.status
          );
        });
      });

    }
  }
  
  back(): void {
    this.router.navigate(['employee/selftask']);
  };
  
  addselftask(): void {
    this.router.navigate(['employee/addselftask']);
  };
  viewselftask(c: Selftask): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.selftask_id.toString());
    this.router.navigate(['employee/viewselftask']);
  };

  deleteselftask(c:Selftask)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.removeselftasks(c.selftask_id,client_id,user_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.gettaskdetails();
    })
  }
  }
  
  updateselftask(c: Selftask): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.selftask_id.toString());
    this.router.navigate(['employee/editselftask']);
  };
  
  }