<nb-card>
    <nb-card-header><h5>Tasks</h5></nb-card-header>
    <nb-card-body>

      <ul class="nav nav-pills">
        <li class="nav-item">
          <a class="nav-link  " data-toggle="tab" href="#panel11" (click)="task()" role="tab" style="text-decoration:none;">Court Tasks</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active " data-toggle="tab" href="#panel12" (click)="othertask()"  role="tab" style="text-decoration:none;">Other Tasks</a>
        </li>
        </ul>

        <div class="tab-content pt-0">

          <!--Panel 1-->
          <div class="tab-pane fade  " id="panel11" role="tabpanel">
            <br>

            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
                </div>
                </div>   
                  
                <div class="col-md-12">
                  <!--<h2 class="text-center" >Clients</h2>-->
                  
                  <br><br>
                  <table class="table table-hover" >
                    <thead>
                    <tr>
             
                      <th>Sr.No</th>
                      <th>Task Date</th>
                      <th>Case Name</th>
                      <th>Employee</th>
                      <th>Task Name</th>
                      <th>Status</th>
                      <th>Note</th>
                      
                      <th>View</th>
                      <th>Edit</th>
                      <th>Delete</th>
                      
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let t of tasks | filter: searchText; let i = index"  >
             
                      <td>{{i + 1}}</td>
                      <td>{{t.task_date}}</td>
                      <td>{{t.case_name}}</td>
                      <td>{{t.emp_id}}</td>
                      <td>{{t.task_name}}</td>
                      <td>{{t.status}}</td>
                      <td>{{t.note}}</td>
                      <td><a (click)="viewtask(t)" >
                        <i class="fa fa-eye" style="color: #00A0F0;font-size: 17px;margin-left:10px;" ></i></a>  </td>
                        <td><a (click)="updatetask(t)"  >
                          <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a>  </td>
                          <td><a (click)="deletetask(t)" >
                            <i class="fa fa-trash" style="color:maroon;font-size: 17px;margin-left:10px;"></i></a>  </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--<div class="alert alert-info alert-dismissible" role="alert" >
                    <i class="fa fa-info-circle"></i> 
                    No Records found
                  </div>-->
                </div>
              </div>
            </div>
          </div>
          <!--/.Panel 1-->
          <div class="tab-pane fade in show active " id="panel12" role="tabpanel">
            <br>
        
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
                </div>
                </div>       
                <div class="col-md-12">
                  <!--<h2 class="text-center" >Clients</h2>-->
                  
                  <br><br>
                  <table class="table table-hover" >
                    <thead>
                    <tr>
             
                      <th>Sr.No</th>
                      <th>Task Date</th>
                      <th>Company Name</th>
                      <th>Employee</th>
                      <th>Task Name</th>
                      <th>Status</th>
                      <th>Note</th>
                      
                      <th>View</th>
                      <th>Edit</th>
                      <th>Delete</th>
                      
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let t of othertasks | filter: searchText; let i = index"  >
             
                      <td>{{i + 1}}</td>
                      <td>{{t.task_date}}</td>
                      <td>{{t.firm_name}}</td>
                      <td>{{t.emp_id}}</td>
                      <td>{{t.task_name}}</td>
                      <td>{{t.status}}</td>
                      <td>{{t.note}}</td>
                      <td><a (click)="viewothertask(t)" >
                        <i class="fa fa-eye" style="color: #00A0F0;font-size: 17px;margin-left:10px;" ></i></a>  </td>
                        <td><a (click)="updateothertask(t)"  >
                          <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a>  </td>
                          <td><a (click)="deleteothertask(t)" >
                            <i class="fa fa-trash" style="color:maroon;font-size: 17px;margin-left:10px;"></i></a>  </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--<div class="alert alert-info alert-dismissible" role="alert" >
                    <i class="fa fa-info-circle"></i> 
                    No Records found
                  </div>-->
                </div>
              </div>
            </div>
    
        
          </div>
          <!--/.Panel 2-->
    
        
  </div>
    </nb-card-body>
    </nb-card>
  
