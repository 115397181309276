

<nb-card>
    <nb-card-header>Edit Task</nb-card-header>
    <nb-card-body>
        <form [formGroup]="editstform" (ngSubmit)="editselftask(editstform)"  autocomplete="off" role="form" >
  <div class="row" *ngIf="case">
        <div class="col-md-6">
          <div class="form-group">
            <label for="case_name" class="label">Case Name</label>
            <input type="hidden" id="selftask_id" name="selftask_id" class="form-control" formControlName="selftask_id">
            <select  class="form-control" id="case_name" name="case_name" formControlName="case_name">
                <option [ngValue]="null" [disabled]="true">Select Case</option>
                <option *ngFor="let r of caseNames" [value]="r.case_name">{{r.case_name}}</option>
            </select>
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="start_date" class="label">Start Date</label><br>
              <input nbInput id="start_date" name="start_date"  formControlName="start_date"
              placeholder="Form Picker" [nbDatepicker]="formpicker1">
       <nb-datepicker #formpicker1></nb-datepicker>      
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="end_date" class="label">End Date</label><br>
              <input nbInput id="end_date" name="end_date"  formControlName="end_date"
              placeholder="Form Picker" [nbDatepicker]="formpicker">
       <nb-datepicker #formpicker></nb-datepicker>      
            </div>
          </div>
        
        
      </div>
<br>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="task_name" class="label">Task Name</label>
              <input type="text"  nbInput fullWidth id="task_name" placeholder="Task Name" name="task_name" formControlName="task_name" autocomplete="off">
            </div>
          </div>
        <div class="col-lg-6">
            <div class="form-group">
              <label for="description" class="label">Description</label>
                
              <textarea  nbInput fullWidth id="description" placeholder="Description" name="description" formControlName="description" autocomplete="off"></textarea>

                </div>
        </div>
        
        
        </div>
      <br>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="status" class="label">Status</label>
              <select name="status" formControlName="status" class="form-control" >
                <option value="Open" >Open</option>
                <option value="Close">Close</option>
              </select>
              
  </div>
</div>
        
        
        </div>
      <div class="row">
        
            <div class="col-lg-6">
              <button type="button"  (click)="back()" class="btn btn-success" style="margin:10px">Back</button>
            
              <button type="submit" class="btn btn-primary" [disabled]="!editstform.valid">Update</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  




