import { Component, OnInit } from '@angular/core';
import {TaskService} from 'app/services/task.service';
import { Task } from 'app/admin/task';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-othertasks',
  templateUrl: './othertasks.component.html',
  styleUrls: ['./othertasks.component.scss']
})
export class OthertasksComponent implements OnInit {
  tasks: Task[];
  othertasks: Task[];
  cat:number;
  viewformModal: boolean;
  public searchText:string;
  showModal: boolean;
  othertaskform: FormGroup;
  submitted = false;
  emp_search:string;
  status_search:string;
  case_search:string;
  constructor(private dataService: TaskService ,private router:Router,private fb: FormBuilder) { }

  ngOnInit(): void {
    //this.gettaskdetails();
    this.othertaskform = new FormGroup({
      othertask_id:new FormControl(),
      status: new FormControl(),
    
      
      
   });
   this.othertaskform = this.fb.group({
    othertask_id:[],
    status: ['', Validators.required],
  
  });
    this.getothertaskdetails();
  }
  show()
  {
    this.viewformModal = true; // Show-Hide Modal Check
    this.othertaskform = new FormGroup({
      othertask_id:new FormControl(),
      status: new FormControl(),
   });

   this.othertaskform = this.fb.group({
    othertask_id:[],
   status: ['', Validators.required],
   
 });
    
  }
  hide()
  {
    this.viewformModal=false;
  }

  search(emp_search,case_search,status_search)
  {

    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      this.dataService.searchothertasks(emp_search,case_search,status_search,client_id).subscribe(response =>
        {
          
          this.othertasks = response.map(item =>
          {
            return new Task(
              item.task_id,
              item.othertask_id,
              item.client_id,
              item.user_id,
              item.emp_id,
               item.case_name,
               item.firm_name,
                item.task_date,
                item.task_name, 
                item.description, 
                item.note,
                item.status,
                item.start_date,
                item.end_date,
                item.client_name
                
            );
          });
        });
 
  }

  }
 
  gettaskdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getalltasks(this.cat,client_id).subscribe(response =>
      {
        this.othertasks = response.map(item =>
        {
          return new Task(
            item.task_id,
            item.othertask_id,
            item.client_id,
            item.user_id,
            item.emp_id,
             item.case_name,
             item.firm_name,
              item.task_date,
              item.task_name, 
              item.description, 
              item.note,
              item.status,
              item.start_date,
              item.end_date,
              item.client_name
            
          );
        });
      });

    }
  }

  getothertaskdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getallothertasks(this.cat,client_id).subscribe(response =>
      {
        this.othertasks = response.map(item =>
        {
          return new Task(
            item.task_id,
            item.othertask_id,
            item.client_id,
            item.user_id,
            item.emp_id,
             item.case_name,
             item.firm_name,
              item.task_date,
              item.task_name, 
              item.description, 
              item.note,
              item.status,
              item.start_date,
              item.end_date,
              item.client_name
              
          );
        });
      });

    }
  }
  
  addtask(): void {
    this.router.navigate(['admin/createtask']);
  };
  back(): void {
    this.router.navigate(['admin/task']);
  };
  
  viewtask(c: Task): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.task_id.toString());
    this.router.navigate(['admin/viewtask']);
  };
  deletetask(c:Task)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removetasks(c.task_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.gettaskdetails();
    })
  }
  }
  updatetask(c: Task): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.task_id.toString());
   // this.router.navigate(['admin/edittask']);
    const url = this.router.createUrlTree(['admin/edittask']);
    window.open(url.toString(), '_blank');
  };
  
  othertask(): void {
    this.router.navigate(['admin/othertasks']);
  };

  selftask(): void {
    this.router.navigate(['admin/selftasks']);
  };

  task(): void {
    this.router.navigate(['admin/task']);
  };
  addothertask(): void {
    this.router.navigate(['admin/addothertasks']);
  };
  otherback(): void {
    this.router.navigate(['admin/othertasks']);
  };

  
  viewothertask(c: Task): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.othertask_id.toString());
    this.router.navigate(['admin/viewothertasks']);
  };
  deleteothertask(c:Task)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removeothertasks(c.othertask_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getothertaskdetails();
    })
  }
  }
  updateothertask(c: Task): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.othertask_id.toString());
   // this.router.navigate(['admin/editothertasks']);
    const url = this.router.createUrlTree(['admin/editothertasks']);
    window.open(url.toString(), '_blank');
  };

  editstatus(emp: Task): void {
    
    this.viewformModal=true;
   
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getothertaskId(emp.othertask_id,client_id)
    .subscribe( data => {  
      this.othertaskform.patchValue({
        othertask_id:data[0].othertask_id,status:data[0].status
     });
    });
  }
  }
  otherstatusupdate(othertaskform)
  {
   
    this.submitted=true;
    if (this.othertaskform.invalid) {
      return;
  }
  if(this.submitted)
  {

    this.viewformModal = false;
    
  }
    if (localStorage.length > 0) {
      let user_id = localStorage.getItem('user_id');
      let client_id = localStorage.getItem('client_id');
    

              this.dataService.editothertaskstatus(othertaskform.value.status,othertaskform.value.othertask_id,client_id)
      .pipe()
      .subscribe(
          data => {
              //this.router.navigate(['radmin/task']);
              //this.getproddetails();
              

          },
          error => {
          });
              
            }       
  }

  
  }

