import { Component, OnInit } from '@angular/core';
import {ReportService} from 'app/services/report.service';
import { Expense } from 'app/admin/expense';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-expensereport',
  templateUrl: './expensereport.component.html',
  styleUrls: ['./expensereport.component.scss']
})
export class ExpensereportComponent implements OnInit {
  expreportform: FormGroup;
  expenses:Expense[];
  list:boolean=false;
  total_amount:string;
  searchText:string;

  
  constructor(private fb: FormBuilder,private dataService: ReportService,private router:Router) { 
    
   }

  ngOnInit() { 
    this.expreportform = new FormGroup({
      from_date: new FormControl(),
      to_date: new FormControl()
   });
   this.expreportform = this.fb.group({
    from_date: ['', Validators.required],
    to_date: ['', Validators.required]
  });
    
  }

  getexpense(expreportform)
  {
    this.list=true;
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(expreportform.value.from_date);
        let d = ("0" + as.getDate()).slice(-2);
        let m = ("0" + (as.getMonth() + 1)).slice(-2);
        let y = as.getFullYear();
        let from_date= y + "-" + m + "-" + d;
  
        var as1=new Date(expreportform.value.to_date);
        let d1 = ("0" + as1.getDate()).slice(-2);
        let m1 = ("0" + (as1.getMonth() + 1)).slice(-2);
        let y1 = as1.getFullYear();
        let to_date= y1 + "-" + m1 + "-" + d1;

      this.dataService.expensereport(user_id,from_date,to_date,client_id)
      .subscribe(response =>{
          this.expenses = response.map(item =>
          {
            return new Expense(
              item.expense_id,
              item.client_id,
              item.user_id,
              item.expense_date,
              item.expense,
                item.amount,
               this.total_amount= item.total_amount
            );
          });
        });

              
            } 
              
          }
         
  
  get expense_date() { return this.expreportform.get('expense_date'); }
  get expense() { return this.expreportform.get('expense'); }
  get amount() { return this.expreportform.get('amount'); }

 

}

