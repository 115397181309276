

<nb-card>
  <nb-card-header>Add Receipt</nb-card-header>
  <nb-card-body>
      <form [formGroup]="viewrecform"  autocomplete="off" role="form" >
    <div class="row">
      <div class="col-md-6">
          <div class="form-group">
            <label for="firm_name" class="label">Company Name*</label>
            <input type="hidden"  nbInput fullWidth id="or_id" name="or_id" formControlName="or_id" autocomplete="off"  placeholder="Case no."> 
            <select disabled  class="form-control" id="firm_name" name="firm_name" #firm_name formControlName="firm_name" >
                <option [ngValue]="null" [disabled]="true">Select Company</option>
                <option *ngFor="let r of firmNames" [value]="r.firm_name">{{r.firm_name}}</option>
            </select>
            <div *ngIf="f.firm_name.touched && f.firm_name.invalid" class="alert alert-danger">
              <div *ngIf="f.firm_name.errors.required">Company Name is required.</div>
             
          </div>
          </div>
        </div>
        
      <div class="col-md-3">
        <div class="form-group">
          <label for="rdate" class="label">Date</label><br>
          <input nbInput id="rdate" name="rdate"  formControlName="rdate" disabled
          placeholder="Form Picker" [nbDatepicker]="formpicker">
   <nb-datepicker #formpicker></nb-datepicker>
          <div *ngIf="f.rdate.touched && f.rdate.invalid" class="alert alert-danger">
            <div *ngIf="f.rdate.errors.required">Date is required.</div>
        </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label for="installment" class="label">Installment</label>
          <input type="text" readonly autocomplete="off"  class="form-control" name="installment" formControlName="installment" #installment placeholder="Case No" id="installment" >  
    </div>
</div>

    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="work" class="label">Work*</label>
          <select  class="form-control"disabled id="work" name="work" #work formControlName="work" >
              <option [ngValue]="null" [disabled]="true">Select Firm</option>
              <option *ngFor="let r of works" [value]="r.work">{{r.work}}</option>
          </select>
          <div *ngIf="f.work.touched && f.work.invalid" class="alert alert-danger">
            <div *ngIf="f.work.errors.required">Work is required.</div>
           
        </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label for="agreed_amount"  class="label">Agreed Amount*</label>
<input type="text"  nbInput fullWidth readonly id="agreed_amount" readonly  name="agreed_amount" #agreed_amount formControlName="agreed_amount" autocomplete="off" placeholder="Agreed Amount">    
</div>
      </div>
    </div>
<div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="paid" class="label">Paid*</label>
<input type="text"  nbInput fullWidth id="paid" readonly #paid name="paid" formControlName="paid" autocomplete="off" placeholder="Paid Amount">
<div *ngIf="f.paid.touched && f.paid.invalid" class="alert alert-danger">
  <div *ngIf="f.paid.errors.required">Paid amount is required.</div>
  <div *ngIf="f.paid.errors.pattern">Enter only numbers.</div>
</div>    
</div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="due" class="label">Due*</label>
<input type="text" readonly  nbInput fullWidth readonly id="paid" #due name="due" formControlName="due" autocomplete="off" >

</div>
          </div>
      </div>  
    
      
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="balance" class="label">Total Received*</label>
<input type="text" readonly  nbInput readonly fullWidth id="balance" #paid name="balance" #balance formControlName="balance" autocomplete="off" >

</div>
</div>
    </div>    

    
    <br>
    <div class="row">
      
          <div class="col-lg-1">
            <button type="button"  (click)="otherback()" class="btn btn-success">Back</button>
          </div>
    </div>
   
   
    </form>
  </nb-card-body>
</nb-card>






