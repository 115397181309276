<div class="row"  >
    <div class="col-md-3" >
    </div>
<div class="col-md-6 col-md-offset-3">
        <div class="jumbotron" style="float:center">
            <div class="container-fluid">
            <h5 class="text-center">Reset Password</h5>
            <br>
            <form [formGroup]="forform" (ngSubmit)="passdata(forform)"  autocomplete="off" >
                <div class="form-group">

                    
                    <div class="input-container">
                        <i class="fa fa-key icon"></i>
                    <input [type]="fieldTextType ? 'text' : 'password'"  name="Password" formControlName="password" #password (keyup)="check(password.value)"  autocomplete="off" class="form-control input-sm" placeholder="Password">  
                    
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i
                            class="fa"
                            [ngClass]="{
                              'fa-eye-slash': !fieldTextType,
                              'fa-eye': fieldTextType
                            }"
                            (click)="toggleFieldTextType()"
                          ></i>
                        </span>
                     </div>
                </div>
                <p id="passmsg">
                <div *ngIf="f.password.touched && f.password.invalid" class="error">
                    <div *ngIf="f.password.errors.required" style="color:red">Password is required.</div>
                </div> 
                     
                </div>
                <div class="form-group">
                    <div class="input-container">
                        <i class="fa fa-key icon"></i>
                    <input [type]="fieldText1 ? 'text' : 'password'" name="cpassword" formControlName="cpassword" #cpassword (keyup)="checkpassword(password.value,cpassword.value)"  autocomplete="off" class="form-control input-sm" placeholder="Confirm Password">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i
                            class="fa"
                            [ngClass]="{
                              'fa-eye-slash': !fieldText1,
                              'fa-eye': fieldText1
                            }"
                            (click)="toggleFieldText1()"
                          ></i>
                        </span>
                     </div>
                </div>
                <p id="msg">
                <div *ngIf="f.cpassword.touched && f.cpassword.invalid" class="error">
                    <div *ngIf="f.cpassword.errors.required" style="color:red">Confirm Password is required.</div>
                </div> 
                </div>
                <button type="submit" class="btn" [disabled]="!forform.valid">Reset Password</button><br><br>
                <p style="color:gray"><a (click)="signin()" style="cursor:pointer">Sign In</a></p>
            </form>
        </div>
    </div>
    </div>
    <div class="col-md-3">
    </div>
       
</div>









