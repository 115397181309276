import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Employee} from 'app/admin/employee';
import {credential} from './credential';
@Injectable({
  providedIn: 'root'
})
export class EmployeeserviceService {
  redirectUrl: string;
  baseUrl=credential.login_url;
  constructor(private httpClient : HttpClient) { }
  getAllemployees(id:number,client_id:string) : Observable<Employee[] > {
    return this.httpClient.get<Employee[]>(this.baseUrl+'/loademployees.php?client_id='+client_id)
  }

  public addemployees(emp_name,emp_email,mobile,city,address,role,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/emp_insert.php', {emp_name,emp_email,mobile,city,address,role,client_id})
        .pipe(map(Employee => {
            return Employee;
        }));
  }

  public updateemp(emp_id,emp_name,emp_email,mobile,city,address,role,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/emp_update.php', {emp_id,emp_name,emp_email,mobile,city,address,role,client_id })
      .pipe(map(Employee => {
            return Employee;
        }));
   
  }
  removeemp(emp_id: number,client_id:string): Observable<Employee[]> {
    return this.httpClient.delete<Employee[]>(this.baseUrl+'/emp_delete.php?emp_id='+emp_id+'&client_id='+client_id);
  }
  public getempId(eid: number,client_id:string): Observable<Employee[]>
  {
    return this.httpClient.get<Employee[]>
    (this.baseUrl + '/getemployees.php?emp_id='+eid +'&client_id='+client_id);
  }
  public validateemployee(emp_email: string,client_id:string): Observable<Employee[]>
  {
    return this.httpClient.get<Employee[]>
    (this.baseUrl + '/validateemp.php?emp_email='+emp_email +'&client_id='+client_id);
  }
  public getroles(): Observable<Employee[]>
  {
    return this.httpClient.get<Employee[]>(
      this.baseUrl + '/getroles.php'
      );
  }
 
}
