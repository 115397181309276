<nb-card>
    <nb-card-header><h5>Self Task Report</h5></nb-card-header>
    <nb-card-body>
        <form [formGroup]="streportform" (ngSubmit)="getselftask(streportform)"  autocomplete="off" role="form" >
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="from_date" class="label">From </label><br>
      
              <input nbInput id="from_date" name="from_date"  formControlName="from_date"
              placeholder="Form Picker" [nbDatepicker]="formpicker">
       <nb-datepicker #formpicker></nb-datepicker>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="to_date" class="label">To </label><br>
              <input nbInput id="to_date" name="to_date"  formControlName="to_date"
              placeholder="Form Picker" [nbDatepicker]="formpicker1">
       <nb-datepicker #formpicker1></nb-datepicker>                </div>
              </div>

              <div class="col-md-2">
                <button type="submit" class="btn btn-primary" [disabled]="!streportform.valid" style="margin-top:25px">Show</button>
                </div>
              
            </div>
            </form>
            <br><br>
  <div class="container">
      <div class="row" *ngIf="list">
        <div class="col-md-6">
          <div class="form-group">
            <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
        </div>
        </div>       
        <div class="col-md-12">
          <br>
          <table class="table table-hover" >
            <thead>
            <tr>
     
              <th>Sr.No</th>
              <th>Case Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Task Name</th>
              <th>Description</th>
              <th>Status</th>

              
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let t of selftasks | filter: searchText; let i = index"  >
     
              <td>{{i + 1}}</td>
              <td>{{t.case_name}}</td>
              <td>{{t.start_date}}</td>
              <td>{{t.end_date}}</td>
              <td>{{t.task_name}}</td>
              <td>{{t.description}}</td>
              <td>{{t.status}}</td>
              </tr>
            </tbody>
          </table>
          <!--<div class="alert alert-info alert-dismissible" role="alert" >
            <i class="fa fa-info-circle"></i> 
            No Records found
          </div>-->
        </div>
      </div>
    </div>
    </nb-card-body>
    </nb-card>
  

