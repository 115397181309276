import {Component, OnDestroy, OnInit} from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators' ;
import { SolarData } from '../../@core/data/solar';
import {DashboardService} from 'app/services/dashboard.service';
import { Dashboard } from 'app/admin/dashboard';
import { map } from 'rxjs/operators';
import { Router,ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Meetings } from 'app/admin/meetings';
import { Calls } from 'app/admin/calls';
import { Casedate } from 'app/admin/casedate';
import { mainModule } from 'process';
import { Task } from 'app/admin/task';
import { Selftask } from 'app/employee/selftask';


interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
 
}

@Component({
  selector: 'ngx-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {

  private alive = true;
  public searchText:string;
  dashboarddetails:Dashboard[];
  meetings: Meetings[];
  calls: Calls[];
  ccdates: Casedate[];
   dueccdates: Casedate[];
  ctask:Task[];
  selftask:Selftask[];
  othertask:Task[];
  public today:string;
  public tommorow:string;
  public total_due:string;
  public total_paid:string;
  public task:string;
  public court:string;
  public total_open_tasks:string;
  public total_close_tasks:string;
  public cc_dtoday:string;
  public cc_dtomorrow:string;
  constructor(private dataService: DashboardService ,private router:Router,private themeService: NbThemeService,
    private solarService: SolarData) {
    
   }
ngOnInit(){
  if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');
    let user_id = localStorage.getItem('user_id');
  this.dataService.dashboard(user_id,client_id).subscribe(response =>
    {
      this.dashboarddetails = response.map(item =>
        {
         
      return new Dashboard(
      
        item.client_id,
        this.tommorow=item.user_id,
       this.total_due= item.total_due,
       this.total_paid=item.total_paid,
        this.task=item.task,
        this.total_close_tasks=item.closetasks,
        this.total_open_tasks=item.opentasks,
        this.court=item.court,
        this.cc_dtoday=item.cc_today,
        this.cc_dtomorrow=item.cc_tomorrow
      );
    });
  });
  var today1 = new Date();
var dd = String(today1.getDate()).padStart(2, '0');
var mm = String(today1.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today1.getFullYear();
this.today = dd + '/' + mm + '/' + yyyy;





  this.dataService.getmeetings(user_id,client_id).subscribe(response =>
    {
      this.meetings = response.map(item =>
      {
        return new Meetings(
          
          item.meeting_id,
          item.client_id,
          item.user_id,
          item.topic,
          item.discussion,
          item.client_name,
          item.scheduled_date,
          item.scheduled_time  
        );
      });
    });

    this.dataService.getcalls(user_id,client_id).subscribe(response =>
      {
        this.calls = response.map(item =>
        {
          return new Calls(
            
            item.call_id,
            item.client_id,
            item.user_id,
            item.case_name,
            item.task_name,
            item.call_date,
            item.emp_id,
            item.note,
            item.status  
          );
        });
      });

      this.dataService.getccdates(user_id,client_id).subscribe(response =>
        {
          this.ccdates = response.map(item =>
          {
            return new Casedate(
            item.case_date_id,
            item.case_id,
            item.client_id,
            item.user_id,
            item.case_no,
            item.case_name,
            item.prev_date,
            item.client_name,
            item.next_date,
            item.stage,
            item.court_name,
            item.party_two
              
            );
          });
        });

        this.dataService.getdueccdates(user_id,client_id).subscribe(response =>
          {
            this.dueccdates = response.map(item =>
            {
              return new Casedate(
              item.case_date_id,
              item.case_id,
              item.client_id,
              item.user_id,
              item.case_no,
              item.case_name,
              item.prev_date,
              item.client_name,
              item.next_date,
              item.stage,
              item.court_name,
              item.party_two
                
              );
            });
          });
  

        this.dataService.gettask(user_id,client_id).subscribe(response =>
          {
            this.ctask = response.map(item =>
            {
              return new Task(
                
                item.task_id,
              item.othertask_id,
              item.client_id,
              item.user_id,
              item.emp_id,
               item.case_name,
               item.firm_name,
                item.task_date,
                item.task_name, 
                item.description, 
                item.note,
                item.status,
              item.start_date,
              item.end_date,
              item.client_name
              );
            });
          });

          this.dataService.getothertask(user_id,client_id).subscribe(response =>
            {
              this.othertask = response.map(item =>
              {
                return new Task(
                  
                  item.task_id,
                  item.othertask_id,
                  item.client_id,
                  item.user_id,
                  item.emp_id,
                  item.case_name,
                  item.firm_name,
                  item.task_date,
                  item.task_name, 
                  item.description, 
                  item.note,
                  item.status,
              item.start_date,
              item.end_date,
              item.client_name
                );
              });
            });
  
          this.dataService.getselftask(user_id,client_id).subscribe(response =>
            {
              this.selftask = response.map(item =>
              {
                return new Selftask(
                  
                item.selftask_id,
              item.client_id,
              item.user_id,
              item.emp_id,
               item.case_name,
                item.task_name, 
                item.description, 
                item.start_date,
                item.end_date,
                item.status
                );
              });
            });


        
   
  }
}


updatemeetings(c: Meetings): void {
  window.localStorage.removeItem("editId");
  window.localStorage.setItem("editId", c.meeting_id.toString());
  this.router.navigate(['admin/dashboardeditmeetings']);
};

updatecalls(c: Calls): void {
  window.localStorage.removeItem("editId");
  window.localStorage.setItem("editId", c.call_id.toString());
  this.router.navigate(['admin/dashboardeditcalls']);
};

updatetask(c: Task): void {
  window.localStorage.removeItem("editId");
  window.localStorage.setItem("editId", c.task_id.toString());
  this.router.navigate(['admin/dashboardedittask']);
};

updateothertask(c: Task): void {
  window.localStorage.removeItem("editId");
  window.localStorage.setItem("editId", c.othertask_id.toString());
  this.router.navigate(['admin/dashboardeditothertasks']);
};

editselftask(c: Selftask): void {
  window.localStorage.removeItem("editId");
  window.localStorage.setItem("editId", c.selftask_id.toString());
  this.router.navigate(['admin/dashboardeditselftask']);
};

addcasedate(c: Casedate): void {
  window.localStorage.removeItem("caseId");
  window.localStorage.setItem("caseId", c.case_date_id.toString());
  const url = this.router.createUrlTree(['admin/dashboardaddccdates']);
window.open(url.toString(), '_blank');
  
};

}

  
    
    
  
 
  




