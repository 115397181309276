import { Component, OnInit } from '@angular/core';
import {TaskService} from 'app/services/task.service';
import { Selftask } from 'app/employee/selftask';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-selftask',
  templateUrl: './selftask.component.html',
  styleUrls: ['./selftask.component.scss']
})
export class SelftaskComponent implements OnInit {
  selftasks: Selftask[];
  cat:number;
  emp_search:string;
  status_search:string;
  case_search:string;
  public searchText:string;
  constructor(private dataService: TaskService ,private router:Router) { }

  ngOnInit(): void {
    this.getselftaskdetails();
    
  }
 
  getselftaskdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getopenselftasks(client_id).subscribe(response =>
      {
        this.selftasks = response.map(item =>
        {
          return new Selftask(
          item.selftask_id,
          item.client_id,
          item.user_id,
          item.emp_id,
           item.case_name,
            item.task_name, 
            item.description, 
            item.start_date,
            item.end_date,
            item.status
          );
        });
      });

    }
  }

  
 
  
  task(): void {
    this.router.navigate(['admin/task']);
  };
  othertask(): void {
    this.router.navigate(['admin/othertasks']);
  };

  selftask(): void {
    this.router.navigate(['admin/selftasks']);
  };
  addothertask(): void {
    this.router.navigate(['admin/addothertasks']);
  };

  editselftask(c: Selftask): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.selftask_id.toString());
   // this.router.navigate(['admin/editselftask']);
   const url = this.router.createUrlTree(['admin/editselftask']);
    window.open(url.toString(), '_blank');
  };

  search(emp_search,case_search,status_search)
  {

    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      this.dataService.searchselftasks(emp_search,case_search,status_search,client_id).subscribe(response =>
        {
          
          this.selftasks = response.map(item =>
            {
              return new Selftask(
              item.selftask_id,
              item.client_id,
              item.user_id,
              item.emp_id,
               item.case_name,
                item.task_name, 
                item.description, 
                item.start_date,
                item.end_date,
                item.status
              );
            });
          });
    
  }

  }
 
 
  }