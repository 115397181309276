import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Dashboard} from 'app/admin/dashboard';
import {Task} from 'app/admin/task';
import {Receipt} from 'app/admin/receipt';
import {Meetings} from 'app/admin/meetings';
import {Selftask} from 'app/employee/selftask';
import {Calls} from 'app/admin/calls';
import {Casedate} from 'app/admin/casedate';
import {credential} from './credential';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  redirectUrl: string;
  baseUrl=credential.login_url;
  constructor(private httpClient : HttpClient) { }
  dashboard(user_id:string,client_id:string) : Observable<Dashboard[] > {
    return this.httpClient.get<Dashboard[]>(this.baseUrl+'/loaddashboarddetails.php?client_id='+client_id+'&user_id='+user_id)
  }

  getmeetings(user_id:string,client_id:string) : Observable<Meetings[] > {
    return this.httpClient.get<Meetings[]>(this.baseUrl+'/loaddashboardmeetings.php?client_id='+client_id+'&user_id='+user_id)
  }

  getcalls(user_id:string,client_id:string) : Observable<Calls[] > {
    return this.httpClient.get<Calls[]>(this.baseUrl+'/loaddashboardcalls.php?client_id='+client_id+'&user_id='+user_id)
  }

  getselftask(user_id:string,client_id:string) : Observable<Selftask[] > {
    return this.httpClient.get<Selftask[]>(this.baseUrl+'/loaddashboardselftask.php?client_id='+client_id+'&user_id='+user_id)
  }

  gettask(user_id:string,client_id:string) : Observable<Task[] > {
    return this.httpClient.get<Task[]>(this.baseUrl+'/loaddashboardtasks.php?client_id='+client_id+'&user_id='+user_id)
  }

  getothertask(user_id:string,client_id:string) : Observable<Task[] > {
    return this.httpClient.get<Task[]>(this.baseUrl+'/loaddashboardothertasks.php?client_id='+client_id+'&user_id='+user_id)
  }

  dashboardemp(user_id:string,client_id:string) : Observable<Dashboard[] > {
    return this.httpClient.get<Dashboard[]>(this.baseUrl+'/loadempdashboarddetails.php?client_id='+client_id+'&user_id='+user_id)
  }

  getempcalls(user_id:string,client_id:string) : Observable<Calls[] > {
    return this.httpClient.get<Calls[]>(this.baseUrl+'/loadempdashboardcalls.php?client_id='+client_id+'&user_id='+user_id)
  }

  getemptask(user_id:string,client_id:string) : Observable<Task[] > {
    return this.httpClient.get<Task[]>(this.baseUrl+'/loadempdashboardtasks.php?client_id='+client_id+'&user_id='+user_id)
  }

  getempothertask(user_id:string,client_id:string) : Observable<Task[] > {
    return this.httpClient.get<Task[]>(this.baseUrl+'/loadempdashboardothertasks.php?client_id='+client_id+'&user_id='+user_id)
  }

  getempselftasks(user_id:string,client_id:string) : Observable<Selftask[] > {
    return this.httpClient.get<Selftask[]>(this.baseUrl+'/loadempdashboardselftask.php?client_id='+client_id+'&user_id='+user_id)
  }

  getccdates(user_id:string,client_id:string) : Observable<Casedate[]>  {
    return this.httpClient.get<Casedate[]>(this.baseUrl+'/loaddashboardccdates.php?client_id='+client_id+'&user_id='+user_id)
  }

  getdueccdates(user_id:string,client_id:string) : Observable<Casedate[]>  {
    return this.httpClient.get<Casedate[]>(this.baseUrl+'/loaddashboarddueccdates.php?client_id='+client_id+'&user_id='+user_id)
  }

  public updateMeetings(meeting_id,user_id,topic,discussion,scheduled_date,scheduled_time,client_name,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/meetings_update.php', {meeting_id,user_id,topic,discussion,scheduled_date,scheduled_time,client_name,client_id })
      .pipe(map(Meetings => {
            return Meetings;
        }));
   
  }

  public getmeetingsId(meeting_id: number,client_id:string): Observable<Meetings[]>
  {
    return this.httpClient.get<Meetings[]>(
      this.baseUrl + '/getmeetings.php?meeting_id=' + meeting_id +'&client_id='+client_id
      );
  }

  

}


