import { Component, OnInit } from '@angular/core';
import {TaskService} from 'app/services/task.service';
import {Task} from 'app/admin/task'
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-othertasksemp',
  templateUrl: './othertasksemp.component.html',
  styleUrls: ['./othertasksemp.component.scss']
})
export class OthertasksempComponent implements OnInit {
  tasks: Task[];
  cat:number;
  othertasks: Task[];
  public searchText:string;
  constructor(private dataService: TaskService ,private router:Router) { }

  ngOnInit(): void {
    this.gettaskdetails();
    this.getothertaskdetails();
  }
  gettaskdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.getallemptasks(this.cat,client_id,user_id).subscribe(response =>
      {
        this.tasks = response.map(item =>
        {
          return new Task(
            item.task_id,
            item.othertask_id,
            item.client_id,
            item.user_id,
            item.emp_id,
             item.case_name,
             item.firm_name,
              item.task_date,
              item.task_name, 
              item.description, 
              item.note,
              item.status,
              item.start_date,
              item.end_date,
              item.client_name
          );
        });
      });

    }
  }

  getothertaskdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.getallotheremptasks(this.cat,client_id,user_id).subscribe(response =>
      {
        this.othertasks = response.map(item =>
        {
          return new Task(
            item.task_id,
            item.othertask_id,
            item.client_id,
            item.user_id,
            item.emp_id,
             item.case_name,
             item.firm_name,
              item.task_date,
              item.task_name, 
              item.description, 
              item.note,
              item.status,
              item.start_date,
              item.end_date,
              item.client_name
              
          );
        });
      });

    }
  
  }
  back(): void {
    this.router.navigate(['employee/task']);
  };
  
  viewtask(c: Task): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.task_id.toString());
    this.router.navigate(['employee/viewtask']);
  };
  othertask(): void {
    this.router.navigate(['employee/othertasks']);
  };

  task(): void {
    this.router.navigate(['employee/task']);
  };

  deletetask(c:Task)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.removetasksemp(c.task_id,client_id,user_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.gettaskdetails();
    })
  }
  }
  
  updatetask(c: Task): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.task_id.toString());
    this.router.navigate(['employee/edittask']);
  };
  

  otherback(): void {
    this.router.navigate(['employee/task']);
  };
  
  viewothertask(c: Task): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.othertask_id.toString());
    this.router.navigate(['employee/viewothertasks']);
  };

  deleteothertask(c:Task)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.removeothertasksemp(c.othertask_id,client_id,user_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getothertaskdetails();
    })
  }
  }
  
  updateothertask(c: Task): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.othertask_id.toString());
    this.router.navigate(['employee/editothertasks']);
  };
  
  }