export class Selftask {
    public selftask_id: number;
    public client_id:number;
    public user_id:number;
    public emp_id:string;
    public case_name:string;
    public task_name:string;
    public description:string;
    public start_date:string;
    public end_date:string;
    public status:string;

    constructor(selftask_id:number,client_id:number,user_id:number,emp_id:string,case_name:string,task_name:string,description:string,start_date:string,end_date:string,status:string) {
      this.selftask_id=selftask_id,
      this.client_id=client_id;
      this.user_id=user_id;
      this.emp_id=emp_id;
      this.case_name=case_name;
      this.task_name = task_name;
      this.description = description;
      this.start_date=start_date;
      this.end_date=end_date;
      this.status=status;
    }
    }