import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Task} from 'app/admin/task';
import {Selftask} from 'app/employee/selftask';
import {credential} from './credential';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  redirectUrl: string;
  baseUrl=credential.login_url;
  constructor(private httpClient : HttpClient) { }
  getalltasks(id:number,client_id:string) : Observable<Task[] > {
    return this.httpClient.get<Task[]>(this.baseUrl+'/loadtasks.php?client_id='+client_id)
  }

  public addtasks(user_id,emp_name,case_name,task_date,task_name,description,status,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/task_insert.php', {user_id,emp_name,case_name,task_date,task_name,description,status,client_id})
        .pipe(map(Task => {
            return Task;
        }));
  }

  public updatetasks(task_id,user_id,emp_name,case_name,task_date,task_name,description,status,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/task_update.php', {task_id,user_id,emp_name,case_name,task_date,task_name,description,status,client_id })
      .pipe(map(Task => {
            return Task;
        }));
   
  }
  public editstatus(status,task_id,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/task_status_update.php', {status,task_id,client_id })
      .pipe(map(Task => {
            return Task;
        }));
   
  }

  public editothertaskstatus(status,othertask_id,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/othertasks_status_update.php', {status,othertask_id,client_id })
      .pipe(map(Task => {
            return Task;
        }));
   
  }
  removetasks(task_id: number,client_id:string): Observable<Task[]> {
    return this.httpClient.delete<Task[]>(this.baseUrl+'/task_delete.php?task_id='+task_id+'&client_id='+client_id);
  }
  public gettaskId(eid: number,client_id:string): Observable<Task[]>
  {
    return this.httpClient.get<Task[]>
    (this.baseUrl + '/gettasks.php?task_id='+eid +'&client_id='+client_id);
  }
  public getemp(client_id:string): Observable<Task[]>
  {
    return this.httpClient.get<Task[]>
    (this.baseUrl + '/getempnames.php?client_id='+client_id);
  }
  public getallemp(client_id:string): Observable<Task[]>
  {
    return this.httpClient.get<Task[]>
    (this.baseUrl + '/getallemployees.php?client_id='+client_id);
  }
  public getfirmnames(client_id:string): Observable<Task[]>
  {
    return this.httpClient.get<Task[]>
    (this.baseUrl + '/getfirmnames.php?client_id='+client_id);
  }
  public getcasenames(client_id:string): Observable<Task[]>
  {
    return this.httpClient.get<Task[]>
    (this.baseUrl + '/getcases.php?client_id='+client_id);
  }

  getallothertasks(id:number,client_id:string) : Observable<Task[] > {
    return this.httpClient.get<Task[]>(this.baseUrl+'/loadothertasks.php?client_id='+client_id);
  }

  getallselftasks(client_id:string) : Observable<Selftask[] > {
    return this.httpClient.get<Selftask[]>(this.baseUrl+'/loaddashboardselftask.php?client_id='+client_id);
  }

  getopenselftasks(client_id:string) : Observable<Selftask[] > {
    return this.httpClient.get<Selftask[]>(this.baseUrl+'/loadallopenselftasks.php?client_id='+client_id);
  }


  public addothertasks(user_id,emp_name,firm_name,task_date,task_name,description,status,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/othertasks_insert.php', {user_id,emp_name,firm_name,task_date,task_name,description,status,client_id})
        .pipe(map(Task => {
            return Task;
        }));
  }

  public updateothertasks(othertask_id,user_id,emp_name,firm_name,task_date,task_name,description,status,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/othertasks_update.php', {othertask_id,user_id,emp_name,firm_name,task_date,task_name,description,status,client_id })
      .pipe(map(Task => {
            return Task;
        }));
   
  }
  removeothertasks(othertask_id: number,client_id:string): Observable<Task[]> {
    return this.httpClient.delete<Task[]>(this.baseUrl+'/othertasks_delete.php?othertask_id='+othertask_id+'&client_id='+client_id);
  }
  public getothertaskId(othertask_id: number,client_id:string): Observable<Task[]>
  {
    return this.httpClient.get<Task[]>
    (this.baseUrl + '/getothertasks.php?othertask_id='+othertask_id +'&client_id='+client_id);
  }

  getallemptasks(id:number,client_id:string,user_id:string) : Observable<Task[] > {
    return this.httpClient.get<Task[]>(this.baseUrl+'/loadtasksemp.php?client_id='+client_id+'&user_id='+user_id)
  }

  public updatetasksemp(task_id,user_id,emp_name,case_name,task_date,task_name,description,status,start_date,end_date,note,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/task_updateemp.php', {task_id,user_id,emp_name,case_name,task_date,task_name,description,status,start_date,end_date,note,client_id })
      .pipe(map(Task => {
            return Task;
        }));
   
  }

  removetasksemp(task_id: number,client_id:string,user_id:string): Observable<Task[]> {
    return this.httpClient.delete<Task[]>(this.baseUrl+'/task_deleteemp.php?task_id='+task_id+'&client_id='+client_id+'&user_id='+user_id);
  }

  getallotheremptasks(id:number,client_id:string,user_id:string) : Observable<Task[] > {
    return this.httpClient.get<Task[]>(this.baseUrl+'/loadothertasksemp.php?client_id='+client_id+'&user_id='+user_id)
  }

  public updateothertasksemp(othertask_id,user_id,emp_name,firm_name,task_date,task_name,description,status,start_date,end_date,note,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/othertask_updateemp.php', {othertask_id,user_id,emp_name,firm_name,task_date,task_name,description,status,start_date,end_date,note,client_id })
      .pipe(map(Task => {
            return Task;
        }));
   
  }

  removeothertasksemp(othertask_id: number,client_id:string,user_id:string): Observable<Task[]> {
    return this.httpClient.delete<Task[]>(this.baseUrl+'/othertask_deleteemp.php?othertask_id='+othertask_id+'&client_id='+client_id+'&user_id='+user_id);
  }

  public searchtasks(emp_search,case_search,status_search,client_id) {
    
    return this.httpClient.post<any>(this.baseUrl + '/searchtasks.php', {emp_search,case_search,status_search,client_id })
      .pipe(map(Task => {
            return Task;
        }));
   
  }

  public searchothertasks(emp_search,case_search,status_search,client_id) {
 
    return this.httpClient.post<any>(this.baseUrl + '/searchothertasks.php', {emp_search,case_search,status_search,client_id })
      .pipe(map(Task => {
            return Task;
        }));
   
  }
  public searchselftasks(emp_search,case_search,status_search,client_id) {
 
    return this.httpClient.post<any>(this.baseUrl + '/searchselftasks.php', {emp_search,case_search,status_search,client_id })
      .pipe(map(Task => {
            return Task;
        }));
   
  }
  
}
