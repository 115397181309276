import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router,ActivatedRoute } from '@angular/router';
import { CheckloginService } from 'app/services/checklogin.service';

@Component({
  selector: 'ngx-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  forform: FormGroup;
  //showlogin:boolean=false;
  //forgotpassword:boolean=false;
 user_id:string;
 fieldTextType: boolean;
 fieldText1: boolean;
  constructor(private fb: FormBuilder,private dataService: CheckloginService, private router:Router,private route: ActivatedRoute) {
    //this.showlogin=true;
    //this.forgotpassword=false;
    this.forform = new FormGroup({
      password: new FormControl(),
      cpassword: new FormControl()

   });
    
   }


  ngOnInit(): void {
    //this.showlogin=true;
    //this.forgotpassword=false;
    this.route.queryParams.subscribe(params => {
      this.user_id = this.route.snapshot.params.key;
    });
    this.forform = this.fb.group({
      password: ['', Validators.required],
      cpassword: ['', [Validators.required,Validators.minLength(8)]],

    })
  }

  get f(){
    return this.forform.controls;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleFieldText1() {
    this.fieldText1 = !this.fieldText1;
  }

  check(password)
  {
    if(password.length<8)
  {
    document.getElementById('passmsg').style.color = 'red';
        document.getElementById('passmsg').innerHTML = 'Password must contain atleast 8 characters';


  }
  else
  {
   
    document.getElementById('passmsg').innerHTML = '';
  }
  }

  checkpassword(password,cpassword)
  {
    if(password==cpassword)
    {
      document.getElementById('msg').style.color = 'green';
      document.getElementById("msg").innerHTML="Password matched!";

    }
    else
    {
      document.getElementById('msg').style.color = 'red';
      document.getElementById("msg").innerHTML="Password confirmation doesn't match password";
    }

  }

  signin(): void {
    this.router.navigate(['login']);
  };

  passdata(forform)
  {
    this.dataService.resetpass(this.user_id,forform.value.password)
      .pipe(first())
      .subscribe(
          data => {
            if(data[0]['result']=="Success")
            {
              this.router.navigate(['login']);
            }
            else
            {
              alert("User name is incorrect");
            }
            
          },
          error => {
            
             // alert("User name is incorrect");
          });
  }

}
