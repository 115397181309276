import { Component, OnInit } from '@angular/core';
import {CaseserviceService} from 'app/services/caseservice.service';
import { Case } from 'app/admin/case';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';


@Component({
  selector: 'ngx-addcase',
  templateUrl: './addcase.component.html',
  styleUrls: ['./addcase.component.scss']
})
export class AddcaseComponent implements OnInit {
  caseform: FormGroup;
  courtNames:any= [];
  constructor(private fb: FormBuilder,private dataService: CaseserviceService,private router:Router) {
    

    
   }
  


  ngOnInit() {
    this.caseform = new FormGroup({
      case_no: new FormControl(),
      case_name: new FormControl(),
      court_name: new FormControl(),
      judge_name: new FormControl(),
      case_date: new FormControl(),
      client_name: new FormControl(),
      party_one: new FormControl(),
      party_two: new FormControl(),
      file_no: new FormControl(),
      stage: new FormControl(),
      agreed_amount: new FormControl(),
      owner_role: new FormControl()
      
   });
    
    this.caseform = this.fb.group({
      court_name: [],
      case_name: ['', Validators.required,],
      case_date: ['', Validators.required],
      client_name: ['', Validators.required],
      party_one: ['', Validators.required],
      party_two: ['', Validators.required],
      file_no: ['', Validators.required],
      stage: ['', Validators.required],
      agreed_amount:  ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      owner_role: ['', Validators.required],
      case_no: [],
      judge_name: []
    });

    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

   
      this.dataService.getcourtnames(client_id)
              .subscribe((data: any) => {
                this.courtNames=data;
               
            });
          }

}

get f(){
  return this.caseform.controls;
}
  
  
  back(): void {
    this.router.navigate(['admin/courtcase']);
  };

  createcase(caseform)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(caseform.value.case_date);
    let d = ("0" + as.getDate()).slice(-2);
    let m = ("0" + (as.getMonth() + 1)).slice(-2);
    let y = as.getFullYear();
    let c_date= y + "-" + m + "-" + d;
  
    this.dataService.addCourtCase(user_id,caseform.value.case_no,caseform.value.court_name,caseform.value.judge_name,c_date,caseform.value.case_name,caseform.value.client_name,caseform.value.party_one,caseform.value.party_two,caseform.value.stage,caseform.value.file_no,caseform.value.owner_role,caseform.value.agreed_amount,client_id)
      .pipe()
      .subscribe(
          data => {
              this.router.navigate(['admin/courtcase']);
          },
          error => {
          });
        }
  }

  showjudge(court_name)
  {
 
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.getjudge(client_id,court_name)
              .subscribe((data: any) => {
                this.caseform.patchValue({
                
                  judge_name:data[0].judge_name
         
               });
               
            });
          
          }

  }


  get case_no() { return this.caseform.get('case_no'); }
  get case_name() { return this.caseform.get('case_name'); }
  get court_name() { return this.caseform.get('court_name'); }
  get judge_name() { return this.caseform.get('judge_name'); }

  get case_date() { return this.caseform.get('case_date'); }
  get client_name() { return this.caseform.get('client_name'); }
  get party_one() { return this.caseform.get('party_one'); }
  get party_two() { return this.caseform.get('party_two'); }
  get file_no() { return this.caseform.get('file_no'); }
  get stage() { return this.caseform.get('stage'); }
  get owner_role() { return this.caseform.get('owner_role'); }
  get agreed_amount() { return this.caseform.get('agreed_amount'); }
  
 

}
