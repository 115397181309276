<nb-card>
    <nb-card-header><h5>Tasks</h5></nb-card-header>
    <nb-card-body>
  

    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#panel11" role="tab" (click)="task()" style="text-decoration:none;">Court Tasks</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#panel12" (click)="othertask()"  role="tab" style="text-decoration:none;">Other Tasks</a>
      </li>
      <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#panel13" (click)="selftask()"  role="tab" style="text-decoration:none;">Self Tasks</a>
      </li>
    </ul>
    <div class="tab-content pt-0">

      <!--Panel 1-->
      <div class="tab-pane fade in show active" id="panel13" role="tabpanel">
        <br>
    
        <div class="container">
              <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <input type="text" placeholder="Search Employee" class="form-control" name="emp_search" [(ngModel)]="emp_search" (blur)="search(emp_search,case_search,status_search)">
              </div>
              </div>   
              <div class="col-md-4">
                <div class="form-group">
                  <input type="text" placeholder="Search Task " class="form-control" name="case_search" [(ngModel)]="case_search" (blur)="search(emp_search,case_search,status_search)">
              </div>
              </div> 
              <div class="col-md-4">
                <div class="form-group">
                  <input type="text" placeholder="Search Status" class="form-control" name="status_search" [(ngModel)]="status_search" (blur)="search(emp_search,case_search,status_search)">
              </div>
              </div>  
            <div class="col-md-12">
              <!--<h2 class="text-center" >Clients</h2>-->
              
              <br><br>
              <table class="table table-hover" >
                <thead>
                <tr>
         
                  <th>Sr.No</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Case Name</th>
                  <th>Employee</th>
                  <th>Task Name</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Edit</th>
                 
                  
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let t of selftasks | filter: searchText; let i = index"  >
         
                  <td>{{i + 1}}</td>
                  <td>{{t.start_date}}</td>
                  <td>{{t.end_date}}</td>
                  <td>{{t.case_name}}</td>
                  <td>{{t.emp_id}}</td>
                  <td>{{t.task_name}}</td>
                  <td>{{t.description}}</td>
                  <td>{{t.status}}</td>
                  <td><a (click)="editselftask(t)"  >
                    <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a></td>
                  
              </tr>
                </tbody>
              </table>
              <!--<div class="alert alert-info alert-dismissible" role="alert" >
                <i class="fa fa-info-circle"></i> 
                No Records found
              </div>-->
            </div>
          </div>
        </div>
      </div>
      <!--/.Panel 1-->
    
     </div>    
    </nb-card-body>
    </nb-card>
  
