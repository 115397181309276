export class Casedate {
    public case_id: number;
    public case_date_id: number;
    public client_id:string;
    public user_id: string;
    public case_no:string
    public case_name:string;
    public client_name:string;
    public prev_date:string;
    public next_date:string;
    public stage:string;
    public court_name:string;
    public party_two:string;
 
 
    constructor(case_date_id:number,case_id:number,client_id:string,user_id: string,case_no:string,case_name:string,prev_date:string,client_name:string,next_date:string,stage:string,court_name:string,party_two:string) {
      this.case_date_id = case_date_id;
      this.case_id = case_id;
      this.client_id=client_id;
      this.user_id=user_id;
      this.case_no=case_no;
      this.case_name = case_name;
      this.client_name=client_name;
      this.prev_date=prev_date;
      this.next_date=next_date;
      this.stage=stage;
      this.court_name=court_name;
      this.party_two=party_two;
    }
}
      
