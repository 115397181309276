import { Component, OnInit } from '@angular/core';
import {MeetingsService} from 'app/services/meetings.service';
import { Meetings } from 'app/admin/meetings';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-viewmeetings',
  templateUrl: './viewmeetings.component.html',
  styleUrls: ['./viewmeetings.component.scss']
})
export class ViewmeetingsComponent implements OnInit {
  viewmeetingform: FormGroup;
  
  constructor(private fb: FormBuilder,private dataService: MeetingsService,private router:Router) {
  
   }
  ngOnInit() {
    this.viewmeetingform = new FormGroup({
      meeting_id: new FormControl(),
      topic: new FormControl(),
      discussion: new FormControl(),
      scheduled_date: new FormControl(),
      scheduled_time: new FormControl(),
      client_name: new FormControl()
      
   });
   if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');

  let Id = window.localStorage.getItem("editId");
  if(!Id) {
    this.router.navigate(['admin/meetings']);
    return;
  }
  this.dataService.getmeetingsId(+Id,client_id)
    .subscribe( data => {
     //this.viewform.controls[this.client_mobile].setValue('client_name')
      //this.client_email.nativeElement.value = 'This is new value';
      this.viewmeetingform.patchValue({
        meeting_id:data[0].meeting_id, topic: data[0].topic, discussion: data[0].discussion, scheduled_date: data[0].scheduled_date, scheduled_time: data[0].scheduled_time,client_name: data[0].client_name

     });
    });
  }
  }
  back(): void {
    this.router.navigate(['admin/meetings']);
  };

  get f(){
    return this.viewmeetingform.controls;
  }

  
  get topic() { return this.viewmeetingform.get('firm_name'); }
  get discussion() { return this.viewmeetingform.get('discussion'); }
  get scheduled_date  () { return this.viewmeetingform.get('scheduled_date'); }
  get scheduled_time() { return this.viewmeetingform.get('scheduled_time'); }
  get client_name() { return this.viewmeetingform.get('client_name'); }
 

}




