

<nb-card>
  <nb-card-header>Edit Task</nb-card-header>
  <nb-card-body>
      <form [formGroup]="viewtaskform" autocomplete="off" role="form" >
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="emp_name" class="label">Employee Name</label>

          <select  class="form-control" id="emp_name" name="emp_name" formControlName="emp_name">
              <option [ngValue]="null" [disabled]="true">Select Employee</option>
              <option *ngFor="let r of employees" [value]="r.emp_id">{{r.emp_name}}</option>
          </select>
          <input type="hidden" id="task_id" name="task_id" class="form-control" formControlName="task_id">
      </div>
      </div>
      <div class="col-md-6" *ngIf="case">
        <div class="form-group">
          <label for="case_name" class="label">Case Name</label>
          <select  class="form-control" id="case_name" name="case_name" formControlName="case_name">
              <option [ngValue]="null" [disabled]="true">Select Case</option>
              <option *ngFor="let r of caseNames" [value]="r.case_name">{{r.case_name}}</option>
          </select>
        </div>
      </div>
    </div><br>
    <div class="row">
      <div class="col-lg-6">
          <div class="form-group">
            <label for="task_date" class="label">Task Date </label><br>
            <input nbInput id="task_date" name="task_date"  formControlName="task_date" disabled
                placeholder="Form Picker" [nbDatepicker]="formpicker1">
         <nb-datepicker #formpicker1></nb-datepicker>      
          </div>
        </div>
      
      <div class="col-lg-6">
        <div class="form-group">
          <label for="task_name" class="label">Task Name</label>
          <input type="text" readonly  nbInput fullWidth id="task_name" placeholder="Task Name" name="task_name" formControlName="task_name" autocomplete="off">
        </div>
      </div>
    </div>
<br>
    <div class="row">
      <div class="col-lg-6">
          <div class="form-group">
            <label for="description" class="label">Description</label>
              
            <textarea  nbInput fullWidth id="description" placeholder="Description" name="description" formControlName="description" autocomplete="off"></textarea>
              </div>
      </div>
      <div class="col-lg-6">
          <div class="form-group">
            <label for="status" class="label">Status</label>
              <select name="status" disabled formControlName="status" class="form-control" >
                  
                <option value="Open" >Open</option>
                <option value="Close">Close</option>
              </select>
              
      </div>
  </div>
      <br>
      </div>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="start_date"  class="label">Start Date</label><br>
                <input nbInput id="start_date" name="start_date"  formControlName="start_date" disabled
                placeholder="Form Picker" [nbDatepicker]="formpicker">
         <nb-datepicker #formpicker></nb-datepicker>      
                  </div>
          </div>
          <div class="col-lg-6">
              <div class="form-group">
                <label for="end_date" class="label">End Date</label><br>
                <input nbInput id="end_date" name="end_date"  formControlName="end_date" disabled
                placeholder="Form Picker" [nbDatepicker]="formpicker2">
         <nb-datepicker #formpicker2></nb-datepicker>                      
        </div>
    </div>
        
        </div>

      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="note" class="label">Note</label>
                
              <textarea  nbInput fullWidth readonly id="note" placeholder="Note" name="note" formControlName="note" autocomplete="off"></textarea>
                </div>
        </div>
        </div>
    <br>
    <div class="row">
          <div class="col-lg-1">
            <button type="button"  (click)="back()" class="btn btn-success">Back</button>
          </div>
    </div>
   
   
    </form>
  </nb-card-body>
</nb-card>





