import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
    Copyright © 2020 <a href="https://myeplatform.com" target="_blank"><b>myEplatform</b></a>
    </span>
  `,
})
export class FooterComponent {
}
