import { Component, OnInit } from '@angular/core';
import {EmployeeserviceService} from 'app/services/employeeservice.service';
import { Employee } from 'app/admin/employee';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-createemployee',
  templateUrl: './createemployee.component.html',
  styleUrls: ['./createemployee.component.scss']
})
export class CreateemployeeComponent implements OnInit {

  empform: FormGroup;
  roles:any= [];
  
  constructor(private fb: FormBuilder,private dataService: EmployeeserviceService,private router:Router) {
    

    
    
   }

  ngOnInit() { 
    this.empform = new FormGroup({
      emp_name: new FormControl(),
      emp_email: new FormControl(),
      mobile: new FormControl(),
      city: new FormControl(),
      role: new FormControl(),
      address: new FormControl()

   });
   this.empform = this.fb.group({
    emp_name: ['', Validators.required],
    emp_email: ['', [Validators.required,Validators.minLength(1), Validators.email]],
    mobile: ['', [Validators.required,Validators.pattern("[0-9]{10}")]],
    city: ['', Validators.required],
    address: ['', Validators.required],
    role: ['', Validators.required],
   
  });
    this.dataService.getroles()
              .subscribe((data: any) => {
                this.roles=data;
               
            });
          }
  get f(){
            return this.empform.controls;
          }

  back(): void {
    this.router.navigate(['admin/employee']);
  };

  createemp(empform)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      this.dataService.validateemployee(empform.value.emp_email,client_id)
      .pipe()
      .subscribe(
        
          data => {
            if(data[0]["result"]=="Already Inserted")
            {
              alert("Employee already exists");
            }
            else{

              this.dataService.addemployees(empform.value.emp_name,empform.value.emp_email,empform.value.mobile,empform.value.city,empform.value.address,empform.value.role,client_id)
      .pipe()
      .subscribe(
          data => {
              this.router.navigate(['admin/employee']);
          },
          error => {
          });
              
            } 
              
          },
          
          error => {
          });
        
    
        }
  }


  get emp_email() { return this.empform.get('emp_email'); }
  get emp_name() { return this.empform.get('emp_name'); }
  get mobile() { return this.empform.get('mobile'); }
  get city() { return this.empform.get('city'); }
  get address() { return this.empform.get('address'); }
  get role() { return this.empform.get('role'); }
 

}
