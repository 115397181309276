

<nb-card>
    <nb-card-header>Edit Expense</nb-card-header>
    <nb-card-body>
        <form [formGroup]="editexpform" (ngSubmit)="editexpense(editexpform)"  autocomplete="off" role="form" >
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="expense_date" class="label">Date</label><br>
            <input type="hidden"  nbInput fullWidth id="expense_id" name="expense_id" formControlName="expense_id">
            <input nbInput id="expense_date" name="expense_date"  formControlName="expense_date"
            placeholder="Form Picker" [nbDatepicker]="formpicker">
     <nb-datepicker #formpicker></nb-datepicker>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="expense" class="label">Expense</label>
            <input type="text"  nbInput fullWidth id="expense" name="expense" formControlName="expense" autocomplete="off"  placeholder="Expense">
          </div>
        </div>
      
        <div class="col-lg-4">
          <div class="form-group">
            <label for="amount" class="label">Amount</label>
            <input type="text"  nbInput fullWidth id="amount" placeholder="Amount" name="amount" formControlName="amount" autocomplete="off">
          </div>
        </div>
        
      </div>
      
      <br>
      <div class="row">
        
            <div class="col-lg-6">
              <button type="button"  (click)="back()" class="btn btn-success" style="margin:10px">Back</button>
            
              <button type="submit" class="btn btn-primary" [disabled]="!editexpform.valid">Update</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  





