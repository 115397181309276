

  <div class="row">
    <!--<div class="col-xxxl-3 col-md-6" *ngFor="let statusCard of statusCards">
      <ngx-status-card [title]="statusCard.title" [type]="statusCard.type">
        <i [ngClass]="statusCard.iconClass"></i>
  
      </ngx-status-card>
    </div>-->
  
  
    <div class="col-md-4">    
    <label>
      <span class="icon" style="margin-bottom:4px;"><i class="fa ion-android-done"></i></span><br><br>
      <span class="title"><h5 style="float:right;"> {{total_open_tasks}} </h5></span>
      <p><span style="float: left;"><h5>Total Open Tasks </h5></span></label>
    </div>
    <div class="col-md-4">    
      <label >
        <span class="icon" style="margin-bottom:4px;"><i class="ion ion-android-done-all"></i></span><br><br>
        <span class="title"><h5 style="float:right;"> {{total_close_tasks}} </h5></span>
        <p><span style="float: left;"><h5>Total Closed Tasks </h5></span></label>
      </div>
      <div class="col-md-4">    
        <label>
          <span class="icon" style="margin-bottom:4px;"><i class="fas fa-tasks"></i></span><br><br>
          <span class="title"><h5 style="float:right;"> {{task}} </h5></span>
          <p><span style="float: left;"><h5>Total Tasks </h5></span></label>
        </div>
  </div>
  <br>

  <div class="row">
    <div class="col-md-12">
      <nb-card>
        <nb-card-header><h6>Case Dates  ({{today}} - {{tommorow}})</h6></nb-card-header>
        <nb-card-body>
      <div class="container">
          <div class="row">
            <div class="col-md-12">
              <br>
              <table class="table table-hover" >
                <thead>
                <tr>
                  <th>Prev. Date</th>
                  <th>Court's Name</th>
                  <th>Case No.</th>
                  <th>Name of Parties</th>
                  <th>Stage</th>
                  <th>Next Date</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let c of ccdates let i = index"  >
                  <td>{{c.prev_date}}</td>
                  <td>{{c.court_name}}</td>
                  <td>{{c.case_no}}</td>
                  <td><b>{{c.client_name}}</b> |  {{c.party_two}}</td>
                  <td>{{c.stage}}</td>
                  <td>{{c.next_date}}</td>
                  
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </nb-card-body>
        </nb-card>
      </div>
    </div>
    <br>
  <div class="row">
    <div class="col-md-6">
      <nb-card>
        <nb-card-header><h6>Court Tasks  ({{today}})</h6></nb-card-header>
        <nb-card-body>
      <div class="container">
          <div class="row">
           <!-- <div class="col-md-6">
              <div class="form-group">
                <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
            </div>
            </div>
            <div class="col-md-6">
            </div>-->
            <div class="col-md-12">
              <br>
              <table class="table table-hover" >
                <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Employee</th>
                  <th>Case Name</th>
                  <th>Task</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let c of ctask let i = index">
         
                  <td>{{i + 1}}</td>
                  <td>{{c.emp_id}}</td>
                  <td>{{c.case_name}}</td>
                  <td>{{c.task_name}}</td>
                  <td><a (click)="updatetask(c)"  >
                    <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a>  </td>
                  
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </nb-card-body>
        </nb-card>
      </div>
    
    <div class="col-md-6">
      <nb-card>
        <nb-card-header><h6>Other Tasks ({{today}})</h6></nb-card-header>
        <nb-card-body>
      <div class="container">
          <div class="row">
           <!-- <div class="col-md-6">
              <div class="form-group">
                <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
            </div>
            </div>
            <div class="col-md-6">
            </div>-->
            <div class="col-md-12">
              <br>
              <table class="table table-hover" >
                <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Employee</th>
                  <th>Company Name</th>
                  <th>Task</th>
                  <th>Edit</th>
                   
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let t of othertask let i = index"  >
         
                  <td>{{i + 1}}</td>
                  <td>{{t.emp_id}}</td>
                  <td>{{t.firm_name}}</td>
                  <td>{{t.task_name}}</td>
                  <td><a (click)="updateothertask(t)"  >
                    <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a>  </td>
                  
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </nb-card-body>
        </nb-card>
      </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <nb-card >
        <nb-card-header><h6>Calls ({{today}} - {{tommorow}})</h6></nb-card-header>
        <nb-card-body>
      <div class="container">
          <div class="row">
            <!--<div class="col-md-6">
              <div class="form-group">
                <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
            </div>
            </div>
            <div class="col-md-6">
            </div>-->
            <div class="col-md-12">
              <br>
              <table class="table table-hover" >
                <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Case</th>
                  <th>Task</th>
                  <th>Date</th>
                  <th>Edit</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let c of calls let i = index"  >
         
                  <td>{{i + 1}}</td>
                  <td>{{c.case_name}}</td>
                  <td>{{c.task_name}}</td>
                  <td>{{c.call_date}}</td>
                  <td><a (click)="updatecalls(c)"  >
                    <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a></td>
                  </tr>
                </tbody>
              </table>
              <!--<div class="alert alert-info alert-dismissible" role="alert" >
                <i class="fa fa-info-circle"></i> 
                No Records found
              </div>-->
            </div>
          </div>
        </div>
        </nb-card-body>
      </nb-card>
      </div>

      <div class="col-md-6">
        <nb-card >
          <nb-card-header><h6>Selftasks ({{today}})</h6></nb-card-header>
          <nb-card-body>
        <div class="container">
            <div class="row">
              <!--<div class="col-md-6">
                <div class="form-group">
                  <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
              </div>
              </div>
              <div class="col-md-6">
              </div>-->
              <div class="col-md-12">
                <br>
                <table class="table table-hover" >
                  <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Case</th>
                    <th>Task</th>
                    <th>Edit</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let c of selftask let i = index"  >
           
                    <td>{{i + 1}}</td>
                    <td>{{c.case_name}}</td>
                    <td>{{c.task_name}}</td>
                   
                    <td><a (click)="updateselftask(c)"  >
                      <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a>
                    </tr>
                  </tbody>
                </table>
                <!--<div class="alert alert-info alert-dismissible" role="alert" >
                  <i class="fa fa-info-circle"></i> 
                  No Records found
                </div>-->
              </div>
            </div>
          </div>
          </nb-card-body>
        </nb-card>
        </div>
    
        </div>
  
  
  
  
  