import { Component, OnInit } from '@angular/core';
import {CourtService} from 'app/services/court.service';
import { Court } from 'app/admin/court';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-editcourt',
  templateUrl: './editcourt.component.html',
  styleUrls: ['./editcourt.component.scss']
})
export class EditcourtComponent implements OnInit {
  editcourt: FormGroup;
  roles:any[]= [];
  
  constructor(private fb: FormBuilder,private dataService: CourtService,private router:Router) {
    

    
   this.editcourt = this.fb.group({
    court_name: ['', Validators.required],
    court_type: ['', Validators.required],
    judge_name: ['', Validators.required],
    city: ['', Validators.required]
  });
    

    
   }
  


  ngOnInit() {
    this.editcourt = new FormGroup({
      court_id: new FormControl(),
      court_name: new FormControl(),
      judge_name: new FormControl(),
      court_type: new FormControl(),
      city: new FormControl(),
      
   });
    if (localStorage.length > 0) {
     
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
  
    let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['admin/court']);
      return;
    }
    this.dataService.getcourtId(+Id,client_id)
      .subscribe( data => {
       //this.viewform.controls[this.client_mobile].setValue('client_name')
        //this.client_email.nativeElement.value = 'This is new value';
        this.editcourt.patchValue({
          court_id:data[0].court_id, court_name: data[0].court_name, judge_name: data[0].judge_name, court_type: data[0].court_type, city: data[0].city
 
       });
      });
    }
  }


  back(): void {
    this.router.navigate(['admin/court']);
  };

  courtedit(editcourt)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
    
    this.dataService.updatecourt(editcourt.value.court_id, editcourt.value.court_name,editcourt.value.judge_name,editcourt.value.court_type,editcourt.value.city,client_id)
 
    .pipe()
    .subscribe(
        data => {
            this.router.navigate(['admin/court']);
        },
        error => {
        });
      }
  }

  get court_name() { return this.editcourt.get('court_name'); }
  get court_type() { return this.editcourt.get('court_type'); }
  get judge_name() { return this.editcourt.get('judge_name'); }
  get city() { return this.editcourt.get('city'); }
 

}



