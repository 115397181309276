import { Component, OnInit } from '@angular/core';
import {CourtService} from 'app/services/court.service';
import { Court } from 'app/admin/court';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-court_view',
  templateUrl: './viewcourt.component.html',
  styleUrls: ['./viewcourt.component.scss']
})
export class ViewcourtComponent implements OnInit {
  court_view: FormGroup;
  
  constructor(private fb: FormBuilder,private dataService: CourtService,private router:Router) {
    

    
    this.court_view = this.fb.group({
      court_name: ['', Validators.required],
      court_type: ['', Validators.required],
      judge_name: ['', Validators.required],
      city: ['', Validators.required]
    });
   }
  


  ngOnInit() {
    this.court_view = new FormGroup({
      court_id: new FormControl(),
      court_name: new FormControl(),
      judge_name: new FormControl(),
      court_type: new FormControl(),
      city: new FormControl()
      
   });
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
  
    let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['admin/court']);
      return;
    }
    this.dataService.getcourtId(+Id,client_id)
      .subscribe( data => {
       //this.viewform.controls[this.client_mobile].setValue('client_name')
        //this.client_email.nativeElement.value = 'This is new value';
        this.court_view.patchValue({
          court_id:data[0].court_id, court_name: data[0].court_name, judge_name: data[0].judge_name, court_type: data[0].court_type, city: data[0].city
 
       });
      });
    }
  }


  back(): void {
    this.router.navigate(['admin/court']);
  };
  get court_id() { return this.court_view.get('court_id'); }
  get court_name() { return this.court_view.get('court_name'); }
  get court_type() { return this.court_view.get('court_type'); }
  get judge_name() { return this.court_view.get('judge_name'); }
  get city() { return this.court_view.get('city'); }
  
 

}
