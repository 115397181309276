import { Component, OnInit } from '@angular/core';
import {TaskService} from 'app/services/task.service';
import { Task } from 'app/admin/task';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-edittask',
  templateUrl: './edittask.component.html',
  styleUrls: ['./edittask.component.scss']
})
export class EdittaskComponent implements OnInit {

  edittaskform: FormGroup;
  employees:any= [];
  caseNames:any= [];
  case:boolean=false;

  constructor(private fb: FormBuilder,private dataService: TaskService,private router:Router) { }

  ngOnInit(){
    this.edittaskform = new FormGroup({
      task_id: new FormControl(),
      emp_name: new FormControl(),
      task_name: new FormControl(),
      task_date: new FormControl(),
      description: new FormControl(),
      case_name: new FormControl(),
      status: new FormControl(),

    

   });
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
    this.dataService.getallemp(client_id)
              .subscribe((data: any) => {
                this.employees=data;
               
            });
            this.dataService.getcasenames(client_id)
            .subscribe((data: any) => {
              this.caseNames=data;
             
          });

          let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['admin/court']);
      return;
    }
    this.dataService.gettaskId(+Id,client_id)
      .subscribe( data => {
       //this.viewform.controls[this.client_mobile].setValue('client_name')
        //this.client_email.nativeElement.value = 'This is new value';
       if(data[0].case_name==null || data[0].case_name=="" )
       {
         this.case=false;
        this.edittaskform.patchValue({
          task_id:data[0].task_id,emp_name: data[0].emp_id, task_name: data[0].task_name, task_date: data[0].task_date,description: data[0].description,status: data[0].status
      
       });

       }
       else
       {
         this.case=true;
        this.edittaskform.patchValue({
          task_id:data[0].task_id, case_name: data[0].case_name, emp_name: data[0].emp_id, task_name: data[0].task_name, task_date: data[0].task_date,description: data[0].description,status: data[0].status
      
       });
       }
      });
          }
  }

  edittask(edittaskform)
  {
    let formdate=edittaskform.value.task_date;
    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let todaysdate= year + "-" + month + "-" + date;
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
      

      var as=new Date(edittaskform.value.task_date);
      let d = ("0" + as.getDate()).slice(-2);
      let m = ("0" + (as.getMonth() + 1)).slice(-2);
      let y = as.getFullYear();
      let c_date= y + "-" + m + "-" + d;
    
    this.dataService.updatetasks(edittaskform.value.task_id,user_id,edittaskform.value.emp_name,edittaskform.value.case_name,c_date,edittaskform.value.task_name,edittaskform.value.description,edittaskform.value.status,client_id)
 
    .pipe()
    .subscribe(
        data => {
            this.router.navigate(['admin/task']);
        },
        error => {
        });
     
      }
  }

          back(): void {
            this.router.navigate(['admin/task']);
          };
          get case_name() { return this.edittaskform.get('case_name'); }
          get task_date() { return this.edittaskform.get('task_date'); }
          get task_name() { return this.edittaskform.get('task_name'); }
          get description() { return this.edittaskform.get('description'); }
          get note() { return this.edittaskform.get('note'); }
          get emp_name() { return this.edittaskform.get('emp_name'); }
          get status() { return this.edittaskform.get('status'); }

}
