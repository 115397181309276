

<nb-card>
    <nb-card-header>Add Meeting</nb-card-header>
    <nb-card-body>
        <form [formGroup]="addmeetingform" (ngSubmit)="addmeeting(addmeetingform)"  autocomplete="off" role="form" >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="client_name" class="label">Client Name*</label>

            <input type="text"  nbInput fullWidth id="client_name" name="client_name" formControlName="client_name" autocomplete="off" placeholder="Client Name">
            <div *ngIf="f.client_name.touched && f.client_name.invalid" class="alert alert-danger">
                <div *ngIf="f.client_name.errors.required">Client Name is required.</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="topic" class="label">Topic*</label>
            <input type="text"  nbInput fullWidth id="topic" name="topic" formControlName="topic" autocomplete="off"  placeholder="Topic">
            <div *ngIf="f.topic.touched && f.topic.invalid" class="alert alert-danger">
                <div *ngIf="f.topic.errors.required">Topic is required.</div>
            </div>
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="scheduled_date" class="label">Date* </label><br>
            <input nbInput id="scheduled_date" name="scheduled_date"  formControlName="scheduled_date"
              placeholder="Date Picker" [nbDatepicker]="formpicker">
       <nb-datepicker #formpicker></nb-datepicker>
            <div *ngIf="f.scheduled_date.touched && f.scheduled_date.invalid" class="alert alert-danger">
                <div *ngIf="f.scheduled_date.errors.required">Date is required.</div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="scheduled_time" class="label">Time*</label>
            <input type="text"  nbInput fullWidth id="scheduled_time" name="scheduled_time" formControlName="scheduled_time" autocomplete="off" placeholder="Time Ex: 12 pm">
            <div *ngIf="f.scheduled_time.touched && f.scheduled_time.invalid" class="alert alert-danger">
                <div *ngIf="f.scheduled_time.errors.required">Time is required.</div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="discussion" class="label">Discussion</label>
            <textarea rows="10" cols="10"  nbInput fullWidth id="discussion" name="discussion" formControlName="discussion" autocomplete="off" placeholder="Discussion"></textarea>
            
          </div>
        </div>
      </div>
      <div class="row">
        
            <div class="col-lg-6">
              <button type="button" class="btn btn-success"   (click)="back()" style="margin:10px">Back</button>
          
              <button type="submit" class="btn btn-primary" [disabled]="!addmeetingform.valid">Add</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  





