import { Component, OnInit } from '@angular/core';
import {ReceiptService} from 'app/services/receipt.service';
import { Receipt } from 'app/admin/receipt';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-viewotherreceipt',
  templateUrl: './viewotherreceipt.component.html',
  styleUrls: ['./viewotherreceipt.component.scss']
})
export class ViewotherreceiptComponent implements OnInit {
  viewrecform: FormGroup;
  firmNames:any=[];
  works:any=[];
  constructor(private fb: FormBuilder,private dataService: ReceiptService,private router:Router) {
  
   }

  ngOnInit() { 
    this.viewrecform = new FormGroup({
      or_id: new FormControl(),
      firm_name: new FormControl(),
      rdate: new FormControl(),
      work: new FormControl(),
      agreed_amount: new FormControl(),
      paid: new FormControl(),
      due: new FormControl(),
      balance: new FormControl(),
      installment: new FormControl()
   });
  if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');
    let user_id = localStorage.getItem('user_id');
    
    this.dataService.getfirmnames(client_id)
    .subscribe((data: any) => {
      this.firmNames=data;
     
  });
    let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['admin/othereceipts']);
      return;
    }
  this.dataService.getotherReceipt(+Id,client_id)
      .subscribe( data => {
        
        this.viewrecform.patchValue({
         
          or_id:data[0].or_id, firm_name: data[0].firm_name,rdate: data[0].rdate,paid: data[0].paid,due:data[0].due,balance: data[0].balance,agreed_amount: data[0].agreed_amount, work:data[0].work,installment:data[0].installment
       });
       this.dataService.getviewworks(client_id,data[0].firm_name)
              .subscribe((data: any) => {
                this.works=data;
   
      });
    });

    }
}


get f(){
  return this.viewrecform.controls;
}


  otherback(): void {
    this.router.navigate(['admin/otherreceipts']);
  };

  get or_id() { return this.viewrecform.get('or_id'); }
  get case_name() { return this.viewrecform.get('case_name'); }
  get rdate() { return this.viewrecform.get('rdate'); }
  get paid() { return this.viewrecform.get('paid'); }
 

}
