import { Component, OnInit } from '@angular/core';
import {CasedateService} from 'app/services/casedate.service';
import { Casedate } from 'app/admin/casedate';
import { Court } from 'app/admin/court';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-dashboardccduedates',
  templateUrl: './dashboardccduedates.component.html',
  styleUrls: ['./dashboardccduedates.component.scss']
})
export class DashboardccduedatesComponent implements OnInit {
  casedateform: FormGroup;
  clientNames:any= [];
  caseNames:any= [];
  isDisabled = false;
  constructor(private fb: FormBuilder,private dataService: CasedateService,private router:Router) {
 
   }
  


  ngOnInit() {
    this.casedateform = new FormGroup({
      case_no: new FormControl(),
      case_name: new FormControl(),
      client_name: new FormControl(),
      prev_date: new FormControl(),
      next_date: new FormControl(),
      stage: new FormControl(),
      court_name:new FormControl(),
      party_two:new FormControl()
   });
    this.casedateform = this.fb.group({
      case_no: [],
      client_name: ['', Validators.required],
      next_date: ['', Validators.required],
      prev_date:[],
      case_name: ['', Validators.required],
      stage: [],
      court_name: [],
      party_two: [],



    });
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.getclientnames(client_id)
              .subscribe((data: any) => {
                this.clientNames=data;      
            });
            let Id = window.localStorage.getItem("caseId");
    if(!Id) {
      this.router.navigate(['admin/receipt']);
      return;
    }
    
            this.dataService.getcasedetails(client_id,Id)
            .subscribe((data: any) => {
            this.casedateform.patchValue({
                case_name:data[0]["case_name"],client_name:data[0]["client_name"]
            });

            
        this.dataService.getcaseno(client_id,data[0]["client_name"],data[0]["case_name"])
        .subscribe( data1 => {
          
              this.casedateform.patchValue({
            
                case_no:data1[0].case_no,stage:data1[0].stage,prev_date:data1[0].prev_date,court_name:data1[0].court_name, party_two:data1[0].party_two
              });
          
        });
             
          });
  
          

          
      }
  }

  get f(){
    return this.casedateform.controls;
  }
  back(): void {
    this.router.navigate(['admin/casedate']);
  };

  
  createcasedate(casedateform)
  {

    let formdate=casedateform.value.next_date;
    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let todaysdate= year + "-" + month + "-" + date;

    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
   
    var as=new Date(casedateform.value.prev_date);
    let d = ("0" + as.getDate()).slice(-2);
    let m = ("0" + (as.getMonth() + 1)).slice(-2);
    let y = as.getFullYear();
    let prev_date= y + "-" + m + "-" + d;

    var as1=new Date(casedateform.value.next_date);
    let d1 = ("0" + as1.getDate()).slice(-2);
    let m1 = ("0" + (as1.getMonth() + 1)).slice(-2);
    let y1 = as1.getFullYear();
    let next_date= y1 + "-" + m1 + "-" + d1;

    
     /* if(next_date >= todaysdate)
      {*/
      this.dataService.getcasenames(client_id,casedateform.value.client_name)
          .subscribe((data: any) => {
            this.caseNames=data;
           
        });
    this.dataService.addcasedate(user_id,casedateform.value.case_no,casedateform.value.case_name,casedateform.value.client_name,casedateform.value.stage,prev_date,next_date,client_id)
      .pipe()
      .subscribe(
          data => {
              this.router.navigate(['admin/casedate']);
          },
          error => {
          });
        /*}
    else
    {
      alert("Select date greater than current date");
    }*/
    }
    
  }

  get case_no() { return this.casedateform.get('case_no'); }
    get case_name() { return this.casedateform.get('case_name'); }
  get client_name() { return this.casedateform.get('client_name'); }
  get prev_date() { return this.casedateform.get('prev_date'); }
  get next_date() { return this.casedateform.get('next_date'); }
 

}
