import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import { EmpComponent } from './emp.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TaskComponent } from './task/task.component';
import { ViewtaskComponent } from './viewtask/viewtask.component';
import { EdittaskComponent } from './edittask/edittask.component';
import { SelftaskComponent } from './selftask/selftask.component';
import { AddselftaskComponent } from './addselftask/addselftask.component';
import { EditselftaskComponent } from './editselftask/editselftask.component';
import { ViewselftaskComponent } from './viewselftask/viewselftask.component';
import { EmptaskreportComponent } from './emptaskreport/emptaskreport.component';
import { EmpselftaskreportComponent } from './empselftaskreport/empselftaskreport.component';
import { TaskreportComponent } from 'app/admin/taskreport/taskreport.component';
import { SearchfilterPipe } from 'app/application/searchfilter.pipe';
import { EmpcallsComponent } from './empcalls/empcalls.component';
import { EmpaddcallsComponent } from './empaddcalls/empaddcalls.component';
import { EmpeditcallsComponent } from './empeditcalls/empeditcalls.component';
import { EmpviewcallsComponent } from './empviewcalls/empviewcalls.component';
import { OthertasksempComponent } from './othertasksemp/othertasksemp.component';
import { EditothertasksempComponent } from './editothertasksemp/editothertasksemp.component';
import { ViewothertasksempComponent } from './viewothertasksemp/viewothertasksemp.component';
import { EmpfirmtaskreportComponent } from './empfirmtaskreport/empfirmtaskreport.component';
import { CasedatereportComponent } from './casedatereport/casedatereport.component';
import { EmpexpenseComponent } from './empexpense/empexpense.component';
import { AddempexpenseComponent } from './addempexpense/addempexpense.component';
import { EditempexpenseComponent } from './editempexpense/editempexpense.component';
import { ViewempexpenseComponent } from './viewempexpense/viewempexpense.component';
import { EmpexpensereportComponent } from './empexpensereport/empexpensereport.component';


const routes: Routes = [{
  path: '',
  component: EmpComponent,
  children: [
    {
      path: 'dashboard',
      component: DashboardComponent,
    },
    {
      path: 'task',
      component: TaskComponent,
    },
    {
      path: 'viewtask',
      component: ViewtaskComponent,
    },
    {
      path: 'edittask',
      component: EdittaskComponent,
    },
    {
      path: 'selftask',
      component: SelftaskComponent,
    },
    {
      path: 'editselftask',
      component: EditselftaskComponent,
    },
    {
      path: 'viewselftask',
      component: ViewselftaskComponent,
    },
    {
      path: 'addselftask',
      component: AddselftaskComponent,
    },
    {
      path: 'taskreport',
      component: EmptaskreportComponent,
    },
    {
      path: 'selftaskreport',
      component: EmpselftaskreportComponent,
    },
    {
      path: 'calls',
      component: EmpcallsComponent,
    },
    {
      path: 'addcalls',
      component: EmpaddcallsComponent,
    },
    {
      path: 'editcalls',
      component: EmpeditcallsComponent,
    },
    {
      path: 'viewcalls',
      component: EmpviewcallsComponent,
    },
    {
      path: 'othertasks',
      component: OthertasksempComponent,
    },
    {
      path: 'viewothertasks',
      component: ViewothertasksempComponent,
    },
    {
      path: 'editothertasks',
      component: EditothertasksempComponent,
    },
    {
      path: 'firmtaskreport',
      component: EmpfirmtaskreportComponent,
    },
    {
      path: 'casedatereport',
      component: CasedatereportComponent,
    },
    {
      path: 'expenses',
      component: EmpexpenseComponent,
    },
    {
      path: 'addexpense',
      component: AddempexpenseComponent,
    },
    {
      path: 'viewexpense',
      component: ViewempexpenseComponent,
    },
    {
      path: 'editexpense',
      component: EditempexpenseComponent,
    },
    {
      path: 'expensereport',
      component: EmpexpensereportComponent,
    },
  ]
}]

@NgModule({
  imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class EmpRoutingModule { }
