import { Component, OnInit } from '@angular/core';
import { Calls } from 'app/admin/calls';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { CallsService } from 'app/services/calls.service';

@Component({
  selector: 'ngx-calls',
  templateUrl: './calls.component.html',
  styleUrls: ['./calls.component.scss']
})
export class CallsComponent implements OnInit {
  calls: Calls[];
  cat:number;
 
  viewformModal: boolean;
  public searchText:string;
  showModal: boolean;
  callsform: FormGroup;
  submitted = false;
  constructor(private dataService: CallsService ,private router:Router,private fb: FormBuilder) { }

  ngOnInit(): void {
    this.callsform = new FormGroup({
      call_id:new FormControl(),
      status: new FormControl(),
    
      
      
   });
   this.callsform = this.fb.group({
     call_id:[],
    status: ['', Validators.required],
  
  });
    this.getcalldetails();
  }
  show()
  {
    this.viewformModal = true; // Show-Hide Modal Check
    this.callsform = new FormGroup({
      call_id:new FormControl(),
      status: new FormControl(),
   });

   this.callsform = this.fb.group({
     call_id:[],
   status: ['', Validators.required],
   
 });
    
  }
  hide()
  {
    this.viewformModal=false;
  }
 
  getcalldetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getalltasks(this.cat,client_id).subscribe(response =>
      {
        this.calls = response.map(item =>
        {
          return new Calls(
            item.call_id,
            item.client_id,
            item.user_id,
            item.case_name,
            item.task_name,
             item.call_date,
              item.emp_id,
              item.note, 
              item.status
          );
        });
      });

    }
  }
  
  addcalls(): void {
    this.router.navigate(['admin/addcalls']);
  };
  back(): void {
    this.router.navigate(['admin/calls']);
  };
  
  viewcalls(c: Calls): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.call_id.toString());
    this.router.navigate(['admin/viewcalls']);
  };
  deletecalls(c:Calls)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removecalls(c.call_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getcalldetails();
    })
  }
  }
  updatecalls(c: Calls): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.call_id.toString());
    this.router.navigate(['admin/editcalls']);
  };

  editstatus(emp: Calls): void {
    
    this.viewformModal=true;
   
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getcallId(emp.call_id,client_id)
    .subscribe( data => {  
      this.callsform.patchValue({
        call_id:data[0].call_id,status:data[0].status
     });
    });
  }
  }
  statusupdate(callsform)
  {
   
    this.submitted=true;
    if (this.callsform.invalid) {
      return;
  }
  if(this.submitted)
  {

    this.viewformModal = false;
    
  }
    if (localStorage.length > 0) {
      let user_id = localStorage.getItem('user_id');
      let client_id = localStorage.getItem('client_id');
    

              this.dataService.editstatus(callsform.value.status,callsform.value.call_id,client_id)
      .pipe()
      .subscribe(
          data => {
              //this.router.navigate(['radmin/task']);
              //this.getproddetails();
              

          },
          error => {
          });
              
            }       
  }

  
  }