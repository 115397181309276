

<nb-card>
    <nb-card-header>Add Employee</nb-card-header>
    <nb-card-body>
        <form [formGroup]="empform" (ngSubmit)="createemp(empform)"  autocomplete="off" role="form" >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="emp_name" class="label">Name*</label>

            <input type="text"  nbInput fullWidth id="emp_name" name="emp_name" formControlName="emp_name" autocomplete="off" placeholder="Name">
            <div *ngIf="f.emp_name.touched && f.emp_name.invalid" class="alert alert-danger">
              <div *ngIf="f.emp_name.errors.required">Name is required.</div>
             
             
          </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="emp_email" class="label">Email*</label>
            <input type="email"  nbInput fullWidth id="emp_email" name="emp_email" formControlName="emp_email" autocomplete="off"  placeholder="Email">
            <div *ngIf="f.emp_email.touched && f.emp_email.invalid" class="alert alert-danger">
              <div *ngIf="f.emp_email.errors.required">Email is required.</div>
              <div *ngIf="f.emp_email.errors.email">Enter valid email id</div>
             
          </div>
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="mobile" class="label">Mobile*</label>
            <input type="text"  nbInput fullWidth id="mobile" placeholder="Mobile" maxlength="10" name="mobile" formControlName="mobile" autocomplete="off">
            <div *ngIf="f.mobile.touched && f.mobile.invalid" class="alert alert-danger">
              <div *ngIf="f.mobile.errors.required">Mobile is required.</div>
              <div *ngIf="f.mobile.errors.pattern">Enter valid mobile no.</div>
             
          </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="city" class="label">City*</label>
            <input type="text"  nbInput fullWidth id="city" name="city" formControlName="city" autocomplete="off" placeholder="City">
            <div *ngIf="f.city.touched && f.city.invalid" class="alert alert-danger">
              <div *ngIf="f.city.errors.required">City is required.</div>
             
          </div>
          </div>
        </div>
      </div>
<br>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="role" class="label">Role*</label>
                <!--<select name="role" formControlName="role" class="form-control" >
                  <option value="Active">Active</option>
                  <option value="In-active">In-active</option>
                </select>-->
                <select  class="form-control" id="role" name="role" formControlName="role">
                    <option [ngValue]="null" [disabled]="true">Choose Role</option>
                    <option *ngFor="let r of roles" [value]="r.role">{{r.role}}</option>
                </select> 
                <div *ngIf="f.role.touched && f.role.invalid" class="alert alert-danger">
                  <div *ngIf="f.role.errors.required">Role is required.</div>
              </div>
                </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="address" class="label">Address*</label>
            <input type="text"  nbInput fullWidth id="address" name="address" formControlName="address" autocomplete="off" placeholder="Address">
            <div *ngIf="f.address.touched && f.address.invalid" class="alert alert-danger">
              <div *ngIf="f.address.errors.required">Address is required.</div>
          </div>
          </div>
        </div>
        </div>
      <br>
      <div class="row">
        
            <div class="col-lg-6">
              <button type="button"  (click)="back()" class="btn btn-success" style="margin:10px">Back</button>
         
              <button type="submit" class="btn btn-primary" [disabled]="!empform.valid">Add</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  




