

<nb-card>
    <nb-card-header>Edit Task</nb-card-header>
    <nb-card-body>
        <form [formGroup]="edittaskform" (ngSubmit)="edittask(edittaskform)"  autocomplete="off" role="form" >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="emp_name" class="label">Employee Name</label>

            <select  class="form-control" id="emp_name" name="emp_name" formControlName="emp_name">
                <option [ngValue]="null" [disabled]="true">Select Employee</option>
                <option *ngFor="let r of employees" [value]="r.emp_id">{{r.emp_name}}</option>
            </select>
            <input type="hidden" id="othertask_id" name="othertask_id" class="form-control" formControlName="othertask_id">
        </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="firm_name" class="label">Company Name</label>
            <select  class="form-control" id="firm_name" name="firm_name" formControlName="firm_name">
                <option [ngValue]="null" [disabled]="true">Select Company</option>
                <option *ngFor="let r of firmNames" [value]="r.firm_name">{{r.firm_name}}</option>
            </select>
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="task_date" class="label">Task Date </label><br>
              <input nbInput id="task_date" name="task_date"  formControlName="task_date"
              placeholder="Form Picker" [nbDatepicker]="formpicker">
       <nb-datepicker #formpicker></nb-datepicker>
            </div>
          </div>
        
        <div class="col-lg-6">
          <div class="form-group">
            <label for="task_name" class="label">Task Name</label>
            <input type="text"  nbInput fullWidth id="task_name" placeholder="Task Name" name="task_name" formControlName="task_name" autocomplete="off">
          </div>
        </div>
      </div>
<br>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="description" class="label">Description</label>
                
              <textarea rows="10" cols="8"  nbInput fullWidth id="description" name="description" formControlName="description" autocomplete="off" placeholder="Description"></textarea>
                </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
              <label for="status" class="label">Status</label>
                <select name="status" formControlName="status" class="form-control" >
                    
                  <option value="Open" >Open</option>
                  <option value="Close">Close</option>
                </select>
                
        </div>
    </div>
        
        </div>
      <br>
      <div class="row">
       
            <div class="col-lg-1">
              <button type="button"  (click)="otherback()"  class="btn btn-success">Back</button>
            </div>
            <div class="col-lg-1">
              <button type="submit" class="btn btn-primary" [disabled]="!edittaskform.valid">Update</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  




