

<div class="row">
    <div class="col-md-4">    
      <label>
        <span class="icon" style="margin-bottom:4px;"><i class="ion-calendar"></i></span><br><br>
                        <span class="title"><h5 style="float:right;"> {{cc_dtoday}} </h5></span>
                          <p><span style="float: left;"><h5>Today's Case Dates </h5></span></label>
      </div>
      <div class="col-md-4">    
        <label>
          <span class="icon" style="margin-bottom:4px;"><i class="ion-calendar"></i></span><br><br>
                          <span class="title"><h5 style="float:right;"> {{cc_dtomorrow}} </h5></span>
                            <p><span style="float: left;"><h5>Tomorrow's Case Dates </h5></span></label>
        </div>
      <!--<div class="col-md-4">    
        <label>
          <span class="icon" style="margin-bottom:4px;"><i class="fas fa-balance-scale"></i></span><br><br>
                          <span class="title"><h5 style="float:right;"> {{court}} </h5></span>
                            <p><span style="float: left;"><h5>Court Cases </h5></span></label>
        </div>-->
</div>

<div class="row">
  <div class="col-md-4">    
  <label>
    <span class="icon" style="margin-bottom:4px;"><i class="fa ion-android-done"></i></span><br><br>
										<span class="title"><h5 style="float:right;"> {{total_open_tasks}} </h5></span>
											<p><span style="float: left;"><h5>Total Open Tasks </h5></span></label>
  </div>
  <div class="col-md-4">    
    <label >
      <span class="icon" style="margin-bottom:4px;"><i class="ion ion-android-done-all"></i></span><br><br>
                      <span class="title"><h5 style="float:right;"> {{total_close_tasks}} </h5></span>
                        <p><span style="float: left;"><h5>Total Closed Tasks </h5></span></label>
    </div>
    <div class="col-md-4">    
      <label>
        <span class="icon" style="margin-bottom:4px;"><i class="fas fa-tasks"></i></span><br><br>
                        <span class="title"><h5 style="float:right;"> {{task}} </h5></span>
                          <p><span style="float: left;"><h5>Total Tasks </h5></span></label>
      </div>
</div>
<br>
<div class="row">
  <div class="col-md-12">
    <nb-card>
      <nb-card-header><h6>Case Dates  ({{today}} - {{tommorow}})</h6></nb-card-header>
      <nb-card-body>
    <div class="container">
        <div class="row">
          <div class="col-md-12">
            <br>
            <table class="table table-hover" >
              <thead>
              <tr>
                <th>Prev. Date</th>
                <th>Judge Name</th>
                <th>Case No.</th>
                <th>Name of Parties</th>
                <th>Stage</th>
                <th>Next Date</th>
                <th>Edit</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let c of ccdates let i = index"  >
                <td>{{c.prev_date}}</td>
                <td>{{c.court_name}}</td>
                <td>{{c.case_no}}</td>
                <td><b>{{c.client_name}}</b> |  {{c.user_id}} |  {{c.party_two}}</td>
                <td>{{c.stage}}</td>
                <td>{{c.next_date}}</td>
                <td><a (click)="addcasedate(c)" target="_blank" >
                  <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </nb-card-body>
      </nb-card>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <nb-card >
        <nb-card-header><h6>Calls  ({{today}} - {{tommorow}})</h6></nb-card-header>
        <nb-card-body>
      <div class="container">
          <div class="row">
           
            <div class="col-md-12">
              <br>
              <table class="table table-hover" >
                <thead>
                <tr>
                  
                  <th>Employee</th>
                  <th>Case</th>
                  <th>Task</th>
                  <th>Date</th>
                  <th>Edit</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let c of calls let i = index">
                  <td>{{c.emp_id}}</td>
                  <td>{{c.case_name}}</td>
                  <td>{{c.task_name}}</td>
                  <td>{{c.call_date}}</td>
                  <td><a (click)="updatecalls(c)"  >
                    <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
  <div class="col-md-6">
    <nb-card>
      <nb-card-header><h6>Meetings  ({{today}} - {{tommorow}})</h6></nb-card-header>
      <nb-card-body>
    <div class="container">
        <div class="row">
          <div class="col-md-12">
            <br>
            <table class="table table-hover" >
              <thead>
              <tr>
                
                <th>Client</th>
                <th>Date</th>
                <th>Time</th>
                <th>Edit</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let c of meetings let i = index"  >
                <td>{{c.client_name}}</td>
                <td>{{c.scheduled_date}}</td>
                <td>{{c.scheduled_time}}</td>
                <td><a (click)="updatemeetings(c)"  >
                  <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
</nb-card-body>
</nb-card>
</div>
</div>
  
  
  
<div class="row">
  <div class="col-md-6">
    <nb-card>
      <nb-card-header><h6>Court Tasks  ({{today}})</h6></nb-card-header>
      <nb-card-body>
    <div class="container">
        <div class="row">
          <div class="col-md-12">
            <br>
            <table class="table table-hover" >
              <thead>
              <tr>
                
                <th>Employee</th>
                <th>Case Name</th>
                <th>Task</th>
                <th>Edit</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let c of ctask let i = index">
                <td>{{c.emp_id}}</td>
                <td>{{c.case_name}}</td>
                <td>{{c.task_name}}</td>
                <td><a target="_blank" (click)="updatetask(c)"  >
                  <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a>  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </nb-card-body>
      </nb-card>
    </div>
  
  <div class="col-md-6">
    <nb-card>
      <nb-card-header><h6>Other Tasks ({{today}})</h6></nb-card-header>
      <nb-card-body>
    <div class="container">
        <div class="row">
          <div class="col-md-12">
            <br>
            <table class="table table-hover" >
              <thead>
              <tr>
                
                <th>Employee</th>
                <th>Company Name</th>
                <th>Task</th>
                <th>Edit</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let t of othertask let i = index"  >
       
                <td>{{t.emp_id}}</td>
                <td>{{t.firm_name}}</td>
                <td>{{t.task_name}}</td>
                <td><a (click)="updateothertask(t)"  >
                  <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a>  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </nb-card-body>
      </nb-card>
    </div>
</div>

<div class="row">
  <div class="col-md-12">
    <nb-card >
      <nb-card-header><h6>Self Task  ({{today}})</h6></nb-card-header>
      <nb-card-body>
    <div class="container">
        <div class="row">
         
          <div class="col-md-12">
            <br>
            <table class="table table-hover" >
              <thead>
              <tr>
                <th>Sr.No</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Case Name</th>
                <th>Employee</th>
                <th>Task Name</th>
                <th>Description</th>
                <th>Status</th>
                <th>Edit</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let t of selftask let i = index">
                <td>{{i + 1}}</td>
                  <td>{{t.start_date}}</td>
                  <td>{{t.end_date}}</td>
                  <td>{{t.case_name}}</td>
                  <td>{{t.emp_id}}</td>
                  <td>{{t.task_name}}</td>
                  <td>{{t.description}}</td>
                  <td>{{t.status}}</td>
                <td><a target="_blank" (click)="editselftask(t)"  >
                  <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</div>
</div>

<div class="row">
  <div class="col-md-12">
    <nb-card>
      <nb-card-header><h6>Pending Case Dates</h6></nb-card-header>
      <nb-card-body>
    <div class="container">
        <div class="row">
          <div class="col-md-12">
            <br>
            <table class="table table-hover" >
              <thead>
              <tr>
                <th>Prev. Date</th>
                <th>Judge Name</th>
                <th>Case No.</th>
                <th>Name of Parties</th>
                <th>Stage</th>
                <th>Next Date</th>
                <th>Edit</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let c of dueccdates let i = index"  >
                <td>{{c.prev_date}}</td>
                <td>{{c.court_name}}</td>
                <td>{{c.case_no}}</td>
                <td><b>{{c.client_name}}</b> |  {{c.user_id}} |  {{c.party_two}}</td>
                <td>{{c.stage}}</td>
                <td>{{c.next_date}}</td>
                <td><a target="_blank" (click)="addcasedate(c)">
                  <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </nb-card-body>
      </nb-card>
    </div>
  </div>

