<nb-card>
    <nb-card-header><h5>Firm's Task Report</h5></nb-card-header>
    <nb-card-body>
      <ul class="nav nav-pills">
        <li class="nav-item">
          <a class="nav-link " data-toggle="tab" href="#panel11" role="tab" (click)="task()" style="text-decoration:none;">Court Tasks</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#panel12" (click)="othertask()"  role="tab" style="text-decoration:none;">Other Tasks</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#panel13" (click)="selftask()"  role="tab" style="text-decoration:none;">Self Tasks</a>
        </li>
      </ul>
      <div class="tab-content pt-0">
        <div class="tab-pane fade in show active" id="panel12" role="tabpanel">
          <br>
      <form [formGroup]="firmtaskreportform" (ngSubmit)="getothertask(firmtaskreportform)"  autocomplete="off" role="form" >
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="from_date" class="label">From </label><br>
      
              <input nbInput id="from_date" name="from_date"  formControlName="from_date"
              placeholder="Form Picker" [nbDatepicker]="formpicker">
       <nb-datepicker #formpicker></nb-datepicker>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="to_date" class="label">To </label><br>
              <input nbInput id="to_date" name="to_date"  formControlName="to_date"
              placeholder="Form Picker" [nbDatepicker]="formpicker1">
       <nb-datepicker #formpicker1></nb-datepicker>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="emp_name1" class="label">Employee</label>
              <select  class="form-control" id="emp_name1" name="emp_name1" formControlName="emp_name1">
                <option [ngValue]="null" [disabled]="true">Select Employee</option>
                <option value="All">All</option>
                <option *ngFor="let r of employees1" [value]="r.emp_id">{{r.emp_name}}</option>
            </select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="status" class="label">Status</label>
              <select  class="form-control" id="status" name="status" formControlName="status">
                <option [ngValue]="null" [disabled]="true">Select Status</option>
                  <option value="All">All</option>
                  <option value="Open">Open</option>
                  <option value="Close">Close</option>
                
            </select>
            </div>
          </div>
          <div class="col-md-2">
            <label class="label"></label>
            <button type="submit" class="btn btn-primary" [disabled]="!firmtaskreportform.valid" style="margin-top:20px">Show</button>
            </div>
          
        </div>
        </form>
        <br><br>
<div class="container">
  <div class="row" *ngIf="list1">
    <div class="col-md-6">
      <div class="form-group">
        <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
    </div>
    </div>       
    <div class="col-md-12">
      <br>
      <table class="table table-hover" >
        <thead>
        <tr>
 
          <th>Sr.No</th>
          <th>Employee</th>
          <th>Task Date</th>
          <th>Firm Name</th>
          <th>Task Name</th>
          <th>Description</th>
          <th>Note</th>
          <th>Status</th>
          
          
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let t of othertasks | filter: searchText; let i = index"  >
 
          <td>{{i + 1}}</td>
          <td>{{t.emp_id}}</td>
          <td>{{t.task_date}}</td>
          <td>{{t.firm_name}}</td>
          <td>{{t.task_name}}</td>
          <td>{{t.description}}</td>
          <td>{{t.note}}</td>
          <td>{{t.status}}</td>
          </tr>
        </tbody>
      </table>
      <!--<div class="alert alert-info alert-dismissible" role="alert" >
        <i class="fa fa-info-circle"></i> 
        No Records found
      </div>-->
    </div>
  </div>
</div>
  </div>      
</div>

    </nb-card-body>
    </nb-card>
  

