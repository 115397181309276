import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Empexpense} from 'app/employee/empexpense';
import {credential} from './credential';
@Injectable({
  providedIn: 'root'
})
export class EmpexpenseService {
  redirectUrl: string;
  baseUrl=credential.login_url;
  
  constructor(private httpClient : HttpClient) { }
  getallexpenses(client_id:string,user_id:string) : Observable<Empexpense[] > {
    return this.httpClient.get<Empexpense[]>(this.baseUrl+'/loadempexpenses.php?client_id='+client_id+'&user_id='+user_id)
  }

  public addexpenses(user_id,expense_date,expense,amount,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/emp_expense_insert.php', {user_id,expense_date,expense,amount,client_id})
        .pipe(map(Task => {
            return Task;
        }));
  }

  public updateexpenses(user_id,expense_id,expense_date,expense,amount,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/emp_expense_update.php', {user_id,expense_id,expense_date,expense,amount,client_id})
      .pipe(map(Task => {
            return Task;
        }));
   
  }
  removeexpenses(expense_id: number,client_id:string,user_id:string): Observable<Empexpense[]> {
    return this.httpClient.delete<Empexpense[]>(this.baseUrl+'/emp_expense_delete.php?expense_id='+expense_id+'&client_id='+client_id+'&user_id='+user_id);
  }
  public getexpenseId(eid: number,client_id:string): Observable<Empexpense[]>
  {
    return this.httpClient.get<Empexpense[]>
    (this.baseUrl + '/getempexpenses.php?expense_id='+eid +'&client_id='+client_id);
  }
  
 
}
