import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Calls} from 'app/admin/calls';
import {credential} from './credential';

@Injectable({
  providedIn: 'root'
})
export class CallsService {
  redirectUrl: string;
  baseUrl=credential.login_url;
  constructor(private httpClient : HttpClient) { }
  getalltasks(id:number,client_id:string) : Observable<Calls[] > {
    return this.httpClient.get<Calls[]>(this.baseUrl+'/loadcalls.php?client_id='+client_id)
  }

  public addcalls(user_id,emp_name,case_name,task_name,call_date,note,status,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/calls_insert.php', {user_id,emp_name,case_name,task_name,call_date,note,status,client_id})
        .pipe(map(Calls => {
            return Calls;
        }));
  }

  public updatecalls(call_id,user_id,emp_name,case_name,task_name,call_date,note,status,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/calls_update.php', {call_id,user_id,emp_name,case_name,task_name,call_date,note,status,client_id})
      .pipe(map(Calls => {
            return Calls;
        }));
   
  }
  public editstatus(status,call_id,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/calls_status_update.php', {status,call_id,client_id })
      .pipe(map(Calls => {
            return Calls;
        }));
   
  }

   public getallemp(client_id:string): Observable<Calls[]>
  {
    return this.httpClient.get<Calls[]>
    (this.baseUrl + '/getallemployees.php?client_id='+client_id);
  }

  removecalls(call_id: number,client_id:string): Observable<Calls[]> {
    return this.httpClient.delete<Calls[]>(this.baseUrl+'/calls_delete.php?call_id='+call_id+'&client_id='+client_id);
  }
  public getcallId(call_id: number,client_id:string): Observable<Calls[]>
  {
    return this.httpClient.get<Calls[]>
    (this.baseUrl + '/getcalls.php?call_id='+call_id +'&client_id='+client_id);
  }
  public getemp(client_id:string): Observable<Calls[]>
  {
    return this.httpClient.get<Calls[]>
    (this.baseUrl + '/getempnames.php?client_id='+client_id);
  }
  public getcasenames(client_id:string): Observable<Calls[]>
  {
    return this.httpClient.get<Calls[]>
    (this.baseUrl + '/getcases.php?client_id='+client_id);
  }


  getallempcalls(id:number,client_id:string,user_id:string) : Observable<Calls[] > {
    return this.httpClient.get<Calls[]>(this.baseUrl+'/loadempcalls.php?client_id='+client_id+'&user_id='+user_id)
  }

  public addempcalls(user_id,case_name,task_name,call_date,note,status,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/calls_insertemp.php', {user_id,case_name,task_name,call_date,note,status,client_id})
        .pipe(map(Calls => {
            return Calls;
        }));
  }

  public updateempcalls(call_id,user_id,case_name,task_name,call_date,note,status,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/calls_updateemp.php', {call_id,user_id,case_name,task_name,call_date,note,status,client_id })
      .pipe(map(Calls => {
            return Calls;
        }));
   
  }
}
