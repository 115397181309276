<nb-card>
    <nb-card-header><h5>Receipts</h5></nb-card-header>
    <nb-card-body>
      <ul class="nav nav-pills">
        <li class="nav-item">
          <a class="nav-link " data-toggle="tab" href="#panel11" role="tab" (click)="receipt()" style="text-decoration:none;">Court Receipts</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#panel12" (click)="otherreceipt()"  role="tab" style="text-decoration:none;">Other Receipts</a>
        </li>
      </ul>
      <div class="tab-content pt-0">
        
      <!--Panel 1-->
      <div class="tab-pane fade " id="panel11" role="tabpanel">
        <br>
    
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
            </div>
            </div>   
            <div class="col-md-6">
              <button class="btn btn-primary"   (click)="addrec()" style="float:right"> Add</button>
            </div>    
            <div class="col-md-12">
              <!--<h2 class="text-center" >Clients</h2>-->
              
              <br><br>
              <table class="table table-hover"  >
                <thead>
                <tr>
         
                  <th>Sr.No</th>
                  <th>Receipt No.</th>
                  <th>Date</th>
                  <th>Case No.</th>
                  <th>Paid</th>
                  <th>Due</th>
                  <th>View</th>
                  <!--<th>Edit</th>-->
                  <th>Delete</th>
                  
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let r of receipts | filter: searchText; let i = index"  >
         
                  <td>{{i + 1}}</td>
                  <td>{{r.receipt_no}}</td>
                  <td>{{r.rdate}}</td>
                  <td>{{r.case_no}}</td>
                  <td>{{r.paid}}</td>
                  <td>{{r.due}}</td>
                  <td><a (click)="viewrec(r)" >
                    <i class="fa fa-eye" style="color: #00A0F0;font-size: 17px;margin-left:10px;" ></i></a>  </td>
                    <!--<td><a (click)="updaterec(r)"  >
                      <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a>  </td>-->
                      <td><a (click)="deleterec(r)" >
                        <i class="fa fa-trash" style="color:maroon;font-size: 17px;margin-left:10px;"></i></a>  </td>
                  </tr>
                </tbody>
              </table>
              <!--<div class="alert alert-info alert-dismissible" role="alert" >
                <i class="fa fa-info-circle"></i> 
                No Records found
              </div>-->
            </div>
          </div>
        </div>    </div>
      <!--/.Panel 1-->
    
      <!--Panel 2-->
      <div class="tab-pane fade in show active" id="panel12" role="tabpanel">
        <br>
    
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
            </div>
            </div>   
            <div class="col-md-6">
              <button class="btn btn-primary"   (click)="addotherrec()" style="float:right"> Add</button>
            </div>    
            <div class="col-md-12">
              <!--<h2 class="text-center" >Clients</h2>-->
              
              <br><br>
              <table class="table table-hover"  >
                <thead>
                <tr>
         
                  <th>Sr.No</th>
                  <th>Receipt No.</th>
                  <th>Date</th>
                  <th>Company Name</th>
                   <th>Work</th>
                  <th>Paid</th>
                  <th>Due</th>
                  <th>View</th>
                  <!--<th>Edit</th>-->
                  <th>Delete</th>
                  
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let r of otherreceipts | filter: searchText; let i = index"  >
         
                  <td>{{i + 1}}</td>
                  <td>{{r.receipt_no}}</td>
                  <td>{{r.rdate}}</td>
                  <td>{{r.firm_name}}</td>
                  <td>{{r.work}}</td>
                  <td>{{r.paid}}</td>
                  <td>{{r.due}}</td>
                  <td><a (click)="viewotherrec(r)" >
                    <i class="fa fa-eye" style="color: #00A0F0;font-size: 17px;margin-left:10px;" ></i></a>  </td>
                    <!--<td><a (click)="updaterec(r)"  >
                      <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a>  </td>-->
                      <td><a (click)="deleteotherrec(r)" >
                        <i class="fa fa-trash" style="color:maroon;font-size: 17px;margin-left:10px;"></i></a>  </td>
                  </tr>
                </tbody>
              </table>
              <!--<div class="alert alert-info alert-dismissible" role="alert" >
                <i class="fa fa-info-circle"></i> 
                No Records found
              </div>-->
            </div>
          </div>
        </div>   

    
      </div>
      <!--/.Panel 2-->

        </div>
        <!---->
  
    </nb-card-body>
    </nb-card>
  