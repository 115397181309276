import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})

export class SearchfilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;

searchText = searchText.toLowerCase();
/*return items.filter( it => {
      return it.toLowerCase().includes(searchText);
    });*/
    return items.filter(function(item){
      return JSON.stringify(item).toLowerCase().includes(searchText);
  });
   }
}