<nb-card>
    <nb-card-header><h5>Payment Due Report</h5></nb-card-header>
    <nb-card-body>
        <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link " data-toggle="tab" href="#panel11" role="tab" (click)="collection()" style="text-decoration:none;">Court Due</a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#panel12" (click)="firm()"  role="tab" style="text-decoration:none;">Other Due</a>
            </li>
            
          </ul>
          <div class="tab-content pt-0">
            <div class="tab-pane fade in show active" id="panel12" role="tabpanel">
              <br>
        <form [formGroup]="dueform" (ngSubmit)="getdue(dueform)"  autocomplete="off" role="form" >
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="from_date" class="label">From </label><br>
      
                  <input nbInput id="from_date" name="from_date"  formControlName="from_date"
                  placeholder="Form Picker" [nbDatepicker]="formpicker">
           <nb-datepicker #formpicker></nb-datepicker>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="to_date" class="label">To </label><br>
                  <input nbInput id="to_date" name="to_date"  formControlName="to_date"
                  placeholder="Form Picker" [nbDatepicker]="formpicker1">
           <nb-datepicker #formpicker1></nb-datepicker>                </div>
              </div>
              <div class="col-md-1">
                <button type="submit" class="btn btn-primary" [disabled]="!dueform.valid" style="margin-top:20px;">Show</button>
                </div>
              
            </div>
            </form>
            <br><br>
  <div class="container">
      <div class="row" *ngIf="list">
        <div class="col-md-6">
          <div class="form-group">
            <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
        </div>
        </div> 
        <div class="col-md-12">
          <!-- <p>{{total_due}}</p>-->
           <form [formGroup]="dueform2"   autocomplete="off" role="form" >
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="total_paid" class="label">Total Collection</label>
      
                  <input type="text" readonly  nbInput fullWidth id="total_paid" name="total_paid" formControlName="total_paid" autocomplete="off">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="total_due" class="label">Total Due</label>
      
                  <input type="text" readonly  nbInput fullWidth id="total_due" name="total_due" formControlName="total_due" autocomplete="off">
                </div>
              </div>
              </div>
           </form>
        </div>      
        <div class="col-md-12">
          <br>
          <table class="table table-hover" >
            <thead>
            <tr>
     
              <th>Sr.No</th>
              <th>Date</th>
              <th>Receipt No.</th>
              <th>Company Name</th>
              <th>Work</th>
              <th>Paid</th>
              <th>Due</th>
              
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let t of paymentdues | filter: searchText; let i = index"  >
     
              <td>{{i + 1}}</td>
              <td>{{t.rdate}}</td>
              <td>{{t.receipt_no}}</td>
              <td>{{t.firm_name}}</td>
              <td>{{t.work}}</td>
              <td>{{t.paid}}</td>
              <td>{{t.due}}</td>

              </tr>
            </tbody>
          </table>
          <!--<div class="alert alert-info alert-dismissible" role="alert" >
            <i class="fa fa-info-circle"></i> 
            No Records found
          </div>-->
        </div>
      </div>
    </div>
    </div>
    </div>
    
    </nb-card-body>
    </nb-card>
  

