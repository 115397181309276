<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="dashboard" (click)="navigateHome()">kaala<span>coat.com</span></a>
  </div>
  <!--<nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>-->
</div>

<div class="header-container">
  <nb-actions size="small">

   <!--<nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>-->
    <!--<nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>-->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user name="{{username}}"
      title=""
      [nbContextMenu]="items"
      nbContextMenuTag="my-context-menu">
      </nb-user>
      <!--<a href="" (click)="logout()" style="text-decoration:none;"><h6>Logout</h6></a>-->
      <!--/* echo $_SESSION['name']; ?>-->
    </nb-action>
  </nb-actions>
</div>