

<nb-card>
    <nb-card-header>Add Receipt</nb-card-header>
    <nb-card-body>
        <form [formGroup]="recform" (ngSubmit)="addreceipt(recform)"  autocomplete="off" role="form" >
          <div class="row">
        
            <div class="col-md-6">
              <div class="form-group">
                <label for="client_name" class="label">Client Name*</label>
                <select id="client_name" class="form-control" #client_name name="client_name" (change)="showcase(client_name.value)"  formControlName="client_name"  >
                  <option [ngValue]="null" [disabled]="true" >Choose Client </option>
                  <option *ngFor="let r of clientNames" [value]="r.client_name">{{r.client_name}}</option>
              </select>
              <div *ngIf="f.client_name.touched && f.client_name.invalid" class="alert alert-danger">
                <div *ngIf="f.client_name.errors.required">Client Name is required.</div>
            </div> 
              </div>
            </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="case_name" class="label">Case Name*</label>
              <select id="case_name" class="form-control" #case_name name="case_name"  formControlName="case_name" (change)="showcaseno(case_name.value,client_name.value)"  >
                <option [ngValue]="null" [disabled]="true">Choose Case </option>
                <option *ngFor="let r of caseNames" [value]="r.case_name">{{r.case_name}}</option>
            </select>
            <div *ngIf="f.case_name.touched && f.case_name.invalid" class="alert alert-danger">
              <div *ngIf="f.case_name.errors.required">Case Name is required.</div>
          </div>  
            </div>
          </div>
        </div>
      <br>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="case_no" class="label">Case No</label>
            <input type="text"  readonly nbInput fullWidth id="case_no" name="case_no" formControlName="case_no" autocomplete="off" placeholder="Case No.">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="rdate" class="label">Date*</label><br>

            <input nbInput id="rdate" name="rdate"  formControlName="rdate"
            placeholder="Date Picker" [nbDatepicker]="formpicker">
     <nb-datepicker #formpicker></nb-datepicker>
            <div *ngIf="f.rdate.touched && f.rdate.invalid" class="alert alert-danger">
              <div *ngIf="f.rdate.errors.required">Date is required.</div>
          </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="installment" class="label">Installment*</label>
              <select name="installment" formControlName="installment" class="form-control" >
                <option [ngValue]="null" [disabled]="true">Select Installment</option>
                <option value="First Installment">First Installment</option>
                <option value="Second Installment">Second Installment</option>
                <option value="Third Installment">Third Installment</option>
                <option value="Fourth Installment">Fourth Installment</option>
                <option value="Fifth Installment">Fifth Installment</option>
              </select>
              <div *ngIf="f.installment.touched && f.installment.invalid" class="alert alert-danger">
                <div *ngIf="f.installment.errors.required">Installment is required.</div>
            </div>

              
      </div>
  </div>
      
      </div>
<br>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                  <label for="agreed_amount" class="label">Agreed Amount*</label>
                  <input type="text" readonly  nbInput fullWidth id="agreed_amount" #agreed_amount placeholder="Agreed Amount" name="agreed_amount" formControlName="agreed_amount" autocomplete="off">
                </div>
              </div>
              
            <div class="col-md-6">
              <div class="form-group">
                <label for="paid" class="label">Paid*</label>
  <input type="text"  nbInput fullWidth id="paid" #paid name="paid" formControlName="paid" autocomplete="off" (change)="showdue(paid.value,agreed_amount.value,balance.value)" placeholder="Paid Amount">
  <div *ngIf="f.paid.touched && f.paid.invalid" class="alert alert-danger">
    <div *ngIf="f.paid.errors.required">Paid amount is required.</div>
    <div *ngIf="f.paid.errors.pattern">Enter only numbers.</div>
</div>    
</div>
            </div>    
      </div>
      
      <br>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="due" class="label">Due*</label>
              <input type="text" readonly  nbInput fullWidth id="due" placeholder="Due" name="due" formControlName="due" autocomplete="off">
            </div>
          </div>
          
        <div class="col-md-6">
          <div class="form-group">
            <label for="balance" class="label">Total Received*</label>

            <input type="text" readonly nbInput fullWidth id="balance" name="balance" formControlName="balance" #balance autocomplete="off" placeholder="Total Received">
          
          </div>
        </div>    
  </div>
      <div class="row">
        
            <div class="col-lg-6">
              <button type="button"  (click)="back()" class="btn btn-success" style="margin:10px">Back</button>
          
              <button type="submit" class="btn btn-primary" [disabled]="!recform.valid">Add</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  





