<nb-card>
    <nb-card-header>Add Court Case</nb-card-header>
    <nb-card-body>
        <form [formGroup]="caseform" (ngSubmit)="createcase(caseform)"  autocomplete="off" role="form" >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="case_no" class="label">Case No</label>

            <input type="text"  nbInput fullWidth id="case_no" name="case_no" formControlName="case_no" autocomplete="off" placeholder="Case No.">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="case_date" class="label">Date*</label><br>
            <input nbInput id="case_date" name="case_date"  formControlName="case_date"
            placeholder="Date Picker" [nbDatepicker]="formpicker">
     <nb-datepicker #formpicker></nb-datepicker>
            <div *ngIf="f.case_date.touched && f.case_date.invalid" class="alert alert-danger">
              <div *ngIf="f.case_date.errors.required">Case Date is required.</div>
          </div>
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="court_name" class="label">Court Name</label>
            <input type="text"  nbInput fullWidth id="court_name" name="court_name" formControlName="court_name" autocomplete="off" placeholder="Court Name">
           <!-- <div *ngIf="f.court_name.touched && f.court_name.invalid" class="alert alert-danger">
              <div *ngIf="f.court_name.errors.required">Court Name is required.</div>
          </div>-->
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label for="judge_name" class="label">Judge Name</label>
              <input type="text" nbInput fullWidth id="judge_name" name="judge_name" formControlName="judge_name" autocomplete="off" placeholder="Judge Name">
            </div>
          </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="case_name" class="label">Case Name*</label>
            <input type="text"  nbInput fullWidth id="case_name" name="case_name" formControlName="case_name" autocomplete="off" placeholder="Case Name">
            <div *ngIf="f.case_name.touched && f.case_name.invalid" class="alert alert-danger">
              <div *ngIf="f.case_name.errors.required">Case Name is required.</div>
          </div>
          </div>
        </div>
      </div>
<br>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="client_name" class="label">Client Name*</label>
            <input type="text"  nbInput fullWidth id="client_name" name="client_name" formControlName="client_name" autocomplete="off" placeholder="Client Name">
            <div *ngIf="f.client_name.touched && f.client_name.invalid" class="alert alert-danger">
              <div *ngIf="f.client_name.errors.required">Client Name is required.</div>
          </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="party_one" class="label">Plainitiff/Appellant/Petitioner/Applicant/Complainant*</label>
            <input type="text"  nbInput fullWidth id="party_one" name="party_one" formControlName="party_one" autocomplete="off" placeholder="Plainitiff/Appellant/Petitioner/Applicant/Complainant">
            <div *ngIf="f.party_one.touched && f.party_one.invalid" class="alert alert-danger">
              <div *ngIf="f.party_one.errors.required">Field is required.</div>
          </div>
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="party_two" class="label">Defendant/Respondent/Opponent/Accused*</label>

            <input type="text"  nbInput fullWidth id="party_two" name="party_two" formControlName="party_two" autocomplete="off" placeholder="Defendant/Respondent/Opponent/Accused">
            <div *ngIf="f.party_two.touched && f.party_two.invalid" class="alert alert-danger">
              <div *ngIf="f.party_two.errors.required">Field is required.</div>
          </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="stage" class="label">Stage*</label>
            <input type="text"  nbInput fullWidth id="stage" name="stage" formControlName="stage" autocomplete="off" placeholder="Stage">
            <div *ngIf="f.stage.touched && f.stage.invalid" class="alert alert-danger">
              <div *ngIf="f.stage.errors.required">Stage is required.</div>
          </div>
          </div>
        </div>
      </div><br>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="file_no" class="label">File No.*</label>

            <input type="text"  nbInput fullWidth id="file_no" name="file_no" formControlName="file_no" autocomplete="off" placeholder="File No">
            <div *ngIf="f.file_no.touched && f.file_no.invalid" class="alert alert-danger">
              <div *ngIf="f.file_no.errors.required">File No. is required.</div>
          </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="agreed_amount" class="label">Agreed Total Amount*</label>
            <input type="text"  nbInput fullWidth id="agreed_amount" name="agreed_amount" formControlName="agreed_amount" autocomplete="off" placeholder="Agreed Amount">
            <div *ngIf="f.agreed_amount.touched && f.agreed_amount.invalid" class="alert alert-danger">
              <div *ngIf="f.agreed_amount.errors.required">Amount is required.</div>
              <div *ngIf="f.agreed_amount.errors.pattern">Enter only numbers.</div>
          </div>
          </div>
        </div>
      </div><br>
      
      
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="Owner_role" class="label">Role*</label>
                <select name="owner_role" formControlName="owner_role" class="form-control" >
                  <option [ngValue]="null" [disabled]="true">Select Role</option>
                  <option value="Plainitiff">Plainitiff</option>
                  <option value="Appellant">Appellant</option>
                  <option value="Petitioner">Petitioner</option>
                  <option value="Applicant">Applicant</option>
                  <option value="Complainant">Complainant</option>
                  <option value="Defendant">Defendant</option>
                  <option value="Respondent">Respondent</option>
                  <option value="Opponent">Opponent</option>
                  <option value="Accused">Accused</option>
                  <option value="Third Party">Third Party</option>
                  <option value="Certificate Holder">Certificate Holder</option>
                  <option value="Certificate Debtor">Certificate Debtor</option>
                  <option value="Decree Holder">Decree Holder</option>
                  <option value="Judgment Holder">Judgment Holder</option>

                </select>
                <div *ngIf="f.owner_role.touched && f.owner_role.invalid" class="alert alert-danger">
                  <div *ngIf="f.owner_role.errors.required">Role is required.</div>
              </div>

                
        </div>
    </div>
        
        </div>
      <br>
      <div class="row">
        
            <div class="col-lg-6">
              <button type="button" class="btn btn-success"  (click)="back()" style="margin:10px">Back</button>
         
              <button type="submit" class="btn btn-primary" [disabled]="!caseform.valid">Add</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  





