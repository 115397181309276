export class Task {
    public task_id: number;
    public othertask_id: number;
    public client_id:number;
    public user_id:number;
    public emp_id:string;
    public case_name:string;
    public firm_name:string;
    public task_date: string;
    public task_name:string;
    public description:string;
    public note:string;
    public status:string;
    public start_date:string;
    public end_date:string;
    public client_name:string;
   
    constructor(task_id:number,othertask_id:number,client_id:number,user_id:number,emp_id:string,case_name:string,firm_name:string,task_date:string,task_name:string,description:string,note:string,status:string,start_date:string,end_date:string,client_name:string) {
      this.task_id=task_id,
      this.othertask_id=othertask_id,
      this.client_id=client_id;
      this.user_id=user_id;
      this.emp_id=emp_id;
      this.case_name=case_name;
      this.firm_name=firm_name;
      this.task_date = task_date;
      this.task_name = task_name;
      this.description = description;
      this.note=note;
      this.status=status;
      this.start_date=start_date;
      this.end_date=end_date;
      this.client_name=client_name;
    
    }
}