import { Component, OnInit } from '@angular/core';
import {ExpenseService} from 'app/services/expense.service';
import { Expense } from 'app/admin/expense';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss']
})
export class ExpenseComponent implements OnInit {
  expenses: Expense[];
  cat:number;
  public searchText:string;
  constructor(private dataService: ExpenseService ,private router:Router) { }

  ngOnInit(): void {
    this.gettaskdetails();
  }
  gettaskdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getallexpenses(this.cat,client_id).subscribe(response =>
      {
        this.expenses = response.map(item =>
        {
          return new Expense(
            item.expense_id,
            item.client_id,
            item.user_id,
            item.expense_date,
             item.expense,
              item.amount,
              item.total_amount
          );
        });
      });

    }
  }
  
  addexp(): void {
    this.router.navigate(['admin/addexpense']);
  };
  back(): void {
    this.router.navigate(['admin/expense']);
  };
  
  viewexp(c: Expense): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.expense_id.toString());
    this.router.navigate(['admin/viewexpense']);
  };
  deleteexp(c:Expense)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removeexpenses(c.expense_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.gettaskdetails();
    })
  }
  }
  updateexp(c: Expense): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.expense_id.toString());
    this.router.navigate(['admin/editexpense']);
  };
  
  }