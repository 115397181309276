

<nb-card>
    <nb-card-header>Add Task</nb-card-header>
    <nb-card-body>
        
      <form [formGroup]="addtaskform" (ngSubmit)="createtask(addtaskform)"  autocomplete="off" role="form" >
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="selecttype" class="label">Select Type</label><br>
  <input id="casetype" type="radio"  value="casetype" name="selecttype" #casetype  (change)="showcase(casetype.value)"   formControlName="selecttype" style=" margin: 0 10px 0 10px;">
    <label for="casetype">Court Case</label>
      <input id="other" type="radio"  value="other"  name="selecttype" #other (change)="showcase(other.value)" formControlName="selecttype" style=" margin: 0 10px 0 10px;">
        <label for="other">Other</label>     
               
            </div>
          </div>
          </div>
        <div class="row" *ngIf="case">
        <div class="col-md-6">
          <div class="form-group">
            <label for="case_name" class="label">Case Name</label>
            <select  class="form-control" id="case_name" name="case_name" formControlName="case_name">
                <option [ngValue]="null" [disabled]="true">Select Case</option>
                <option *ngFor="let r of caseNames" [value]="r.case_name">{{r.case_name}}</option>
            </select>
            <div *ngIf="f.case_name.touched && f.case_name.invalid" class="alert alert-danger">
              <div *ngIf="f.case_name.errors.required">Case Name is required.</div>
             
          </div>
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="start_date" class="label">Start Date </label><br>
              <input nbInput id="start_date" name="start_date"  formControlName="start_date"
              placeholder="Form Picker" [nbDatepicker]="formpicker1">
       <nb-datepicker #formpicker1></nb-datepicker>      
              <div *ngIf="f.start_date.touched && f.start_date.invalid" class="alert alert-danger">
                <div *ngIf="f.start_date.errors.required">Start Date is required.</div>
               
            </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="end_date" class="label">End Date </label><br>
              <input nbInput id="end_date" name="end_date"  formControlName="end_date"
              placeholder="Form Picker" [nbDatepicker]="formpicker">
       <nb-datepicker #formpicker></nb-datepicker>      
              
            </div>
          </div>
        
        
      </div>
<br>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="task_name" class="label">Task Name</label>
              <input type="text"  nbInput fullWidth id="task_name" placeholder="Task Name" name="task_name" formControlName="task_name" autocomplete="off">
              <div *ngIf="f.task_name.touched && f.task_name.invalid" class="alert alert-danger">
                <div *ngIf="f.task_name.errors.required">Task Name is required.</div>
               
            </div>
            </div>
          </div>
        <div class="col-lg-6">
            <div class="form-group">
              <label for="description" class="label">Description</label>
              <textarea  nbInput fullWidth id="description" placeholder="Description" name="description" formControlName="description" autocomplete="off"></textarea>
              
            </div>
            </div>
        </div>
      
<div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="status" class="label">Status</label>
              <select name="status" formControlName="status" class="form-control" >
                <option value="Open" >Open</option>
                <option value="Close">Close</option>
              </select>
              <div *ngIf="f.status.touched && f.status.invalid" class="alert alert-danger">
                <div *ngIf="f.status.errors.required">Status is required.</div>
              
      </div>
  </div>
</div>
        
        
        </div>
      <br>
      <div class="row">
       
            <div class="col-lg-6">
              <button type="button" class="btn btn-success"  (click)="back()" style="margin:10px">Back</button>
           
              <button type="submit" class="btn btn-primary" [disabled]="!addtaskform.valid">Add</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  




