import { Component, OnInit } from '@angular/core';
import {CourtService} from 'app/services/court.service';
import { Court } from 'app/admin/court';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-court',
  templateUrl: './court.component.html',
  styleUrls: ['./court.component.scss']
})
export class CourtComponent implements OnInit {
  courts: Court[];
  cat:number;
  public searchText:string;
  constructor(private dataService: CourtService ,private router:Router) { }

  ngOnInit(): void {
    this.getcourtdetails();
  }
  getcourtdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getAllCourts(this.cat,client_id).subscribe(response =>
      {
        this.courts = response.map(item =>
        {
          return new Court(
            item.court_id,
            item.client_id,
            item.court_name,
            item.judge_name,
              item.court_type,
              item.city
          );
        });
      });

    }
  }
  
  addcourt(): void {
    this.router.navigate(['admin/addcourt']);
  };
  back(): void {
    this.router.navigate(['admin/court']);
  };
  
  viewcourt(c: Court): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.court_id.toString());
    this.router.navigate(['admin/viewcourt']);
  };
  deletecourt(c:Court)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removecourt(c.court_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getcourtdetails();
    })
  }
  }
  updatecourt(c: Court): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.court_id.toString());
    this.router.navigate(['admin/editcourt']);
  };
  
  }