import { Component, OnInit } from '@angular/core';
import { MENU_ITEMS } from './emp-menu';

@Component({
  selector: 'ngx-emp',
  //templateUrl: './emp.component.html',
  styleUrls: ['./emp.component.scss'],
  template: `
  <ngx-one-column-layout>
    <nb-menu [items]="menu"></nb-menu>
    <router-outlet></router-outlet>
  </ngx-one-column-layout>
`,
})
export class EmpComponent  {

  menu = MENU_ITEMS;

}
