import { Component, OnInit } from '@angular/core';
import {MeetingsService} from 'app/services/meetings.service';
import { Meetings } from 'app/admin/meetings';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-addmeetings',
  templateUrl: './addmeetings.component.html',
  styleUrls: ['./addmeetings.component.scss']
})
export class AddmeetingsComponent implements OnInit {
  addmeetingform: FormGroup;
  
  constructor(private fb: FormBuilder,private dataService: MeetingsService,private router:Router) {
  
   }
  ngOnInit() {
    this.addmeetingform = new FormGroup({
      topic: new FormControl(),
      discussion: new FormControl(),
      scheduled_date: new FormControl(),
      scheduled_time: new FormControl(),
      client_name: new FormControl()
      
   });
    this.addmeetingform = this.fb.group({
      topic: ['', Validators.required],
      discussion: [],
      scheduled_date: ['', Validators.required],
      scheduled_time: ['', Validators.required],
      client_name: ['', Validators.required],
    });
  }
  back(): void {
    this.router.navigate(['admin/meetings']);
  };

  get f(){
    return this.addmeetingform.controls;
  }

  addmeeting(addmeetingform)
  {
    let formdate=addmeetingform.value.scheduled_date;
    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let todaysdate= year + "-" + month + "-" + date;

    var as=new Date(addmeetingform.value.scheduled_date);
    let d = ("0" + as.getDate()).slice(-2);
    let m = ("0" + (as.getMonth() + 1)).slice(-2);
    let y = as.getFullYear();
    let c_date= y + "-" + m + "-" + d;
  
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
     /* if(c_date >= todaysdate)
      {*/
        this.dataService.addMeetings(user_id,addmeetingform.value.topic,addmeetingform.value.discussion,c_date,addmeetingform.value.scheduled_time,addmeetingform.value.client_name,client_id)
        .pipe()
        .subscribe(
            data => {
                this.router.navigate(['admin/meetings']);
            },
            error => {
            });

      /*}
      else
      {
        alert("Select date greater than current date");
      }*/
   
        }
        
    }
  
  

  get topic() { return this.addmeetingform.get('firm_name'); }
  get discussion() { return this.addmeetingform.get('discussion'); }
  get scheduled_date  () { return this.addmeetingform.get('scheduled_date'); }
  get scheduled_time() { return this.addmeetingform.get('scheduled_time'); }
  get client_name() { return this.addmeetingform.get('client_name'); }

}




