import { Component, OnInit } from '@angular/core';
import {SelftaskService} from 'app/services/selftask.service';
import {Selftask} from 'app/employee/selftask';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-editselftask',
  templateUrl: './editselftask.component.html',
  styleUrls: ['./editselftask.component.scss']
})
export class EditselftaskComponent implements OnInit {
  editstform: FormGroup;
  employees:any= [];
  caseNames:any= [];
  case:boolean=false;

  constructor(private fb: FormBuilder,private dataService: SelftaskService,private router:Router) { }

  ngOnInit(){
    this.editstform = new FormGroup({
      selftask_id: new FormControl(),
      task_name: new FormControl(),
      start_date: new FormControl(),
      end_date: new FormControl(),
      description: new FormControl(),
      case_name: new FormControl(),
      status:new FormControl()
   });
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
            this.dataService.getcasenames(client_id)
            .subscribe((data: any) => {
              this.caseNames=data;
             
          });

          let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['employee/selftask']);
      return;
    }
    this.dataService.getselftaskId(+Id,client_id)
      .subscribe( data => {
       //this.viewform.controls[this.client_mobile].setValue('client_name')
        //this.client_email.nativeElement.value = 'This is new value';
        if(data[0].case_name==null || data[0].case_name=="")
        {
          this.case=false;
          this.editstform.patchValue({
            selftask_id:data[0].selftask_id,task_name: data[0].task_name, start_date: data[0].start_date,end_date: data[0].end_date,description: data[0].description,status: data[0].status
   
         });

        }
        else
        {
          this.case=true;
          this.editstform.patchValue({
            selftask_id:data[0].selftask_id,case_name: data[0].case_name,task_name: data[0].task_name, start_date: data[0].start_date,end_date: data[0].end_date,description: data[0].description,status: data[0].status
   
   
         });

        }
       
      });
          }
  }

  editselftask(editstform)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(editstform.value.start_date);
      let d = ("0" + as.getDate()).slice(-2);
      let m = ("0" + (as.getMonth() + 1)).slice(-2);
      let y = as.getFullYear();
      let start_date= y + "-" + m + "-" + d;

      var as1=new Date(editstform.value.end_date);
      let d1 = ("0" + as1.getDate()).slice(-2);
      let m1 = ("0" + (as1.getMonth() + 1)).slice(-2);
      let y1 = as1.getFullYear();
      let end_date= y1 + "-" + m1+ "-" + d1;
      
    
    this.dataService.updateselftasks(editstform.value.selftask_id,user_id,editstform.value.case_name,start_date,end_date,editstform.value.task_name,editstform.value.description,editstform.value.status,client_id)
 
    .pipe()
    .subscribe(
        data => {
            this.router.navigate(['employee/selftask']);
        },
        error => {
        });
      }
  }

          back(): void {
            this.router.navigate(['employee/selftask']);
          };
        

        
          get case_name() { return this.editstform.get('case_name'); }
          get start_date() { return this.editstform.get('start_date'); }
          get task_name() { return this.editstform.get('task_name'); }
          get end_date() { return this.editstform.get('end_date'); }
          get description() { return this.editstform.get('description'); }
        
        }
        