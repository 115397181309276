import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Expense} from 'app/admin/expense';
import {Empexpense} from 'app/employee/empexpense';
import {Task} from 'app/admin/task';
import {Receipt} from 'app/admin/receipt';
import {Casedate} from 'app/admin/casedate';
import {Selftask} from 'app/employee/selftask';
import {credential} from './credential';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  redirectUrl: string;
  baseUrl=credential.login_url;
  constructor(private httpClient : HttpClient) { }
  expensereport(user_id:string,from_date:string,to_date:string,client_id:string) : Observable<Expense[] > {
    return this.httpClient.get<Expense[]>(this.baseUrl+'/loadexpensereport.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date)
  }

  public getemp(client_id:string): Observable<Task[]>
  {
    return this.httpClient.get<Task[]>
    (this.baseUrl + '/getempnames.php?client_id='+client_id);
  }

  selftaskreport(user_id:string,from_date:string,to_date:string,emp_name:string,status:string,client_id:string) : Observable<Selftask[] > {
    return this.httpClient.get<Selftask[]>(this.baseUrl+'/loadselftaskreport.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date+'&emp_name='+emp_name+'&status='+status)
  }

  taskreport(user_id:string,from_date:string,to_date:string,emp_name:string,status:string,client_id:string) : Observable<Task[] > {
    return this.httpClient.get<Task[]>(this.baseUrl+'/loadtaskreport.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date+'&emp_name='+emp_name+'&status='+status)
  }

  duepayment(user_id:string,from_date:string,to_date:string,client_id:string) : Observable<Receipt[] > {
    return this.httpClient.get<Receipt[]>(this.baseUrl+'/loadduepaymentreport.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date)
  }

  firmduepayment(user_id:string,from_date:string,to_date:string,client_id:string) : Observable<Receipt[] > {
    return this.httpClient.get<Receipt[]>(this.baseUrl+'/loadfirmduepaymentreport.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date)
  }


  paymentcollection(user_id:string,from_date:string,to_date:string,client_id:string) : Observable<Receipt[] > {
    return this.httpClient.get<Receipt[]>(this.baseUrl+'/loadtotalcollectionreport.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date)
  }

  emptaskreport(user_id:string,from_date:string,to_date:string,client_id:string) : Observable<Task[] > {
    return this.httpClient.get<Task[]>(this.baseUrl+'/loademptaskreport.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date)
  }

  empfirmtaskreport(user_id:string,from_date:string,to_date:string,client_id:string) : Observable<Task[] > {
    return this.httpClient.get<Task[]>(this.baseUrl+'/loadempfirmtaskreport.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date)
  }

  empselftaskreport(user_id:string,from_date:string,to_date:string,client_id:string) : Observable<Selftask[] > {
    return this.httpClient.get<Selftask[]>(this.baseUrl+'/loadempselftaskreport.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date)
  }

  ccdates(user_id:string,from_date:string,to_date:string,client_id:string) : Observable<Casedate[] > {
    return this.httpClient.get<Casedate[]>(this.baseUrl+'/loadccdatesreport2.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date)
  }

  allccdates(user_id:string,client_id:string) : Observable<Casedate[] > {
    return this.httpClient.get<Casedate[]>(this.baseUrl+'/loadccdatesreport1.php?client_id='+client_id+'&user_id='+user_id)
  }

  calendar(user_id:string,from_date:string,to_date:string,client_id:string) : Observable<Casedate[] > {
    return this.httpClient.get<Casedate[]>(this.baseUrl+'/loadccdatesreport2.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date)
  }

  allcalendar(user_id:string,client_id:string) : Observable<Casedate[] > {
    return this.httpClient.get<Casedate[]>(this.baseUrl+'/loadallcalendar.php?client_id='+client_id+'&user_id='+user_id)
  }


  firmtask(user_id:string,from_date:string,to_date:string,emp_name:string,status:string,client_id:string) : Observable<Task[] > {
    return this.httpClient.get<Task[]>(this.baseUrl+'/loadfirmtasksreport.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date+'&emp_name='+emp_name+'&status='+status)
  }

  firmpayment(user_id:string,from_date:string,to_date:string,client_id:string) : Observable<Receipt[] > {
    return this.httpClient.get<Receipt[]>(this.baseUrl+'/loadfirmpaymentreport.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date)
  }

  empexpenses(user_id:string,from_date:string,to_date:string,client_id:string,emp_id:string) : Observable<Empexpense[] > {
    return this.httpClient.get<Empexpense[]>(this.baseUrl+'/loadempexpensereport.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date+'&emp_id='+emp_id)
  }

  empexpensesreport(user_id:string,from_date:string,to_date:string,client_id:string) : Observable<Empexpense[] > {
    return this.httpClient.get<Empexpense[]>(this.baseUrl+'/loademployeeexpensereport.php?client_id='+client_id+'&user_id='+user_id+'&from_date='+from_date+'&to_date='+to_date)
  }

  public getallemp(client_id:string): Observable<Task[]>
  {
    return this.httpClient.get<Task[]>
    (this.baseUrl + '/getallemployees.php?client_id='+client_id);
  }


}
 