import { NbMenuItem } from '@nebular/theme';
//import { NbMenuModule } from '@nebular/theme';
export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home-outline',
    link: '/employee/dashboard',
    home: true,
  },
  {
    title: 'Task',
    icon: 'checkmark-square-outline',
    link: '/employee/task',
    
  },
  {
    title: 'Self Task',
    icon: 'person-done-outline',
    link: '/employee/selftask', 
  },

  {
    title: 'Expenses',
    icon: 'activity-outline',
    link: '/employee/expenses', 
    
  },

  {
    title: 'Calls',
    icon: 'phone-outline',
    link: '/employee/calls',
    
  },
  {
    title: 'Reports',
    icon: 'file-text-outline',
    children: [
      {
        title: 'Datewise Case Date Report',
        link: '/employee/casedatereport'
        
      },
      {
    title: 'Datewise Task Report',

    link: '/employee/taskreport'
    
  },
  {
    title: 'Datewise Company Task Report',
    link: '/employee/firmtaskreport'
    
  },
  
     {
    title: 'Datewise Self Task Report',
    link: '/employee/selftaskreport' 
  },
  {
    title: 'Datewise Expense Report',
    link: '/employee/expensereport' 
  },
 
 
 ],
  },
  
  ];
