import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Meetings} from 'app/admin/meetings';
import {credential} from './credential';
@Injectable({
  providedIn: 'root'
})
export class MeetingsService {

  redirectUrl: string;
  baseUrl=credential.login_url;
  constructor(private httpClient : HttpClient) { }
  getallMeetings(id:number,client_id:string) : Observable<Meetings[] > {
    return this.httpClient.get<Meetings[]>(this.baseUrl+'/loadmeetings.php?client_id='+client_id)
  }

  public addMeetings(user_id,topic,discussion,scheduled_date,scheduled_time,client_name,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/meetings_insert.php', {user_id,topic,discussion,scheduled_date,scheduled_time,client_name,client_id})
        .pipe(map(Meetings => {
            return Meetings;
        }));
  }

  public updateMeetings(meeting_id,user_id,topic,discussion,scheduled_date,scheduled_time,client_name,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/meetings_update.php', {meeting_id,user_id,topic,discussion,scheduled_date,scheduled_time,client_name,client_id })
      .pipe(map(Meetings => {
            return Meetings;
        }));
   
  }
  removeMeetings(meeting_id: number,client_id:string): Observable<Meetings[]> {
    return this.httpClient.delete<Meetings[]>(this.baseUrl+'/meetings_delete.php?meeting_id='+meeting_id+'&client_id='+client_id);
  }
  public getmeetingsId(meeting_id: number,client_id:string): Observable<Meetings[]>
  {
    return this.httpClient.get<Meetings[]>(
      this.baseUrl + '/getmeetings.php?meeting_id=' + meeting_id +'&client_id='+client_id
      );
  }

  

}

