import { Component, OnInit } from '@angular/core';
import { Empexpense } from 'app/employee/empexpense';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { EmpexpenseService } from 'app/services/empexpense.service';
@Component({
  selector: 'ngx-addempexpense',
  templateUrl: './addempexpense.component.html',
  styleUrls: ['./addempexpense.component.scss']
})
export class AddempexpenseComponent implements OnInit {
  expform: FormGroup;
  roles:any= [];
  
  constructor(private fb: FormBuilder,private dataService: EmpexpenseService,private router:Router) {
    

    
    
   }

  ngOnInit() { 
    this.expform = new FormGroup({
      expense_date: new FormControl(),
      expense: new FormControl(),
      amount: new FormControl(),
      
   });
   this.expform = this.fb.group({
    expense_date: ['', Validators.required],
    expense: ['', [Validators.required]],
    amount: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
    
   
  });
    
  }
  get f(){
    return this.expform.controls;
  }


  back(): void {
    this.router.navigate(['employee/expenses']);
  };

  addexpense(expform)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(expform.value.expense_date);
    let d = ("0" + as.getDate()).slice(-2);
    let m = ("0" + (as.getMonth() + 1)).slice(-2);
    let y = as.getFullYear();
    let c_date= y + "-" + m + "-" + d;
      

   this.dataService.addexpenses(user_id,c_date,expform.value.expense,expform.value.amount,client_id)
      .pipe()
      .subscribe(
          data => {
              this.router.navigate(['employee/expenses']);
          },
          error => {
          });
              
            } 
              
          }
         
  
  get expense_date() { return this.expform.get('expense_date'); }
  get expense() { return this.expform.get('expense'); }
  get amount() { return this.expform.get('amount'); }

 

}
