import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmployeeComponent } from './employee/employee.component';
import { CreateemployeeComponent } from './createemployee/createemployee.component';
import { EditemployeeComponent } from './editemployee/editemployee.component';
import { ViewemployeeComponent } from './viewemployee/viewemployee.component';
import { CourtComponent } from './court/court.component';
import { AddcourtComponent } from './addcourt/addcourt.component';
import { EditcourtComponent } from './editcourt/editcourt.component';
import { ViewcourtComponent } from './viewcourt/viewcourt.component';
import { CourtcaseComponent } from './courtcase/courtcase.component';
import { AddcaseComponent } from './addcase/addcase.component';
import { EditcaseComponent } from './editcase/editcase.component';
import { ViewcaseComponent } from './viewcase/viewcase.component';
import { CasedateComponent } from './casedate/casedate.component';
import { AddcasedateComponent } from './addcasedate/addcasedate.component';
import { EditcasedateComponent } from './editcasedate/editcasedate.component';
import { ViewcasedateComponent } from './viewcasedate/viewcasedate.component';
import { TaskComponent } from './task/task.component';
import { CreatetaskComponent } from './createtask/createtask.component';
import { EdittaskComponent } from './edittask/edittask.component';
import { ViewtaskComponent } from './viewtask/viewtask.component';
import { ExpenseComponent } from './expense/expense.component';
import { AddexpenseComponent } from './addexpense/addexpense.component';
import { EditexpenseComponent } from './editexpense/editexpense.component';
import { ViewexpenseComponent } from './viewexpense/viewexpense.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { AddreceiptComponent } from './addreceipt/addreceipt.component';
import { EditreceiptComponent } from './editreceipt/editreceipt.component';
import { ViewreceiptComponent } from './viewreceipt/viewreceipt.component';
import { TaskreportComponent } from './taskreport/taskreport.component';
import { SelftaskreportComponent } from './selftaskreport/selftaskreport.component';
import { ExpensereportComponent } from './expensereport/expensereport.component';
import { DuepaymentreportComponent } from './duepaymentreport/duepaymentreport.component';
import { DatewisecollectionreportComponent } from './datewisecollectionreport/datewisecollectionreport.component';
import { FirmComponent } from './firm/firm.component';
import { AddfirmComponent } from './addfirm/addfirm.component';
import { EditfirmComponent } from './editfirm/editfirm.component';
import { ViewfirmComponent } from './viewfirm/viewfirm.component';
import { MeetingsComponent } from './meetings/meetings.component';
import { AddmeetingsComponent } from './addmeetings/addmeetings.component';
import { EditmeetingsComponent } from './editmeetings/editmeetings.component';
import { ViewmeetingsComponent } from './viewmeetings/viewmeetings.component';
import { ViewcallsComponent } from './viewcalls/viewcalls.component';
import { EditcallsComponent } from './editcalls/editcalls.component';
import { AddcallsComponent } from './addcalls/addcalls.component';
import { CallsComponent } from './calls/calls.component';
import { CasedatereportComponent } from './casedatereport/casedatereport.component';
import { OthertasksComponent } from './othertasks/othertasks.component';
import { AddothertasksComponent } from './addothertasks/addothertasks.component';
import { ViewothertasksComponent } from './viewothertasks/viewothertasks.component';
import { EditothertasksComponent } from './editothertasks/editothertasks.component';
import { OtherreceiptComponent } from './otherreceipt/otherreceipt.component';
import { AddotherreceiptComponent } from './addotherreceipt/addotherreceipt.component';
import { ViewotherreceiptComponent } from './viewotherreceipt/viewotherreceipt.component';
import { FirmtaskreportComponent } from './firmtaskreport/firmtaskreport.component';
import { FirmpaymentreportComponent } from './firmpaymentreport/firmpaymentreport.component';
import { CalendarComponent } from './calendar/calendar.component';
import { FirmpaymentduereportComponent } from './firmpaymentduereport/firmpaymentduereport.component';
import { SelftaskComponent } from './selftask/selftask.component';
import { ViewselftaskComponent } from './viewselftask/viewselftask.component';
import { EmpexpensereportComponent } from './empexpensereport/empexpensereport.component';
import { EditselftaskComponent } from './editselftask/editselftask.component';
import { DashboardeditmeetingsComponent } from './dashboardeditmeetings/dashboardeditmeetings.component';
import { DashboardeditcallsComponent } from './dashboardeditcalls/dashboardeditcalls.component';
import { DashboardedittasksComponent } from './dashboardedittasks/dashboardedittasks.component';
import { DashboardeditothertasksComponent } from './dashboardeditothertasks/dashboardeditothertasks.component';
import { DashboardeditselftaskComponent } from './dashboardeditselftask/dashboardeditselftask.component';
import { DashboardccduedatesComponent } from './dashboardccduedates/dashboardccduedates.component';
import { PrintreceiptComponent } from './printreceipt/printreceipt.component';

const routes: Routes = [{
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'employee',
        component: EmployeeComponent,
      },
      { path: 'createemployee', 
        component: CreateemployeeComponent
       },
       { path: 'editemployee', 
        component: EditemployeeComponent
       },
  
       { path: 'viewemployee', 
        component: ViewemployeeComponent
       },
       {
        path: 'court',
        component: CourtComponent,
      },
      { path: 'addcourt', 
        component: AddcourtComponent
       },
       { path: 'editcourt', 
        component: EditcourtComponent
       },
  
       { path: 'viewcourt', 
        component: ViewcourtComponent
       },
       {
        path: 'courtcase',
        component: CourtcaseComponent,
      },
      { path: 'addcase', 
        component: AddcaseComponent
       },
       { path: 'editcase', 
        component: EditcaseComponent
       },
  
       { path: 'viewcase', 
        component: ViewcaseComponent
       },

       {
        path: 'casedate',
        component: CasedateComponent,
      },
      { path: 'addcasedate', 
        component: AddcasedateComponent
       },
       { path: 'editcasedate', 
        component: EditcasedateComponent
       },
  
       { path: 'viewcasedate', 
        component: ViewcasedateComponent
       },
       {
        path: 'task',
        component: TaskComponent,
      },
      { path: 'createtask', 
        component: CreatetaskComponent
       },
       { path: 'edittask', 
        component: EdittaskComponent
       },
  
       { path: 'viewtask', 
        component: ViewtaskComponent
       },
       {
        path: 'expense',
        component: ExpenseComponent,
      },
      { path: 'addexpense', 
        component: AddexpenseComponent
       },
       { path: 'editexpense', 
        component: EditexpenseComponent
       },
  
       { path: 'viewexpense', 
        component: ViewexpenseComponent
       },
       {
        path: 'receipt',
        component: ReceiptComponent,
      },
      { path: 'addreceipt', 
        component: AddreceiptComponent
       },
       { path: 'editreceipt', 
        component: EditreceiptComponent
       },
  
       { path: 'viewreceipt', 
        component: ViewreceiptComponent
       },

       {
        path: 'taskreport',
        component: TaskreportComponent,
      },
      { path: 'selftaskreport', 
        component: SelftaskreportComponent
       },
       { path: 'expensereport', 
        component: ExpensereportComponent
       },
       { path: 'duepayment', 
        component: DuepaymentreportComponent
       },
       { path: 'datewisecollection', 
        component: DatewisecollectionreportComponent
       },

       {
        path: 'company',
        component: FirmComponent,
      },
      { path: 'addcompany', 
        component: AddfirmComponent
       },
       { path: 'editcompany', 
        component: EditfirmComponent
       },
  
       { path: 'viewcompany', 
        component: ViewfirmComponent
       },

       {
        path: 'meetings',
        component: MeetingsComponent,
      },
      { path: 'addmeetings', 
        component: AddmeetingsComponent
       },
       { path: 'editmeetings', 
        component: EditmeetingsComponent
       },
  
       { path: 'viewmeetings', 
        component: ViewmeetingsComponent
       },

       {
        path: 'calls',
        component: CallsComponent,
      },
      { path: 'addcalls', 
        component: AddcallsComponent
       },
       { path: 'editcalls', 
        component: EditcallsComponent
       },
  
       { path: 'viewcalls', 
        component: ViewcallsComponent
       },

       { path: 'casedatereport', 
        component: CasedatereportComponent
       },

       {
        path: 'othertasks',
        component: OthertasksComponent,
      },
      { path: 'addothertasks', 
        component: AddothertasksComponent
       },
       { path: 'editothertasks', 
        component: EditothertasksComponent
       },
  
       { path: 'viewothertasks', 
        component: ViewothertasksComponent
       },
       
       {
        path: 'otherreceipts',
        component: OtherreceiptComponent,
      },
      { path: 'addotherreceipts', 
        component: AddotherreceiptComponent
       },
       { path: 'viewotherreceipts', 
        component: ViewotherreceiptComponent
       },
       { path: 'firmstaskreport', 
       component: FirmtaskreportComponent
      },
      { path: 'firmspaymentreport', 
       component: FirmpaymentreportComponent
      },
      { path: 'calendar', 
       component: CalendarComponent
      },

      { path: 'companyduepayment', 
      component: FirmpaymentduereportComponent
     },

     { 
       path: 'selftasks', 
      component: SelftaskComponent
     },

     { 
      path: 'empexpensereport', 
     component: EmpexpensereportComponent
    },

    { 
      path: 'editselftask', 
     component: EditselftaskComponent
    },

    { 
      path: 'dashboardeditselftask', 
     component: DashboardeditselftaskComponent
    },

    { 
      path: 'dashboardeditothertasks', 
     component: DashboardeditothertasksComponent
    },

    { 
      path: 'dashboardedittask', 
     component: DashboardedittasksComponent
    },
    { 
      path: 'dashboardeditcalls', 
     component: DashboardeditcallsComponent
    },

    { 
      path: 'dashboardeditmeetings', 
     component: DashboardeditmeetingsComponent
    },

    { 
      path: 'dashboardaddccdates', 
     component: DashboardccduedatesComponent
    },
    { 
      path: 'receiptprint', 
     component: PrintreceiptComponent
    },
    ],
  }];
  
  
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule { }
