

<nb-card>
    <nb-card-header>Add Expense</nb-card-header>
    <nb-card-body>
        <form [formGroup]="expform" (ngSubmit)="addexpense(expform)"  autocomplete="off" role="form" >
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="expense_date" class="label">Date*</label><br>

            <input nbInput id="expense_date" name="expense_date"  formControlName="expense_date"
              placeholder="Date Picker" [nbDatepicker]="formpicker">
       <nb-datepicker #formpicker></nb-datepicker>
            <div *ngIf="f.expense_date.touched && f.expense_date.invalid" class="alert alert-danger">
              <div *ngIf="f.expense_date.errors.required">Date is required.</div>

          </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="expense" class="label">Expense*</label>
            <input type="text"  nbInput fullWidth id="expense" name="expense" formControlName="expense" autocomplete="off"  placeholder="Expense">
            <div *ngIf="f.expense.touched && f.expense.invalid" class="alert alert-danger">
              <div *ngIf="f.expense.errors.required">Expense is required.</div>
             
          </div>
          </div>
        </div>
      
        <div class="col-lg-4">
          <div class="form-group">
            <label for="amount" class="label">Amount*</label>
            <input type="text"  nbInput fullWidth id="amount" placeholder="Amount" name="amount" formControlName="amount" autocomplete="off">
            <div *ngIf="f.amount.touched && f.amount.invalid" class="alert alert-danger">
              <div *ngIf="f.amount.errors.required">Amount is required.</div>
              <div *ngIf="f.amount.errors.pattern">Enter only numbers.</div>
          </div>
          </div>
        </div>
        
      </div>
      
      <br>
      <div class="row">
       
            <div class="col-lg-6">
              <button type="button" class="btn btn-success"  (click)="back()" style="margin:10px">Back</button>
           
              <button type="submit" class="btn btn-primary" [disabled]="!expform.valid">Add</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  





