import { Component, OnInit } from '@angular/core';
import {ReportService} from 'app/services/report.service';
import { Selftask } from 'app/employee/selftask';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';


@Component({
  selector: 'ngx-selftaskreport',
  templateUrl: './selftaskreport.component.html',
  styleUrls: ['./selftaskreport.component.scss']
})
export class SelftaskreportComponent implements OnInit {
  streportform: FormGroup;
  selftasks:Selftask[];
  list:boolean=false;
  employees:any= [];

  
  constructor(private fb: FormBuilder,private dataService: ReportService,private router:Router) { 
    
   }

  ngOnInit() { 
    this.streportform = new FormGroup({
      from_date: new FormControl(),
      to_date: new FormControl(),
      emp_name:new FormControl(),
      status: new FormControl()
   });
   this.streportform = this.fb.group({
    from_date: ['', Validators.required],
    to_date: ['', Validators.required],
    emp_name: ['', Validators.required],
    status:['', Validators.required],

  });
  if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');
    
  this.dataService.getallemp(client_id)
            .subscribe((data: any) => {
              this.employees=data;
             
          });
        }
    
  }

  getselftask(streportform)
  {
    this.list=true;
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(streportform.value.from_date);
        let d = ("0" + as.getDate()).slice(-2);
        let m = ("0" + (as.getMonth() + 1)).slice(-2);
        let y = as.getFullYear();
        let from_date= y + "-" + m + "-" + d;
  
        var as1=new Date( streportform.value.to_date);
        let d1 = ("0" + as1.getDate()).slice(-2);
        let m1 = ("0" + (as1.getMonth() + 1)).slice(-2);
        let y1 = as1.getFullYear();
        let to_date= y1 + "-" + m1 + "-" + d1;

      this.dataService.selftaskreport(user_id,from_date,to_date,streportform.value.emp_name,streportform.value.status,client_id)
      .subscribe(response =>{
          this.selftasks = response.map(item =>
          {
            return new Selftask(
              item.selftask_id,
            item.client_id,
            item.user_id,
            item.emp_id,
             item.case_name,
              item.task_name, 
              item.description, 
              item.start_date,
              item.end_date,
              item.status
            );
          });
        });

              
            } 
              
          }

          task(): void {
            this.router.navigate(['admin/taskreport']);
          };

          othertask(): void {
            this.router.navigate(['admin/firmstaskreport']);
          };

          selftask(): void {
            this.router.navigate(['admin/selftaskreport']);
          };
         
  
          get case_name() { return this.streportform.get('case_name'); }
          get start_date() { return this.streportform.get('start_date'); }
          get task_name() { return this.streportform.get('task_name'); }
          get end_date() { return this.streportform.get('end_date'); }
          get description() { return this.streportform.get('description'); }

 

}

