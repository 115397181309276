export class Employee {
    public emp_id: number;
    public client_id:number;
    public user_id:number;
    public role_id:number;
    public emp_name: string;
    public emp_email:string;
    public mobile:string;
    public city:string;
    public address:string;
    public role:string;
    
    
    
    constructor(emp_id:number,client_id:number,user_id:number,role_id:number,emp_name: string,emp_email:string,mobile:string,city:string,address:string,role:string) {
      this.emp_id = emp_id;
      this.client_id=client_id;
      this.user_id=user_id;
      this.role_id=role_id;
      this.emp_name = emp_name;
      this.emp_email = emp_email;
      this.mobile = mobile;
      this.city=city;
      this.address=address;
      this.role=role;
      

      

    }
    }