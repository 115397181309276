import { Component, OnInit } from '@angular/core';
import {TaskService} from 'app/services/task.service';
import { Task } from 'app/admin/task';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-createtask',
  templateUrl: './createtask.component.html',
  styleUrls: ['./createtask.component.scss']
})
export class CreatetaskComponent implements OnInit {
  addtaskform: FormGroup;
  employees:any= [];
  caseNames:any= [];
  case:boolean=false;
 

  constructor(private fb: FormBuilder,private dataService: TaskService,private router:Router) { }

  ngOnInit(){
    this.case=true;
    
    this.addtaskform = new FormGroup({
      selecttype: new FormControl(),
      emp_name: new FormControl(),
      task_name: new FormControl(),
      task_date: new FormControl(),
      description: new FormControl(),
      case_name: new FormControl(),
      status: new FormControl(),
    

   });
   this.addtaskform = this.fb.group({
    selecttype: ['casetype'],
    emp_name: ['', Validators.required],
    task_name: ['', [Validators.required]],
    task_date: ['', [Validators.required]],
    description: ['', Validators.required],
    case_name: [''],
    status: ['Open', Validators.required],
    
   
  });
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
    this.dataService.getemp(client_id)
              .subscribe((data: any) => {
                this.employees=data;
               
            });
            this.dataService.getcasenames(client_id)
            .subscribe((data: any) => {
              this.caseNames=data;
             
          });
          }
  }

  get f(){
    return this.addtaskform.controls;
  }

  showcase(selecttype)
  {
    if(selecttype=="casetype")
    {
      this.case=true;
    }
    else
    {
      this.case=false;
    }
  }

          back(): void {
            this.router.navigate(['admin/task']);
          };
        
          createtask(addtaskform)
          {
            let formdate=addtaskform.value.task_date;
    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let todaysdate= year + "-" + month + "-" + date;
            if (localStorage.length > 0) {
              let client_id = localStorage.getItem('client_id');
              let username = localStorage.getItem('name');
              let user_id = localStorage.getItem('user_id');

              var as=new Date(addtaskform.value.task_date);
              let d1 = ("0" + as.getDate()).slice(-2);
              let m1 = ("0" + (as.getMonth() + 1)).slice(-2);
              let y1 = as.getFullYear();
              let task_date= y1 + "-" + m1 + "-" + d1;

              if(task_date >= todaysdate)
              {
              
                      this.dataService.addtasks(user_id,addtaskform.value.emp_name,addtaskform.value.case_name,task_date,addtaskform.value.task_name,addtaskform.value.description,addtaskform.value.status,client_id)
              .pipe()
              .subscribe(
                  data => {
                      this.router.navigate(['admin/task']);
                  },
                  error => {
                  });
              }
              else
              {
                alert("Select date greater than current date");
              }
                              
                    } 
                
                
            
                  }
        
          get case_name() { return this.addtaskform.get('case_name'); }
          get task_date() { return this.addtaskform.get('task_date'); }
          get task_name() { return this.addtaskform.get('task_name'); }
          get description() { return this.addtaskform.get('description'); }
          get note() { return this.addtaskform.get('note'); }
          get emp_name() { return this.addtaskform.get('emp_name'); }
          get status() { return this.addtaskform.get('status'); }
         
        
        }
        