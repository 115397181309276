import { Component, OnInit } from '@angular/core';
import {ExpenseService} from 'app/services/expense.service';
import { Expense } from 'app/admin/expense';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-editexpense',
  templateUrl: './editexpense.component.html',
  styleUrls: ['./editexpense.component.scss']
})
export class EditexpenseComponent implements OnInit {
  editexpform: FormGroup;
  constructor(private fb: FormBuilder,private dataService: ExpenseService,private router:Router) {
    

    
    this.editexpform = this.fb.group({
      expense_date: ['', Validators.required],
      expense: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      
     
    });
   }

  ngOnInit() { 
    this.editexpform = new FormGroup({
      expense_id: new FormControl(),
      expense_date: new FormControl(),
      expense: new FormControl(),
      amount: new FormControl(),
      
   });

   if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');
    let user_id = localStorage.getItem('user_id');
    let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['admin/expense']);
      return;
    }
   this.dataService.getexpenseId(+Id,client_id)
      .subscribe( data => {
       //this.viewform.controls[this.client_mobile].setValue('client_name')
        //this.client_email.nativeElement.value = 'This is new value';
        this.editexpform.patchValue({
          expense_id:data[0].expense_id, expense_date: data[0].expense_date, expense: data[0].expense, amount: data[0].amount
 
       });
      });
    }
    
  }

  back(): void {
    this.router.navigate(['admin/expense']);
  };

  editexpense(editexpform)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(editexpform.value.expense_date);
      let d = ("0" + as.getDate()).slice(-2);
      let m = ("0" + (as.getMonth() + 1)).slice(-2);
      let y = as.getFullYear();
      let c_date= y + "-" + m + "-" + d;
    
    this.dataService.updateexpenses(user_id,editexpform.value.expense_id,c_date,editexpform.value.expense,editexpform.value.amount,client_id)
 
    .pipe()
    .subscribe(
        data => {
            this.router.navigate(['admin/expense']);
        },
        error => {
        });
      }
  }
  
  get expense_date() { return this.editexpform.get('expense_date'); }
  get expense() { return this.editexpform.get('expense'); }
  get amount() { return this.editexpform.get('amount'); }

 

}
