import { Injectable, Output, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usermodule } from 'app/usermodule';
import {credential} from './credential';
@Injectable({
  providedIn: 'root'
})
export class CheckloginService {
  redirectUrl: string;
 
  baseUrl=credential.login_url;
@Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  constructor(private httpClient : HttpClient) { }
  
  public userlogin(username, password): Observable<Usermodule[]> {
    return this.httpClient.post<any>(this.baseUrl + '/checklogin.php', { username, password })
        .pipe(map(Usermodule => {
            this.setToken(Usermodule[0].name);
            this.getLoggedInName.emit(true);
            return Usermodule;
        }));
}

public resetpass( user_id,password): Observable<Usermodule[]> {
  return this.httpClient.post<any>(this.baseUrl + '/resetpassword.php', {user_id,password })
      .pipe(map(Usermodule => {
          return Usermodule;
      }));
}

public forgotpass( email): Observable<Usermodule[]> {
  return this.httpClient.post<any>(this.baseUrl + '/forgotpassword.php', {email })
      .pipe(map(Usermodule => {
          return Usermodule;
      }));
}



/*public logout(log_id): Observable<Usermodule[]>{
 
  return this.httpClient.post<any>(this.baseUrl + '/logout.php', {log_id})
        .pipe(map(Usermodule => {
            return Usermodule;
        }));

}*/

setToken(token: string) {
  localStorage.setItem('token', token);
}
 
getToken() {
  return localStorage.getItem('token');
}
 
deleteToken() {
  localStorage.removeItem('token');
}
 
isLoggedIn() {
  const usertoken = this.getToken();
  if (usertoken != null) {
    return true
  }
  return false;
}
}
