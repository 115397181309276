import { Component, OnInit } from '@angular/core';
import {EmployeeserviceService} from 'app/services/employeeservice.service';
import { Employee } from 'app/admin/employee';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {
  employees: Employee[];
  cat:number;
  public searchText:string;
  constructor(private dataService: EmployeeserviceService ,private router:Router) { }

  ngOnInit() {
    this.getempdetails();
  }
  getempdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getAllemployees(this.cat,client_id).subscribe(response =>
      {
        this.employees = response.map(item =>
        {
          return new Employee(
            item.emp_id,
            item.client_id,
            item.user_id,
            item.role_id,
              item.emp_name,
              item.emp_email,
              item.mobile,
              item.city,
              item.address,
              item.role
            
              
              
          );
        });
      });

    }
  }
  
  addemp(): void {
    this.router.navigate(['admin/createemployee']);
  };
  back(): void {
    this.router.navigate(['admin/employee']);
  };
  
  viewemp(emp: Employee): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", emp.emp_id.toString());
    this.router.navigate(['admin/viewemployee']);
  };
  deleteemp(emp:Employee)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removeemp(emp.emp_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getempdetails();
    })
  }
  }
  updateemp(emp: Employee): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", emp.emp_id.toString());
    this.router.navigate(['admin/editemployee']);
  };
  
  }