import { Component, OnInit } from '@angular/core';
import {SelftaskService} from 'app/services/selftask.service';
import {Selftask} from 'app/employee/selftask';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-viewselftask',
  templateUrl: './viewselftask.component.html',
  styleUrls: ['./viewselftask.component.scss']
})
export class ViewselftaskComponent implements OnInit {
  viewstform: FormGroup;
  employees:any= [];
  caseNames:any= [];
  case:boolean=false;

  constructor(private fb: FormBuilder,private dataService: SelftaskService,private router:Router) { }

  ngOnInit(){
    this.viewstform = new FormGroup({
      selftask_id: new FormControl(),
      task_name: new FormControl(),
      start_date: new FormControl(),
      end_date: new FormControl(),
      description: new FormControl(),
      case_name: new FormControl(),
      status:new FormControl()
   });
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
            this.dataService.getcasenames(client_id)
            .subscribe((data: any) => {
              this.caseNames=data;
             
          });

          let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['employee/selftask']);
      return;
    }
    this.dataService.getselftaskId(+Id,client_id)
      .subscribe( data => {
       //this.viewform.controls[this.client_mobile].setValue('client_name')
        //this.client_email.nativeElement.value = 'This is new value';
        if(data[0].case_name==null || data[0].case_name=="")
        {
          this.case=false;
          this.viewstform.patchValue({
            selftask_id:data[0].selftask_id,task_name: data[0].task_name, start_date: data[0].start_date,end_date: data[0].end_date,description: data[0].description,status: data[0].status
   
         });

        }
        else
        {
          this.case=true;
          this.viewstform.patchValue({
            selftask_id:data[0].selftask_id,case_name: data[0].case_name,task_name: data[0].task_name, start_date: data[0].start_date,end_date: data[0].end_date,status: data[0].status
   
         });

        }
      });
          }
  }


          back(): void {
            this.router.navigate(['employee/selftask']);
          };
        

        
          get case_name() { return this.viewstform.get('case_name'); }
          get start_date() { return this.viewstform.get('start_date'); }
          get task_name() { return this.viewstform.get('task_name'); }
          get end_date() { return this.viewstform.get('end_date'); }
          get description() { return this.viewstform.get('description'); }
        
        }
        