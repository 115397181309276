import { Component, OnInit } from '@angular/core';
import {TaskService} from 'app/services/task.service';
import { Task } from 'app/admin/task';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-editothertasks',
  templateUrl: './editothertasks.component.html',
  styleUrls: ['./editothertasks.component.scss']
})
export class EditothertasksComponent implements OnInit {
  edittaskform: FormGroup;
  employees:any= [];
  firmNames:any= [];

  constructor(private fb: FormBuilder,private dataService: TaskService,private router:Router) { }

  ngOnInit(){
    this.edittaskform = new FormGroup({
      othertask_id: new FormControl(),
      emp_name: new FormControl(),
      task_name: new FormControl(),
      task_date: new FormControl(),
      description: new FormControl(),
      firm_name: new FormControl(),
      status: new FormControl(),

    

   });
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
    this.dataService.getallemp(client_id)
              .subscribe((data: any) => {
                this.employees=data;
               
            });
            this.dataService.getfirmnames(client_id)
            .subscribe((data: any) => {
              this.firmNames=data;
             
          });

          let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['admin/othertasks']);
      return;
    }
    this.dataService.getothertaskId(+Id,client_id)
      .subscribe( data => {
       //this.viewform.controls[this.client_mobile].setValue('client_name')
        //this.client_email.nativeElement.value = 'This is new value';
        this.edittaskform.patchValue({
          othertask_id:data[0].othertask_id, firm_name: data[0].firm_name, emp_name: data[0].emp_id, task_name: data[0].task_name, task_date: data[0].task_date,description: data[0].description,status: data[0].status
 
       });
      });
          }
  }

  edittask(edittaskform)
  {
    let formdate=edittaskform.value.task_date;
    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let todaysdate= year + "-" + month + "-" + date;

    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(edittaskform.value.task_date);
      let d = ("0" + as.getDate()).slice(-2);
      let m = ("0" + (as.getMonth() + 1)).slice(-2);
      let y = as.getFullYear();
      let c_date= y + "-" + m + "-" + d;
      
    
    this.dataService.updateothertasks(edittaskform.value.othertask_id,user_id,edittaskform.value.emp_name,edittaskform.value.firm_name,c_date,edittaskform.value.task_name,edittaskform.value.description,edittaskform.value.status,client_id) 
    .pipe()
    .subscribe(
        data => {
            this.router.navigate(['admin/othertasks']);
        },
        error => {
        });
      
      }
  }

          otherback(): void {
            this.router.navigate(['admin/othertasks']);
          };
          get firm_name() { return this.edittaskform.get('firm_name'); }
          get task_date() { return this.edittaskform.get('task_date'); }
          get task_name() { return this.edittaskform.get('task_name'); }
          get description() { return this.edittaskform.get('description'); }
          get note() { return this.edittaskform.get('note'); }
          get emp_name() { return this.edittaskform.get('emp_name'); }
          get status() { return this.edittaskform.get('status'); }

}
