import { Component, OnInit } from '@angular/core';
import {EmployeeserviceService} from 'app/services/employeeservice.service';
import { Employee } from 'app/admin/employee';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-editemployee',
  templateUrl: './editemployee.component.html',
  styleUrls: ['./editemployee.component.scss']
})
export class EditemployeeComponent implements OnInit {

  empedit: FormGroup;
  roles:any=[];
  
  constructor(private fb: FormBuilder,private dataService: EmployeeserviceService,private router:Router) {
    
    
      }
 

      ngOnInit() {
        this.empedit = new FormGroup({
          emp_id: new FormControl(),
          emp_name: new FormControl(),
          emp_email: new FormControl(),
          mobile: new FormControl(),
          city: new FormControl(),
          address: new FormControl(),
          role: new FormControl()
    
    
          
       });
        if (localStorage.length > 0) {
          let client_id = localStorage.getItem('client_id');
          let username = localStorage.getItem('name');
      
        let Id = window.localStorage.getItem("editId");
        if(!Id) {
          this.router.navigate(['admin/employee']);
          return;
        }

        this.dataService.getroles()
              .subscribe((data: any) => {
                this.roles=data;
               
            });
          


        this.dataService.getempId(+Id,client_id)
          .subscribe( data => {
           //this.viewform.controls[this.client_mobile].setValue('client_name')
            //this.client_email.nativeElement.value = 'This is new value';
            this.empedit.patchValue({
              emp_id:data[0].emp_id, emp_name: data[0].emp_name, emp_email: data[0].emp_email, mobile: data[0].mobile, city: data[0].city,address: data[0].address,role: data[0].role
     
           });
          });
        }
      }

      back(): void {
        this.router.navigate(['admin/employee']);
      };


  editemp(empedit)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
    
    this.dataService.updateemp(empedit.value.emp_id, empedit.value.emp_name,empedit.value.emp_email,empedit.value.mobile,empedit.value.city,empedit.value.address,empedit.value.role,client_id)
 
    .pipe()
    .subscribe(
        data => {
            this.router.navigate(['admin/employee']);
        },
        error => {
        });
      }
  }
  get emp_email() { return this.empedit.get('emp_email'); }
      get emp_name() { return this.empedit.get('emp_name'); }
      get mobile() { return this.empedit.get('mobile'); }
      get city() { return this.empedit.get('city'); }
      get address() { return this.empedit.get('address'); }
      get role() { return this.empedit.get('role'); }
     
 

}


