export class Meetings {
    public meeting_id: number;
    public client_id:string;
    public user_id:string;
    public topic: string;
    public discussion:string;
    public scheduled_date:string;
    public scheduled_time:string;
    public client_name:string;
    
    
    
    constructor(meeting_id:number,client_id:string,user_id:string,topic: string,discussion:string,client_name:string,scheduled_date:string,scheduled_time:string) {
      this.meeting_id = meeting_id;
      this.client_id=client_id;
      this.user_id=user_id;
      this.topic=topic;
      this.discussion=discussion;
      this.client_name = client_name;
      this.scheduled_date=scheduled_date;
      this.scheduled_time=scheduled_time;
      
    }
}
