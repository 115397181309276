

<nb-card>
    <nb-card-header>Add Receipt</nb-card-header>
    <nb-card-body>
        <form [formGroup]="recform" (ngSubmit)="addreceipt(recform)"  autocomplete="off" role="form" >
      <div class="row">
        <div class="col-md-6">
            <div class="form-group">
              <label for="firm_name" class="label">Company Name*</label>
              <select  class="form-control" id="firm_name" name="firm_name" #firm_name formControlName="firm_name" (change)="showwork(firm_name.value)">
                  <option [ngValue]="null" [disabled]="true">Select Company</option>
                  <option *ngFor="let r of firmNames" [value]="r.firm_name">{{r.firm_name}}</option>
              </select>
              <div *ngIf="f.firm_name.touched && f.firm_name.invalid" class="alert alert-danger">
                <div *ngIf="f.firm_name.errors.required">Company Name is required.</div>
               
            </div>
            </div>
          </div>
          
        <div class="col-md-3">
          <div class="form-group">
            <label for="rdate" class="label">Date*</label><br>
            <input nbInput id="rdate" name="rdate"  formControlName="rdate"
            placeholder="Date Picker" [nbDatepicker]="formpicker">
     <nb-datepicker #formpicker></nb-datepicker>
            <div *ngIf="f.rdate.touched && f.rdate.invalid" class="alert alert-danger">
              <div *ngIf="f.rdate.errors.required">Date is required.</div>
          </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="installment" class="label">Installment*</label>
              <select name="installment" formControlName="installment" class="form-control" >
                <option [ngValue]="null" [disabled]="true">Select Installment</option>
                <option value="First Installment">First Installment</option>
                <option value="Second Installment">Second Installment</option>
                <option value="Third Installment">Third Installment</option>
                <option value="Fourth Installment">Fourth Installment</option>
                <option value="Fifth Installment">Fifth Installment</option>
              </select>
              <div *ngIf="f.installment.touched && f.installment.invalid" class="alert alert-danger">
                <div *ngIf="f.installment.errors.required">Installment is required.</div>
            </div>

              
      </div>
      </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="work" class="label">Work*</label>
            <select  class="form-control" id="work" name="work" #work formControlName="work" (change)="showamount(firm_name.value,work.value)">
                <option [ngValue]="null" [disabled]="true">Select Firm</option>
                <option *ngFor="let r of works" [value]="r.firm_detail_id">{{r.work}}</option>
            </select>
            <div *ngIf="f.work.touched && f.work.invalid" class="alert alert-danger">
              <div *ngIf="f.work.errors.required">Work is required.</div>
             
          </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="agreed_amount" class="label">Agreed Amount*</label>
<input type="text"  nbInput fullWidth id="agreed_amount" readonly  name="agreed_amount" #agreed_amount formControlName="agreed_amount" autocomplete="off" placeholder="Agreed Amount">    
</div>
        </div>
      </div>
<div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="paid" class="label">Paid*</label>
  <input type="text"  nbInput fullWidth id="paid" #paid name="paid" formControlName="paid" autocomplete="off" (change)="showdue(paid.value,agreed_amount.value,balance.value)" placeholder="Paid Amount">
  <div *ngIf="f.paid.touched && f.paid.invalid" class="alert alert-danger">
    <div *ngIf="f.paid.errors.required">Paid amount is required.</div>
    <div *ngIf="f.paid.errors.pattern">Enter only numbers.</div>
</div>    
</div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="due" class="label">Due*</label>
  <input type="text" readonly  nbInput fullWidth id="paid" #due name="due" formControlName="due" placeholder="Due" autocomplete="off" >
  
</div>
            </div>
        </div>  
      
        
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="balance" class="label">Total Received*</label>
<input type="text" readonly  nbInput fullWidth id="balance"  name="balance" #balance formControlName="balance" placeholder="Total Received" autocomplete="off" >

</div>
</div>
      </div>    

      
      <br>
      <div class="row"> 
        
            <div class="col-lg-6">
              <button type="button"  (click)="otherback()" class="btn btn-success" style="margin:10px">Back</button>
           
              <button type="submit" class="btn btn-primary" [disabled]="!recform.valid">Add</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  





