import { Component, OnInit } from '@angular/core';
import {FirmService} from 'app/services/firm.service';
import { Firm } from 'app/admin/firm';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-viewfirm',
  templateUrl: './viewfirm.component.html',
  styleUrls: ['./viewfirm.component.scss']
})
export class ViewfirmComponent implements OnInit {
  viewfirmform: FormGroup;
   f: FormGroup;
  dynamicArray: Array<Firm> = [];  
  newDynamic: any = {};  
  firm_id:number;
  firm_name:string;
  city:string;
  mobile:string;
  contact_person:string;
  
  constructor(private fb: FormBuilder,private dataService: FirmService,private router:Router) {
  
   }
   ngOnInit() {
    this.newDynamic = {firm_detail_id:"",work: "",agreed_amount:""};  
      this.dynamicArray.push(this.newDynamic);  
    
   if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');

  let Id = window.localStorage.getItem("editId");
  if(!Id) {
    this.router.navigate(['admin/company']);
    return;
  }
  this.dataService.getfirmId(+Id,client_id)
    .subscribe( data => {
    
      
       this.firm_id=data[0].firm_id; 
       this.firm_name=data[0].firm_name;
       this.city= data[0].city;
       this.contact_person=data[0].contact_person; 
       this.mobile=data[0].mobile;

    });
    this.dataService.getfirmdetails(+Id,client_id)
    .subscribe( data => {
          
        this.dynamicArray=data;

    });
  }
  }
  addRow(index) {    
    this.newDynamic = {firm_detail_id:"",work: "",agreed_amount:""};  
    this.dynamicArray.push(this.newDynamic); 
    console.log(this.dynamicArray);  
    return true;  
}  
  
deleteRow(index) {  
    if(this.dynamicArray.length ==1) {  
     
        return false;  
    } else {  
        this.dynamicArray.splice(index, 1);  
        
        return true;  
    }  
}  
  back(): void {
    this.router.navigate(['admin/company']);
  };

  

 
 /* get firm_name() { return this.editfirmform.get('firm_name'); }
  get city() { return this.editfirmform.get('city'); }
  get contact_person  () { return this.editfirmform.get('contact_person'); }
  get mobile() { return this.editfirmform.get('mobile'); }*/
  
 

}