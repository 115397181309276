import { Component, OnInit } from '@angular/core';
import {TaskService} from 'app/services/task.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-viewothertasksemp',
  templateUrl: './viewothertasksemp.component.html',
  styleUrls: ['./viewothertasksemp.component.scss']
})
export class ViewothertasksempComponent implements OnInit {
  viewtaskform: FormGroup;
  employees:any= [];
  firmNames:any= [];

  constructor(private fb: FormBuilder,private dataService: TaskService,private router:Router) { }

  ngOnInit(){
    this.viewtaskform = new FormGroup({
      othertask_id: new FormControl(),
      emp_name: new FormControl(),
      task_name: new FormControl(),
      task_date: new FormControl(),
      description: new FormControl(),
      firm_name: new FormControl(),
      status: new FormControl(),
      start_date:new FormControl(),
      end_date: new FormControl(),
      note: new FormControl()

   });
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
    this.dataService.getemp(client_id)
              .subscribe((data: any) => {
                this.employees=data;
               
            });
            this.dataService.getfirmnames(client_id)
            .subscribe((data: any) => {
              this.firmNames=data;
             
          });

          let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['employee/othertasks']);
      return;
    }
    this.dataService.getothertaskId(+Id,client_id)
      .subscribe( data => {
       //this.viewform.controls[this.client_mobile].setValue('client_name')
        //this.client_email.nativeElement.value = 'This is new value';
        this.viewtaskform.patchValue({
          othertask_id:data[0].othertask_id, firm_name: data[0].firm_name, emp_name: data[0].emp_id, task_name: data[0].task_name, task_date: data[0].task_date,description: data[0].description,status: data[0].status,note:data[0].note,start_date:data[0].start_date,end_date:data[0].end_date
 
       });
      });
          }
  }

  
         otherback(): void {
            this.router.navigate(['employee/othertasks']);
          };
          get firm_name() { return this.viewtaskform.get('firm_name'); }
          get task_date() { return this.viewtaskform.get('task_date'); }
          get task_name() { return this.viewtaskform.get('task_name'); }
          get description() { return this.viewtaskform.get('description'); }
          get note() { return this.viewtaskform.get('note'); }
          get emp_name() { return this.viewtaskform.get('emp_name'); }
          get status() { return this.viewtaskform.get('status'); }

}