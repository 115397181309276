import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Firm} from 'app/admin/firm';
import {credential} from './credential';
@Injectable({
  providedIn: 'root'
})
export class FirmService {

    redirectUrl: string;
    baseUrl=credential.login_url;
    constructor(private httpClient : HttpClient) { }
    getallfirms(id:number,client_id:string) : Observable<Firm[] > {
      return this.httpClient.get<Firm[]>(this.baseUrl+'/loadfirms.php?client_id='+client_id)
    }
  
    public addfirms(firm_name,city,contact_person,mobile,wrk,amount,user_id,client_id) {
      return this.httpClient.post<any>(this.baseUrl + '/firm_insert.php', {firm_name,city,contact_person,mobile,wrk,amount,user_id,client_id})
          .pipe(map(Court => {
              return Court;
          }));
    }
  
    public updatefirm(firm_id,firm_detail_id,firm_name,city,contact_person,mobile,wrk,amount,user_id,client_id) {
      return this.httpClient.post<any>(this.baseUrl + '/firm_update.php', {firm_id,firm_detail_id,firm_name,city,contact_person,mobile,wrk,amount,user_id,client_id })
        .pipe(map(Court => {
              return Court;
          }));
     
    }
    removefirm(firm_id: number,client_id:string): Observable<Firm[]> {
      return this.httpClient.delete<Firm[]>(this.baseUrl+'/firm_delete.php?firm_id='+firm_id+'&client_id='+client_id);
    }
    public getfirmId(firm_id: number,client_id:string): Observable<Firm[]>
    {
      return this.httpClient.get<Firm[]>(
        this.baseUrl + '/getfirms.php?firm_id=' + firm_id +'&client_id='+client_id
        );
    }

    public getfirmdetails(firm_id: number,client_id:string): Observable<Firm[]>
    {
      return this.httpClient.get<Firm[]>(
        this.baseUrl + '/getfirmdetails.php?firm_id=' + firm_id +'&client_id='+client_id
        );
    }
  
    public validatefirm(firm_name: string,client_id:string): Observable<Firm[]>
    {
      return this.httpClient.get<Firm[]>
      (this.baseUrl + '/validatefirm.php?firm_name='+firm_name +'&client_id='+client_id);
    }
    
  
  }
  
