import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Court} from 'app/admin/court';
import {credential} from './credential';
@Injectable({
  providedIn: 'root'
})
export class CourtService {

  redirectUrl: string;
  baseUrl=credential.login_url;
  constructor(private httpClient : HttpClient) { }
  getAllCourts(id:number,client_id:string) : Observable<Court[] > {
    return this.httpClient.get<Court[]>(this.baseUrl+'/loadcourts.php?client_id='+client_id)
  }

  public addCourts(court_name,judge_name,court_type,city,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/court_insert.php', {court_name,judge_name,court_type,city,client_id})
        .pipe(map(Court => {
            return Court;
        }));
  }

  public updatecourt(court_id,court_name,judge_name,court_type,city,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/court_update.php', {court_id,court_name,judge_name,court_type,city,client_id })
      .pipe(map(Court => {
            return Court;
        }));
   
  }
  removecourt(court_id: number,client_id:string): Observable<Court[]> {
    return this.httpClient.delete<Court[]>(this.baseUrl+'/court_delete.php?court_id='+court_id+'&client_id='+client_id);
  }
  public getcourtId(court_id: number,client_id:string): Observable<Court[]>
  {
    return this.httpClient.get<Court[]>(
      this.baseUrl + '/getcourts.php?court_id=' + court_id +'&client_id='+client_id
      );
  }

  public validatecourt(court_name: string,client_id:string): Observable<Court[]>
  {
    return this.httpClient.get<Court[]>
    (this.baseUrl + '/validatecourt.php?court_name='+court_name +'&client_id='+client_id);
  }
  

}
