import { Component, OnInit } from '@angular/core';
import {FirmService} from 'app/services/firm.service';
import { Firm } from 'app/admin/firm';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-firm',
  templateUrl: './firm.component.html',
  styleUrls: ['./firm.component.scss']
})
export class FirmComponent implements OnInit {
  firms: Firm[];
  cat:number;
  public searchText:string;
  constructor(private dataService: FirmService ,private router:Router) { }

  ngOnInit(): void {
    this.getfirmdetails();
  }
  getfirmdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getallfirms(this.cat,client_id).subscribe(response =>
      {
        this.firms = response.map(item =>
        {
          return new Firm(
            
            item.firm_id,
            item.client_id,
            item.firm_name,
            item.city,
              item.contact_person,
              item.mobile,
              item.firm_detail_id,
              item.user_id,
              item.work_date,
              item.work,
              item.agreed_amount
          );
        });
      });

    }
  }
  
  addfirm(): void {
    this.router.navigate(['admin/addcompany']);
  };
  back(): void {
    this.router.navigate(['admin/company']);
  };
  
  viewfirm(c: Firm): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.firm_id.toString());
    this.router.navigate(['admin/viewcompany']);
  };
  deletefirm(c:Firm)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removefirm(c.firm_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getfirmdetails();
    })
  }
  }
  updatefirm(c: Firm): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.firm_id.toString());
    this.router.navigate(['admin/editcompany']);
  };
  
  }
