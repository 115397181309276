export class Dashboard {
    public client_id:string;
    public user_id: string;
    public total_paid:string;
    public total_due:string;
    public task:string;
    public court:string;
    public opentasks:string;
    public closetasks:string;
    public cc_today:string;
    public cc_tomorrow:string;
    
    constructor(client_id:string,user_id: string,total_due:string,total_paid:string,task:string,closetasks:string,opentasks:string,court:string,cc_today:string,cc_tomorrow:string) {
      this.client_id=client_id;
      this.user_id=user_id;
      this.total_paid=total_paid;
      this.total_due=total_due;
      this.task = task;
      this.court = court;
      this.opentasks = opentasks;
      this.closetasks = closetasks;
       this.cc_today = cc_today;
      this.cc_tomorrow = cc_tomorrow;
          }    

}
