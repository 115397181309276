import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CheckloginService } from 'app/services/checklogin.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginform: FormGroup;
  fieldTextType: boolean;
  
  constructor(private fb: FormBuilder,private dataService: CheckloginService, private router:Router) {
  
    this.loginform = new FormGroup({
      email: new FormControl(),
      password: new FormControl()

   });
    
   }


  ngOnInit(): void {
    
    this.loginform = this.fb.group({
      email: ['', [Validators.required,Validators.minLength(1), Validators.email]],
      password: ['', Validators.required]
    })

    if(localStorage.getItem('user_id')!=null)
    {
      let url=localStorage.getItem('url');
    this.router.navigate([url]);
    }
  }

  get f(){
    return this.loginform.controls;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  
  testdata(loginform)
  {
    this.dataService.userlogin(loginform.value.email,loginform.value.password)
      .pipe(first())
      .subscribe(
          data => {
            var uclient_id=data[0].client_id;
            var uname=data[0].name;
            var u_id=data[0].user_id;
            var l_id=data[0].log_id;
            
            if(data[0].result=="Success")
            {
              const redirect = this.dataService.redirectUrl ? this.dataService.redirectUrl :data[0].URL;
                this.router.navigate([redirect]);
                localStorage.setItem('client_id',data[0].client_id);
                localStorage.setItem('user_id',data[0].user_id);
                localStorage.setItem('log_id',data[0].log_id);
                localStorage.setItem('url',data[0].URL);
                localStorage.setItem('name',uname);

            }
            else if(data[0].result=="Invalid")
            {
              alert("User name or password is incorrect");
            }
                
                
 
          },
          error => {
            
              alert("Error!");
          });
  }



  /*passdata()
  {
    this.dataService.forgotpass(loginform.value.email,loginform.value.password)
      .pipe(first())
      .subscribe(
          data => {
            
          },
          error => {
            
              alert("User name is incorrect");
          });
  }*/

  forgotpassword(): void {
   
    this.router.navigate(['/forgotpassword']);
  };

  get email() { return this.loginform.get('email'); }
  get password() { return this.loginform.get('password'); }

}
