

<nb-card>
    <nb-card-header>Courts</nb-card-header>
    <nb-card-body>
        <form [formGroup]="court_view"  autocomplete="off" role="form" >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <input type="hidden" readonly nbInput fullWidth formControlName="court_id" id="court_id" name="court_id" autocomplete="off" >
            <label for="court_name" class="label">Court Name</label>
            

            <input type="text"  nbInput fullWidth id="court_name" name="court_name" formControlName="court_name" autocomplete="off" placeholder="Name">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="judge_name" class="label">Judge Name</label>
            <input type="text"  nbInput fullWidth id="judge_name" name="judge_name" formControlName="judge_name" autocomplete="off"  placeholder="Email">
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="court_type" class="label">Court Type</label>
            <input type="text"  nbInput fullWidth id="court_type" placeholder="court_type" name="court_type" formControlName="court_type" autocomplete="off">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="city" class="label">City</label>
            <input type="text"  nbInput fullWidth id="city" name="city" formControlName="city" autocomplete="off" placeholder="City">
          </div>
        </div>
      </div>
      <br>
      <div class="row">
            <div class="col-lg-1">
              <button type="button"  (click)="back()" class="btn btn-success">Back</button>
            </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  






