export class Calls {
public call_id: number;
    public client_id:string;
    public user_id:string;
    public case_name: string;
    public task_name: string;
    public call_date: string;
    public emp_id:string;
    public note:string;
    public status:string;

    constructor(call_id:number,client_id:string,user_id:string,case_name: string,task_name:string,call_date:string,emp_id:string,note:string,status:string) {
      this.call_id = call_id;
      this.client_id=client_id;
      this.user_id=user_id;
      this.case_name=case_name;
      this.task_name=task_name;
      this.call_date=call_date;
      this.emp_id = emp_id;
      this.note=note;
      this.status=status;
      
    }
}
      