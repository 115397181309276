

<nb-card>
    <nb-card-header>Receipts</nb-card-header>
    <nb-card-body>
        <form [formGroup]="viewrecform"  autocomplete="off" role="form" >
      <div class="row">
       
        <div class="col-md-6">
          <div class="form-group">
            <label for="client_name" class="label">Client name</label>
            <input type="text" readonly  nbInput fullWidth id="client_name" name="client_name" formControlName="client_name" autocomplete="off"  placeholder="Client Name">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="case_name" class="label">Case Name</label>
            <input type="text"  readonly nbInput fullWidth id=" case_name" placeholder="Case Name" name="case_name" formControlName="case_name" autocomplete="off">
          </div>
        </div>
        
      </div>
      <br>
      <div class="row">
       
          <div class="col-md-6">
            <div class="form-group">
              <label for="case_no" class="label">Case No.</label>
  
              <input type="hidden"  nbInput fullWidth id="r_id" name="r_id" formControlName="r_id" autocomplete="off"  placeholder="Case no." >
             
                <input type="text" readonly autocomplete="off"  class="form-control frm  m-2" name="case_no" formControlName="case_no" #case_no placeholder="Case No" id="case_no" >
                     
              </div>
          </div>
          
        <div class="col-md-3">
          <div class="form-group">
            <label for="rdate" class="label">Date</label><br>

            <input nbInput id="rdate" name="rdate"  formControlName="rdate" disabled
            placeholder="Datepicker" [nbDatepicker]="formpicker">
     <nb-datepicker #formpicker></nb-datepicker>
              </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="installment" class="label">Installment</label>
            <input type="text" readonly autocomplete="off"  class="form-control" name="installment" formControlName="installment" #installment placeholder="Case No" id="installment" >  
      </div>
  </div>

      </div>
<br>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                  <label for="agreed_amount" class="label">Agreed Amount</label>
                  <input type="text" readonly  nbInput fullWidth id="agreed_amount" #agreed_amount placeholder="Agreed Amount" name="agreed_amount" formControlName="agreed_amount" autocomplete="off">
                </div>
              </div>
              
            <div class="col-md-6">
              <div class="form-group">
                <label for="paid" class="label">Paid</label>
    
                <input type="text" readonly  nbInput fullWidth id="paid" #paid name="paid" formControlName="paid" autocomplete="off"  placeholder="Paid Amount">
              </div>
            </div>    
      </div>
      
      <br>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="due" class="label">Due</label>
              <input type="text" readonly  nbInput fullWidth id="due" placeholder="Due" name="due" formControlName="due" autocomplete="off">
            </div>
          </div>
          
        <div class="col-md-6">
          <div class="form-group">
            <label for="balance" class="label">Total Received</label>

            <input type="text" readonly  nbInput fullWidth id="balance" name="balance" formControlName="balance" autocomplete="off" placeholder="Total Received">
          </div>
        </div>    
  </div>
      <div class="row">
            <div class="col-lg-1">
              <button type="button"  (click)="back()" class="btn btn-success">Back</button>
            </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  





