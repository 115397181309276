import { Component, OnInit } from '@angular/core';
import {ReceiptService} from 'app/services/receipt.service';
import { Receipt } from 'app/admin/receipt';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';


@Component({
  selector: 'ngx-viewreceipt',
  templateUrl: './viewreceipt.component.html',
  styleUrls: ['./viewreceipt.component.scss']
})
export class ViewreceiptComponent implements OnInit {
  viewrecform: FormGroup;
  
  constructor(private fb: FormBuilder,private dataService: ReceiptService,private router:Router) {
  
   }

  ngOnInit() { 
    this.viewrecform = new FormGroup({
      r_id: new FormControl(),
      case_no: new FormControl(),
      case_name: new FormControl(),
      client_name: new FormControl(),
      rdate: new FormControl(),
      agreed_amount: new FormControl(),
      paid: new FormControl(),
      due: new FormControl(),
      balance: new FormControl(),
      installment: new FormControl()

   });
  if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');
    let user_id = localStorage.getItem('user_id');
    let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['admin/receipt']);
      return;
    }
  this.dataService.getReceipt(+Id,client_id)
      .subscribe( data => {
       //this.viewform.controls[this.client_mobile].setValue('client_name')
        //this.client_email.nativeElement.value = 'This is new value';
        this.viewrecform.patchValue({
          r_id:data[0].r_id, case_no: data[0].case_no, case_name: data[0].case_name, client_name: data[0].client_name,rdate: data[0].rdate,agreed_amount: data[0].agreed_amount,paid: data[0].paid,due: data[0].due,balance: data[0].balance,installment:data[0].installment
       });
      });

    }
}



  back(): void {
    this.router.navigate(['admin/receipt']);
  };

  get r_id() { return this.viewrecform.get('r_id'); }
  get case_no() { return this.viewrecform.get('case_no'); }
  get case_name() { return this.viewrecform.get('case_name'); }
  get client_name() { return this.viewrecform.get('client_name'); }
  get rdate() { return this.viewrecform.get('rdate'); }
  get paid() { return this.viewrecform.get('paid'); }
  get due() { return this.viewrecform.get('due'); }
  get balance() { return this.viewrecform.get('balance'); }

 

}
