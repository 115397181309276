import { Component, OnInit } from '@angular/core';
import { Calls } from 'app/admin/calls';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { CallsService } from 'app/services/calls.service';
@Component({
  selector: 'ngx-empaddcalls',
  templateUrl: './empaddcalls.component.html',
  styleUrls: ['./empaddcalls.component.scss']
})
export class EmpaddcallsComponent implements OnInit {
  callsform: FormGroup;
  employees:any= [];
  caseNames:any= [];
  statuses:any = ['Open', 'Close'];
  case:boolean=false;
  task:boolean=false;
  
  constructor(private fb: FormBuilder,private dataService: CallsService,private router:Router) {
  
   }
  ngOnInit() {
    this.case=true;
    this.callsform = new FormGroup({
     
      selecttype: new FormControl(),
      case_name: new FormControl(),
      task_name: new FormControl(),
      call_date: new FormControl(),
      note: new FormControl(),
      status: new FormControl()
      
   });
    this.callsform = this.fb.group({
      selecttype: ['casetype'],
      note: [],
      case_name: [''],
      task_name:[''],
      call_date: ['', Validators.required],
      status: ['Open', Validators.required],
    });

    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
    this.dataService.getemp(client_id)
              .subscribe((data: any) => {
                this.employees=data;
               
            });
            this.dataService.getcasenames(client_id)
            .subscribe((data: any) => {
              this.caseNames=data;
             
          });
          }
  }
  back(): void {
    this.router.navigate(['employee/calls']);
  };

  get f(){
    return this.callsform.controls;
  }

  showcase(selecttype)
  {
    if(selecttype=="casetype")
    {
      this.case=true;
      this.task=false;
    }
    else
    {
      this.case=false;
      this.task=true;
    }
  }


  addcalls(callsform)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      let date_ob = new Date();
      let date = ("0" + date_ob.getDate()).slice(-2);
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
      let year = date_ob.getFullYear();
      let todaysdate= year + "-" + month + "-" + date;

      var as=new Date(callsform.value.call_date);
      let d = ("0" + as.getDate()).slice(-2);
      let m = ("0" + (as.getMonth() + 1)).slice(-2);
      let y = as.getFullYear();
      let call_date= y + "-" + m + "-" + d;

      /*if(call_date >= todaysdate)
      {*/
    this.dataService.addempcalls(user_id,callsform.value.case_name,callsform.value.task_name,call_date,callsform.value.note,callsform.value.status,client_id)
      .pipe()
      .subscribe(
          data => {
              this.router.navigate(['employee/calls']);
          },
          error => {
          });
       /* }
        else
        {
          alert("Select date greater than current date");
        }*/
      }
        
    }
  
 
  get case_name() { return this.callsform.get('case_name'); }
  get call_date  () { return this.callsform.get('call_date'); }
  get note() { return this.callsform.get('note'); }
  get status() { return this.callsform.get('status'); }

}
