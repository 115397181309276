import { Component, OnInit } from '@angular/core';
import {CaseserviceService} from 'app/services/caseservice.service';
import { Case } from 'app/admin/case';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';


@Component({
  selector: 'ngx-courtcase',
  templateUrl: './courtcase.component.html',
  styleUrls: ['./courtcase.component.scss']
})
export class CourtcaseComponent implements OnInit {

  courtcase: Case[];
  cat:number;
  public searchText:string;
  constructor(private dataService: CaseserviceService ,private router:Router) { }

  ngOnInit(): void {
    this.getcasedetails();
  }
  getcasedetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.getAllCases(this.cat,client_id).subscribe(response =>
      {
        this.courtcase = response.map(item =>
        {
          return new Case(
            item.case_id,
            item.client_id,
            item.user_id,
            item.case_no,
            item.case_date,
            item.case_name,
            item.court_name,
            item.judge_name,
              item.client_name,
              item.party_one,
              item.party_two,
              item.file_no,
              item.stage,
              item.owner_role,
              item.agreed_amount
          );
        });
      });

    }
  }
  
  addcourtcase(): void {
    this.router.navigate(['admin/addcase']);
  };
  back(): void {
    this.router.navigate(['admin/courtcase']);
  };
  
  viewcourtcase(c: Case): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.case_id.toString());
    this.router.navigate(['admin/viewcase']);
  };
  deletecourtcase(c:Case)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removecourtcase(c.case_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getcasedetails();
    })
  }
  }
  updatecourtcase(c: Case): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.case_id.toString());
    this.router.navigate(['admin/editcase']);
  };

}
