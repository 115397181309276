import { Component, OnInit } from '@angular/core';
import { Calls } from 'app/admin/calls';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { CallsService } from 'app/services/calls.service';

@Component({
  selector: 'ngx-dashboardeditcalls',
  templateUrl: './dashboardeditcalls.component.html',
  styleUrls: ['./dashboardeditcalls.component.scss']
})
export class DashboardeditcallsComponent implements OnInit {
  editcallsform: FormGroup;
  employees:any= [];
  caseNames:any= [];
  case:boolean=false;
  task:boolean=false;

  constructor(private fb: FormBuilder,private dataService: CallsService,private router:Router) { }

  ngOnInit(){
    this.editcallsform = new FormGroup({
      call_id: new FormControl(),
      emp_name: new FormControl(),
      case_name: new FormControl(),
      task_name: new FormControl(),
      call_date: new FormControl(),
      note: new FormControl(),
      status: new FormControl()
      
   });

    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
    this.dataService.getemp(client_id)
              .subscribe((data: any) => {
                this.employees=data;
               
            });
            this.dataService.getcasenames(client_id)
            .subscribe((data: any) => {
              this.caseNames=data;
             
          });

          let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['admin/dashboard']);
      return;
    }
    this.dataService.getcallId(+Id,client_id)
      .subscribe( data => {
        if(data[0].case_name==null || data[0].case_name=="")
        {
          this.case=false;
          this.task=true;
          this.editcallsform.patchValue({
            call_id:data[0].call_id,task_name:data[0].task_name, emp_name:data[0].emp_id, call_date:data[0].call_date, note: data[0].note,status: data[0].status
   
         });

        }
        else
        {
          this.case=true;
          this.task=false;
          this.editcallsform.patchValue({
            call_id:data[0].call_id,case_name:data[0].case_name, emp_name:data[0].emp_id, call_date:data[0].call_date, note: data[0].note,status: data[0].status
   
         });

        }
        
      });
          }
  }

  editcalls(editcallsform)
  {
    let formdate=editcallsform.value.call_date;
    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let todaysdate= year + "-" + month + "-" + date;

    var as=new Date(editcallsform.value.call_date);
    let d = ("0" + as.getDate()).slice(-2);
    let m = ("0" + (as.getMonth() + 1)).slice(-2);
    let y = as.getFullYear();
    let call_date= y + "-" + m + "-" + d;

    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
     
    
    this.dataService.updatecalls(editcallsform.value.call_id,user_id,editcallsform.value.emp_name,editcallsform.value.case_name,editcallsform.value.task_name,call_date,editcallsform.value.note,editcallsform.value.status,client_id)
 
    .pipe()
    .subscribe(
        data => {
            this.router.navigate(['admin/dashboard']);
        },
        error => {
        });
     
      }
  }
  
  back(): void {
    this.router.navigate(['admin/dashboard']);
  };
  get f(){
    return this.editcallsform.controls;
  }

          get emp_name() { return this.editcallsform.get('emp_name'); }
          get case_name() { return this.editcallsform.get('case_name'); }
          get call_date  () { return this.editcallsform.get('call_date'); }
          get note() { return this.editcallsform.get('note'); }
          get status() { return this.editcallsform.get('status'); }
}
