import { Component, OnInit } from '@angular/core';
import {ReceiptService} from 'app/services/receipt.service';
import { Receipt } from 'app/admin/receipt';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-otherreceipt',
  templateUrl: './otherreceipt.component.html',
  styleUrls: ['./otherreceipt.component.scss']
})
export class OtherreceiptComponent implements OnInit {
  receipts: Receipt[];
  otherreceipts: Receipt[];
  cat:number;
  public searchText:string;
  constructor(private dataService: ReceiptService ,private router:Router) { }

  ngOnInit() {
    this.getrecdetails();
    this.getotherrecdetails();
  }
  getrecdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getallreceipts(this.cat,client_id).subscribe(response =>
      {
        this.receipts = response.map(item =>
        {
          return new Receipt(
            item.r_id,
            item.or_id,
            item.client_id,
            item.user_id,
            item.receipt_no,
            item.rdate,
              item.case_no,
              item.paid,
              item.due,
              item.balance,
              item.case_name,
              item.firm_name,
              item.client_name,
              item.agreed_amount,
              item.total_due,
              item.total_paid,
              item.paid,
              item.installment
            
              
              
          );
        });
      });

    }
  }

  getotherrecdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getallotherreceipts(this.cat,client_id).subscribe(response =>
      {
        this.otherreceipts = response.map(item =>
        {
          return new Receipt(
            item.r_id,
            item.or_id,
            item.client_id,
            item.user_id,
            item.receipt_no,
            item.rdate,
            item.case_no,
              item.paid,
              item.due,
              item.balance,
              item.case_name,
              item.firm_name,
              item.client_name,
              item.agreed_amount,
              item.total_due,
              item.total_paid,
              item.paid,
              item.installment
            
            
              
              
          );
        });
      });

    }
  }
  
  addrec(): void {
    this.router.navigate(['admin/addreceipt']);
  };
  back(): void {
    this.router.navigate(['admin/receipt']);
  };
  
  viewrec(emp: Receipt): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", emp.r_id.toString());
    this.router.navigate(['admin/viewreceipt']);
  };
  deleterec(emp:Receipt)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removeReceipt(emp.r_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getrecdetails();
    })
  }
  }
  updaterec(emp: Receipt): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", emp.r_id.toString());
    this.router.navigate(['admin/editreceipt']);
  };

  otherreceipt(): void {
    this.router.navigate(['admin/otherreceipts']);
  };

  receipt(): void {
    this.router.navigate(['admin/receipt']);
  };

  addotherrec(): void {
    this.router.navigate(['admin/addotherreceipts']);
  };
  otherback(): void {
    this.router.navigate(['admin/otherreceipts']);
  };
  
  viewotherrec(emp: Receipt): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", emp.or_id.toString());
    this.router.navigate(['admin/viewotherreceipts']);
  };
  deleteotherrec(emp:Receipt)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removeotherReceipt(emp.or_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getotherrecdetails();
    })
  }
  }
  
  }