import { Component, OnInit } from '@angular/core';
import {ExpenseService} from 'app/services/expense.service';
import { Expense } from 'app/admin/expense';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-viewexpense',
  templateUrl: './viewexpense.component.html',
  styleUrls: ['./viewexpense.component.scss']
})
export class ViewexpenseComponent implements OnInit {
  viewexpform: FormGroup;
  constructor(private fb: FormBuilder,private dataService: ExpenseService,private router:Router) {
    

    
    this.viewexpform = this.fb.group({
      expense_date: ['', Validators.required],
      expense: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      
     
    });
   }

  ngOnInit() { 
    this.viewexpform = new FormGroup({
      expense_id: new FormControl(),
      expense_date: new FormControl(),
      expense: new FormControl(),
      amount: new FormControl(),
      
   });

   if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');
    let user_id = localStorage.getItem('user_id');
    let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['admin/expense']);
      return;
    }
   this.dataService.getexpenseId(+Id,client_id)
      .subscribe( data => {
       //this.viewform.controls[this.client_mobile].setValue('client_name')
        //this.client_email.nativeElement.value = 'This is new value';
        this.viewexpform.patchValue({
          expense_id:data[0].expense_id, expense_date: data[0].expense_date, expense: data[0].expense, amount: data[0].amount
 
       });
      });
    }
    
  }

  back(): void {
    this.router.navigate(['admin/expense']);
  };

  
  get expense_date() { return this.viewexpform.get('expense_date'); }
  get expense() { return this.viewexpform.get('expense'); }
  get amount() { return this.viewexpform.get('amount'); }

 

}
