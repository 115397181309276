import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CheckloginService } from 'app/services/checklogin.service';

@Component({
  selector: 'ngx-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  forform: FormGroup;
  //showlogin:boolean=false;
  //forgotpassword:boolean=false;
 
  constructor(private fb: FormBuilder,private dataService: CheckloginService, private router:Router) {
    //this.showlogin=true;
    //this.forgotpassword=false;
    this.forform = new FormGroup({
      email: new FormControl(),
      

   });
    
   }


  ngOnInit(): void {
    //this.showlogin=true;
    //this.forgotpassword=false;

    this.forform = this.fb.group({
      email: ['', [Validators.required,Validators.minLength(1), Validators.email]],
      

    })
  }

  get f(){
    return this.forform.controls;
  }

  
  
  signin(): void {
    this.router.navigate(['login']);
  };

  passdata(forform)
  {
    this.dataService.forgotpass(forform.value.email)
      .pipe(first())
      .subscribe(
          data => {
            if(data[0]['result']=="Valid")
            {
              alert("Reset link is sent on "+data[0].email);
            }
            else
            {
              alert("User name is incorrect");
            }
            
          },
          error => {
            
             // alert("User name is incorrect");
          });
  }

}
