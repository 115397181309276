import { Component, OnInit } from '@angular/core';
import { Empexpense } from 'app/employee/empexpense';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { EmpexpenseService } from 'app/services/empexpense.service';

@Component({
  selector: 'ngx-viewempexpense',
  templateUrl: './viewempexpense.component.html',
  styleUrls: ['./viewempexpense.component.scss']
})
export class ViewempexpenseComponent implements OnInit {
  viewexpform: FormGroup;
  constructor(private fb: FormBuilder,private dataService: EmpexpenseService,private router:Router) {
    

    
    this.viewexpform = this.fb.group({
      expense_date: ['', Validators.required],
      expense: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      
     
    });
   }

  ngOnInit() { 
    this.viewexpform = new FormGroup({
      expense_id: new FormControl(),
      expense_date: new FormControl(),
      expense: new FormControl(),
      amount: new FormControl(),
      
   });

   if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');
    let user_id = localStorage.getItem('user_id');
    let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['employee/expenses']);
      return;
    }
   this.dataService.getexpenseId(+Id,client_id)
      .subscribe( data => {
      
        this.viewexpform.patchValue({
          expense_id:data[0].expense_id, expense_date: data[0].expense_date, expense: data[0].expense, amount: data[0].amount
 
       });
      });
    }
    
  }

  back(): void {
    this.router.navigate(['employee/expenses']);
  };

  
  get expense_date() { return this.viewexpform.get('expense_date'); }
  get expense() { return this.viewexpform.get('expense'); }
  get amount() { return this.viewexpform.get('amount'); }

 

}
