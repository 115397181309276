import { Component, OnInit } from '@angular/core';
import {CasedateService} from 'app/services/casedate.service';
import { Casedate } from 'app/admin/casedate';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-viewcasedate',
  templateUrl: './viewcasedate.component.html',
  styleUrls: ['./viewcasedate.component.scss']
})
export class ViewcasedateComponent implements OnInit {

  viewcdate: FormGroup;
  clientNames:any=[];
  caseNames:any= [];
  constructor(private fb: FormBuilder,private dataService: CasedateService,private router:Router) { 
    
  }


  ngOnInit(): void {
    this.viewcdate = new FormGroup({
      case_date_id: new FormControl(),
      case_no: new FormControl(),
      case_name: new FormControl(),
      client_name: new FormControl(),
      prev_date: new FormControl(),
      next_date: new FormControl(),
      stage: new FormControl(),
      court_name:new FormControl(),
      party_two: new FormControl()
   });
    
   if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');
    let user_id = localStorage.getItem('user_id');
  
          
  let Id = window.localStorage.getItem("editId");
  if(!Id) {
    this.router.navigate(['admin/casedate']);
    return;
  }
  this.dataService.getcasedate(+Id,client_id)
    .subscribe( data => {
     //this.viewform.controls[this.client_mobile].setValue('client_name')
      //this.client_email.nativeElement.value = 'This is new value';
      this.viewcdate.patchValue({
        case_date_id:data[0].case_date_id,case_no:data[0].case_no, case_name: data[0].case_name,  client_name: data[0].client_name,prev_date: data[0].prev_date,next_date: data[0].next_date, stage: data[0].stage,court_name:data[0].court_name,party_two:data[0].party_two
       


     });
     this.dataService.getclientnames(client_id)
            .subscribe((data: any) => {
              this.clientNames=data;
             
          });
          this.dataService.getcasenames(client_id,data[0].client_name)
          .subscribe((data: any) => {
            this.caseNames=data;
           
        });
 
    });
  }
}

showcase(client_name,case_name)
  {
   
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
  

      this.dataService.getcasenames(client_id,client_name)
          .subscribe((data: any) => {
            this.caseNames=data;
           
        });

        this.dataService.getcaseno(client_id,client_name,case_name)
    .subscribe( data => {
    
      this.viewcdate.patchValue({
        case_no:data[0].case_no
     });
    });

        
    }

  }

  showcaseno(case_name,client_name)
  {    
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');


        this.dataService.getcaseno(client_id,client_name,case_name)
    .subscribe( data => {
    
      this.viewcdate.patchValue({
        case_no:data[0].case_no
     });
    });
    }
    

  }


  get case_no() { return this.viewcdate.get('case_no'); }
    get case_name() { return this.viewcdate.get('case_name'); }
  get client_name() { return this.viewcdate.get('client_name'); }
  get prev_date() { return this.viewcdate.get('prev_date'); }
  get next_date() { return this.viewcdate.get('next_date'); }
 
  

   
  

  


  back(): void {
    this.router.navigate(['admin/casedate']);
  } 
}
