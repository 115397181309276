import { Component, OnInit } from '@angular/core';
import {MeetingsService} from 'app/services/meetings.service';
import { Meetings } from 'app/admin/meetings';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss']
})
export class MeetingsComponent implements OnInit {
  firms: Meetings[];
  cat:number;
  public searchText:string;
  constructor(private dataService: MeetingsService ,private router:Router) { }

  ngOnInit(): void {
    this.getfirmdetails();
  }
  getfirmdetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.getallMeetings(this.cat,client_id).subscribe(response =>
      {
        this.firms = response.map(item =>
        {
          return new Meetings(
            
            item.meeting_id,
            item.client_id,
            item.user_id,
            item.topic,
            item.discussion,
              item.client_name,
              item.scheduled_date,
              item.scheduled_time
          );
        });
      });

    }
  }
  
  addmeetings(): void {
    this.router.navigate(['admin/addmeetings']);
  };
  back(): void {
    this.router.navigate(['admin/meetings']);
  };
  
  viewmeetings(c: Meetings): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.meeting_id.toString());
    this.router.navigate(['admin/viewmeetings']);
  };
  deletemeetings(c:Meetings)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removeMeetings(c.meeting_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getfirmdetails();
    })
  }
  }
  updatemeetings(c: Meetings): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.meeting_id.toString());
    this.router.navigate(['admin/editmeetings']);
  };
  
  }
