import { Component, OnDestroy, OnInit,Inject } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {CheckloginService} from 'app/services/checklogin.service';
import { Usermodule } from 'app/usermodule';
import { NB_WINDOW } from '@nebular/theme';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  username:string;

 
  items = [
    { title: 'Logout' }
  ];


  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService
              ,private dataService: CheckloginService,
               @Inject(NB_WINDOW) private window) {
                
  }

  ngOnInit() {
    this.menuService.onItemClick().subscribe(() => {
      if (window.innerWidth < 1200 && window.innerWidth >= 576) {
        this.sidebarService.compact('menu-sidebar');
      } else if (window.innerWidth < 576) {
        this.sidebarService.collapse('menu-sidebar');
      }
    });
    if (localStorage.length > 0) {
      let id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let uid = localStorage.getItem('user_id');
      let log_id = localStorage.getItem('log_id');
      this.username=username;
      
    } 
    /*else if(localStorage.getItem('client_id')==null)
    {
      window.location.href ="https://kaalacoat.com";
        
    }
    else 
    {
      //window.location.href ="http://localhost:4200";
      
    }*/

    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.title === 'Logout') {
        this.dataService.deleteToken();
        //this.dataService.logout(log_id);
        localStorage.removeItem('name');
        localStorage.removeItem('client_id');
        localStorage.removeItem('user_id');
        localStorage.clear();
        window.location.href = "https://kaalacoat.com";
      }
    });
   
   
  }
  
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  
  logout()
  {
    

    this.dataService.deleteToken();
    //this.dataService.logout(log_id);
    localStorage.removeItem('name');
    localStorage.removeItem('client_id');
    localStorage.removeItem('user_id');
    localStorage.clear();
    window.location.href = window.location.href;
    
    
  }
}
