import { Component, OnInit } from '@angular/core';
import {ReportService} from 'app/services/report.service';
import { Task } from 'app/admin/task';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-taskreport',
  templateUrl: './taskreport.component.html',
  styleUrls: ['./taskreport.component.scss']
})
export class TaskreportComponent implements OnInit {
  taskreportform: FormGroup;
  tasks:Task[];
  list:boolean=false;
  list1:boolean=false;
  employees:any= [];

  
  constructor(private fb: FormBuilder,private dataService: ReportService,private router:Router) { 
    
   }

  ngOnInit() { 
    this.taskreportform = new FormGroup({
      from_date: new FormControl(),
      to_date: new FormControl(),
      emp_name:new FormControl(),
      status:new FormControl()
   });
   this.taskreportform = this.fb.group({
    from_date: ['', Validators.required],
    to_date: ['', Validators.required],
    emp_name: ['', Validators.required],
    status: ['', Validators.required],

  });
  if (localStorage.length > 0) {
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');
    
  this.dataService.getallemp(client_id)
            .subscribe((data: any) => {
              this.employees=data;
             
          });
        }
    
  }

  gettask(taskreportform)
  {
    this.list=true;
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(taskreportform.value.from_date);
        let d = ("0" + as.getDate()).slice(-2);
        let m = ("0" + (as.getMonth() + 1)).slice(-2);
        let y = as.getFullYear();
        let from_date= y + "-" + m + "-" + d;
  
        var as1=new Date(taskreportform.value.to_date);
        let d1 = ("0" + as1.getDate()).slice(-2);
        let m1 = ("0" + (as1.getMonth() + 1)).slice(-2);
        let y1 = as1.getFullYear();
        let to_date= y1 + "-" + m1 + "-" + d1;

      this.dataService.taskreport(user_id,from_date,to_date,taskreportform.value.emp_name,taskreportform.value.status,client_id)
      .subscribe(response =>{
          this.tasks = response.map(item =>
          {
            return new Task(
              item.task_id,
              item.othertask_id,
              item.client_id,
              item.user_id,
              item.emp_id,
               item.case_name,
               item.firm_name,
                item.task_date,
                item.task_name, 
                item.description, 
                item.note,
                item.status,
                item.start_date,
                item.end_date,
                item.client_name
               
              
            );
          });
        });

              
            } 
              
          }

          task(): void {
            this.router.navigate(['admin/taskreport']);
          };

          othertask(): void {
            this.router.navigate(['admin/firmstaskreport']);
          };

          selftask(): void {
            this.router.navigate(['admin/selftaskreport']);
          };
          get case_name() { return this.taskreportform.get('case_name'); }
          get task_date() { return this.taskreportform.get('task_date'); }
          get task_name() { return this.taskreportform.get('task_name'); }
          get description() { return this.taskreportform.get('description'); }
          get note() { return this.taskreportform.get('note'); }
          get emp_name() { return this.taskreportform.get('emp_name'); }
          get status() { return this.taskreportform.get('status'); }


 

}

