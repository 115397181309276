

<nb-card>
    <nb-card-header>Add Company</nb-card-header>
    <nb-card-body>
        <form name="form" (ngSubmit)="f.form.valid && onSubmit(f.value)" #f="ngForm"   autocomplete="off" role="form" novalidate >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="firm_name" class="label">Company Name*</label>
            <input type="text"  nbInput fullWidth id="firm_name" name="firm_name" ngModel  #firm_name="ngModel" autocomplete="off" placeholder="Name" [ngClass]="{ 'is-invalid': f.submitted && firm_name.invalid }" required>
            <div *ngIf="f.submitted && firm_name.invalid" class="alert alert-danger">
              <div *ngIf="firm_name.errors.required">Company Name is required</div>
          </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="city" class="label">City*</label>
            <input type="text"  nbInput fullWidth id="city" name="city"  autocomplete="off" ngModel  #city="ngModel"  placeholder="City" [ngClass]="{ 'is-invalid': f.submitted && city.invalid }" required>
            <div *ngIf="f.submitted && city.invalid" class="alert alert-danger">
              <div *ngIf="city.errors.required">City is required</div>
          </div>
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="contact_person" class="label">Contact Person*</label>
            <input type="text"  nbInput fullWidth id="contact_person" placeholder="Contact Person" ngModel  #contact_person="ngModel" name="contact_person"  autocomplete="off" [ngClass]="{ 'is-invalid': f.submitted && contact_person.invalid }" required>
            <div *ngIf="f.submitted && contact_person.invalid" class="alert alert-danger">
              <div *ngIf="contact_person.errors.required">Contact Person is required</div>
          </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="mobile" class="label">Mobile*</label>
            <input type="text"  nbInput fullWidth id="mobile"  name="mobile" ngModel  #mobile="ngModel" autocomplete="off" placeholder="Mobile" [pattern]="mobNumberPattern" maxlength="10" [ngClass]="{ 'is-invalid': f.submitted && mobile.invalid }" required>
            <div *ngIf="f.submitted && mobile.invalid" class="alert alert-danger">
              <div *ngIf="mobile.errors.required">Mobile No. is required</div>
              <div *ngIf="mobile.errors.pattern">Enter valid Mobile No.</div>
          </div>
          </div>
        </div>
      </div>
      <br>
      <table class="table table-bordered">    
        <thead>    
            <tr>    
                  
                <th style="width:10%">Sr.No</th>    
                <th style="width:50%">Work</th>    
                <th style="width:20%">Agreed Amount</th> 
                <th style="width:10%">Add</th> 
                <th style="width:10%">Remove</th>      
            </tr>    
        </thead>    
        <tbody>    
             <tr *ngFor="let dynamic of dynamicArray; let i = index">    
               
                <td>    
                 <label >{{i + 1}}</label>    
                </td>    
                <td>    
                  <textarea  [(ngModel)]="dynamicArray[i].work" style="width: 100%; max-width: 100%;" ngModel #work="ngModel" rows="2" cols="50" nbInput id="work" placeholder="Work" name="work{{i}}" autocomplete="off" [ngClass]="{ 'is-invalid': f.submitted && work.invalid }" required>  </textarea>  
                  <div *ngIf="f.submitted && work.invalid" class="alert alert-danger">
                    <div *ngIf="work.errors.required">Work is required</div>
                </div>
                </td>    
                <td>    
                  <input  nbInput fullWidth [(ngModel)]="dynamicArray[i].agreed_amount" ngModel #agreed_amount="ngModel" id="agreed_amount" placeholder="Agreed Amount" name="agreed_amount{{i}}" maxlength="10" pattern="^[0-9]*$"  autocomplete="off" type="text" [ngClass]="{ 'is-invalid': f.submitted && agreed_amount.invalid }" required/>    
                  <div *ngIf="f.submitted && agreed_amount.invalid" class="alert alert-danger">
                    <div *ngIf="agreed_amount.errors.required">Agreed Amount is required</div>
                    <div *ngIf="agreed_amount.errors.pattern">Enter valid amount</div>
                </div>
                </td> 
                <td (click)="addRow(i)">    
                  <i class="fa fa-plus-square fa-2x" style="color:#24a0ed"></i>    
                </td> 
                <td (click)="deleteRow(i)" *ngIf="i!=0">    
                  <i class="fa fa-minus-square fa-2x" style="color:rgb(163, 5, 5)"></i>    
                </td>     
            </tr>    
            <tr>    
               
            </tr>   
        </tbody>    
    </table>    
      <br>
      <div class="row">
        
            <div class="col-lg-6">
              <button type="button" class="btn btn-success"   (click)="back()" style="margin:10px">Back</button>
          
              <button type="submit" class="btn btn-primary" >Add</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  





