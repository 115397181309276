<nb-card>
    <nb-card-header>Edit Court Case</nb-card-header>
    <nb-card-body>
        <form [formGroup]="editcase" (ngSubmit)="courtcaseedit(editcase)"  autocomplete="off" role="form" >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="case_no" class="label">Case No</label>
            <input type="hidden"  nbInput fullWidth id="case_id" name="case_id" formControlName="case_id" autocomplete="off" >
            <input type="text"  nbInput fullWidth id="case_no" name="case_no" formControlName="case_no" autocomplete="off" placeholder="Case No.">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="case_date" class="label">Date</label><br>
            <input nbInput id="case_date" name="case_date"  formControlName="case_date"
              placeholder="Form Picker" [nbDatepicker]="formpicker">
       <nb-datepicker #formpicker></nb-datepicker>
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="court_name" class="label">Court name</label>
            <input type="text"  nbInput fullWidth id="court_name" name="court_name" formControlName="court_name" autocomplete="off" placeholder="Court Name">
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label for="judge_name" class="label">Judge Name</label>
              <input type="text" nbInput fullWidth id="judge_name" name="judge_name" formControlName="judge_name" autocomplete="off" placeholder="Judge Name">
            </div>
          </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="case_name" class="label">Case Name</label>
            <input type="text"  nbInput fullWidth id="case_name" name="case_name" formControlName="case_name" autocomplete="off" placeholder="Case Name">
          </div>
        </div>
      </div>
<br>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="client_name" class="label">Client Name</label>

            <input type="text"  nbInput fullWidth id="client_name" name="client_name" formControlName="client_name" autocomplete="off" placeholder="Client Name">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="party_one" class="label">Plainitiff/Appellant/Petitioner/Applicant/Complainant*</label>
            <input type="text"  nbInput fullWidth id="party_one" name="party_one" formControlName="party_one" autocomplete="off" placeholder="Party One">
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="party_two" class="label">Defendant/Respondent/Opponent/Accused*</label>

            <input type="text"  nbInput fullWidth id="party_two" name="party_two" formControlName="party_two" autocomplete="off" placeholder="Party Two">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="stage" class="label">Stage</label>
            <input type="text"  nbInput fullWidth id="stage" name="stage" formControlName="stage" autocomplete="off" placeholder="Stage">
          </div>
        </div>
      </div><br>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="file_no" class="label">File No.</label>

            <input type="text"  nbInput fullWidth id="file_no" name="file_no" formControlName="file_no" autocomplete="off" placeholder="File No">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="agreed_amount" class="label">Agreed Amount</label>
            <input type="text"  nbInput fullWidth id="agreed_amount" name="agreed_amount" formControlName="agreed_amount" autocomplete="off" placeholder="Agreed Amount">
          </div>
        </div>
      </div><br>
      
      
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="Owner_role" class="label">Role</label>
                <select name="owner_role" formControlName="owner_role" class="form-control" >
                  <option value="Plainitiff">Plainitiff</option>
                  <option value="Appellant">Appellant</option>
                  <option value="Petitioner">Petitioner</option>
                  <option value="Applicant">Applicant</option>
                  <option value="Complainant">Complainant</option>
                  <option value="Defendant">Defendant</option>
                  <option value="Respondent">Respondent</option>
                  <option value="Opponent">Opponent</option>
                  <option value="Accused">Accused</option>
                  <option value="Third Party">Third Party</option>
                  <option value="Certificate Holder">Certificate Holder</option>
                  <option value="Certificate Debtor">Certificate Debtor</option>
                  <option value="Decree Holder">Decree Holder</option>
                  <option value="Judgment Holder">Judgment Holder</option>
                </select>
                
        </div>
    </div>
        
        </div>
      <br>
      <div class="row">
        
            <div class="col-lg-6">
              <button type="button"  (click)="back()" class="btn btn-success" style="margin:10px">Back</button>
          
            <button type="submit" class="btn btn-primary" [disabled]="!editcase.valid">Update</button>
            </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  






