export class Case {
    public case_id: number;
    public client_id:string;
    public user_id: string;
    public case_no:string;
    public court_name:string;
    public judge_name:string;

    public case_date:string;
    public case_name:string;
    public client_name:string;
    public party_one:string;
    public party_two:string;
    public stage:string;
    public file_no:string;
    public agreed_amount:string;
    public owner_role:string;
    
    
    
    constructor(case_id:number,client_id:string,user_id: string,case_no:string,case_date:string,case_name:string,court_name:string,judge_name:string,client_name:string,party_one:string,party_two:string,stage:string,file_no:string,owner_role:string,agreed_amount:string) {
      this.case_id = case_id;
      this.client_id=client_id;
      this.user_id=user_id;
      this.case_no=case_no;
      this.case_date=case_date;
      this.case_name = case_name;
      this.court_name = court_name;
      this.judge_name=judge_name;
      this.client_name=client_name;
      this.party_one=party_one;
      this.party_two=party_two;
      this.stage=stage;
      this.file_no=file_no;
      this.agreed_amount=agreed_amount;
      this.owner_role=owner_role;
    }    

}
