

<nb-card>
  <nb-card-header>Company</nb-card-header>
  <nb-card-body>
      <form #f="ngForm" name="editfirmform"  autocomplete="off" role="form" >
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="firm_name" class="label">Company Name*</label>
          <input type="text" readonly  nbInput fullWidth id="firm_name" name="firm_name"  [(ngModel)]="firm_name" required   autocomplete="off" placeholder="Name">
          <input type="hidden" readonly  nbInput fullWidth id="firm_id" name="firm_id" [(ngModel)]="firm_id"  required   autocomplete="off">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="city"  class="label">City*</label>
          <input type="text"  readonly nbInput fullWidth id="city" name="city" [(ngModel)]="city"  autocomplete="off"  required  placeholder="City">
          
        </div>
      </div>
    </div><br>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label for="contact_person" class="label">Contact Person*</label>
          <input type="text" readonly nbInput fullWidth id="contact_person" placeholder="Contact Person" [(ngModel)]="contact_person" required  name="contact_person"  autocomplete="off">
          
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label for="mobile" class="label">Mobile*</label>
          <input type="text" readonly nbInput fullWidth id="mobile" name="mobile" required [(ngModel)]="mobile" autocomplete="off" placeholder="Mobile">
          
        </div>
      </div>
    </div>
    <br>
    <table class="table table-bordered">    
      <thead>    
          <tr>    
                
              <th>Sr.No</th>    
              <th>Work</th>    
              <th>Agreed Amount</th>       
          </tr>    
      </thead>    
      <tbody>    
           <tr *ngFor="let dynamic of dynamicArray; let i = index">    
             
              <td>    
               <label >{{i + 1}}</label>    
              </td>    
              <td> 
                <input  nbInput fullWidth [(ngModel)]="dynamicArray[i].firm_detail_id" ngModel #agreed_amount="ngModel" id="firm_detail_id"  name="firm_detail_id{{i}}"  autocomplete="off" type="hidden"/>     
                <textarea readonly style="width: 100%;" [(ngModel)]="dynamicArray[i].work" ngModel #work="ngModel" rows="4" cols="25" nbInput id="work" placeholder="Work" name="work{{i}}" autocomplete="off" >  </textarea>  
              </td>    
              <td>    
                <input  nbInput readonly fullWidth [(ngModel)]="dynamicArray[i].agreed_amount" ngModel #agreed_amount="ngModel" id="agreed_amount" placeholder="Agreed Amount" name="agreed_amount{{i}}"  autocomplete="off" type="text"/>    
              </td> 
          </tr>    
          <tr>    
             
          </tr>    
      </tbody>    
  </table>    
    <br>
    <div class="row">
      
          <div class="col-lg-1">
            <button type="button" class="btn btn-success"   (click)="back()">Back</button>
          </div>
          
    </div>
   
   
    </form>
  </nb-card-body>
</nb-card>






