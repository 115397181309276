import { Component, OnInit } from '@angular/core';
import {CasedateService} from 'app/services/casedate.service';
import { Casedate } from 'app/admin/casedate';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';


@Component({
  selector: 'ngx-casedate',
  templateUrl: './casedate.component.html',
  styleUrls: ['./casedate.component.scss']
})
export class CasedateComponent implements OnInit {
  casedates: Casedate[];
  cat:number;
  public searchText:string;
  constructor(private dataService: CasedateService ,private router:Router) { }

  ngOnInit(): void {
    this.getcasedetails();
  }
  getcasedetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.getallcases(this.cat,client_id).subscribe(response =>
      {
        this.casedates = response.map(item =>
        {
          return new Casedate(
            item.case_date_id,
            item.case_id,
            item.client_id,
            item.user_id,
            item.case_no,
            item.case_name,
            item.client_name,
            item.prev_date,
            item.next_date,
            item.stage,
            item.court_name,
            item.party_two
          );
        });
      });

    }
  }
  
  addcasedate(): void {
    this.router.navigate(['admin/addcasedate']);
  };
  back(): void {
    this.router.navigate(['admin/casedate']);
  };
  
  viewccdate(c: Casedate): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.case_date_id.toString());
    this.router.navigate(['admin/viewcasedate']);
  };
  deleteccdate(c:Casedate)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removecasedate(c.case_date_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getcasedetails();
    })
  }
  }
  updateccdate(c: Casedate): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.case_date_id.toString());
    this.router.navigate(['admin/editcasedate']);
  };

}
