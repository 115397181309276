<nb-card>
    <nb-card-header>Case Date</nb-card-header>
    <nb-card-body>
        <form [formGroup]="viewcdate"  autocomplete="off" role="form" >
      
      <div class="row">
        
          <div class="col-md-6">
            <div class="form-group">
              <label for="client_name" class="label">Client Name</label>
              <select id="client_name" class="form-control" disabled #client_name name="client_name" (change)="showcase(client_name.value, case_name.value)"  formControlName="client_name"  >
                <option [ngValue]="null" [disabled]="true" >Choose Client </option>
                <option *ngFor="let r of clientNames" [value]="r.client_name">{{r.client_name}}</option>
            </select> 
            </div>
          </div>
          <input type="hidden"  nbInput fullWidth id="case_date_id" name="case_date_id" formControlName="case_date_id" autocomplete="off" >
        <div class="col-lg-6">
          <div class="form-group">
            <label for="case_name" class="label">Case Name</label>
            <select id="case_name" class="form-control" disabled #case_name name="case_name"  formControlName="case_name" (change)="showcaseno(case_name.value,client_name.value)"  >
              <option [ngValue]="null" [disabled]="true">Choose Case </option>
              <option *ngFor="let r of caseNames" [value]="r.case_name">{{r.case_name}}</option>
          </select> 
          </div>
        </div>
      </div>
      
<br>
<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label for="case_no" class="label">Case No</label>

      <input type="text" readonly  nbInput fullWidth id="case_no" name="case_no" formControlName="case_no" autocomplete="off" placeholder="Case No.">
    </div>
  </div>
  <div class="col-lg-6">
    <div class="form-group">
      <label for="court_name" class="label">Court Name</label>
      <input type="text" readonly  nbInput fullWidth id="court_name" name="court_name" formControlName="court_name" autocomplete="off" placeholder="Court Name">
      </div>
    </div>
</div><br>
<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label for="party_two" class="label">Defendant/Respondent/Opponent/Accused</label>

      <input type="text" readonly  nbInput fullWidth id="party_two" name="party_two" formControlName="party_two" autocomplete="off" placeholder="Defendant/Respondent/Opponent/Accused">
      
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label for="stage" class="label">Stage</label>

      <input type="text" readonly nbInput fullWidth id="stage" name="stage" formControlName="stage" autocomplete="off" placeholder="Stage">
    </div>
  </div>
  
</div><br>
      <div class="row">
        
        <div class="col-md-6">
            <div class="form-group">
              <label for="prev_date" class="label">Previous Date</label><br>
              <input nbInput id="prev_date" name="prev_date"  formControlName="prev_date" disabled
              placeholder="Form Picker" [nbDatepicker]="formpicker">
       <nb-datepicker #formpicker></nb-datepicker>
            </div>
          </div>
  
      <div class="col-md-6">
        <div class="form-group">
          <label for="next_date" class="label">Next Date</label><br>
          <input nbInput id="next_date" name="next_date"  formControlName="next_date" disabled
          placeholder="Form Picker" [nbDatepicker]="formpicker">
   <nb-datepicker #formpicker></nb-datepicker>
        </div>
      </div>
    </div>
    <br>
      <div class="row">
            <div class="col-lg-1">
              <button type="button"  (click)="back()" class="btn btn-success">Back</button>
            </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  







