<div class="row"  >
    <div class="col-md-3" >
    </div>
<div class="col-md-6 col-md-offset-3">
        <div class="jumbotron" style="float:center">
            <div class="container-fluid">
            <h5 class="text-center">Reset Password</h5>
            <br>
            <form [formGroup]="forform" (ngSubmit)="passdata(forform)"  autocomplete="off" >
                <div class="form-group">
                    
                    <div class="input-container">
                        <i class="fa fa-envelope icon"></i>
                    <input type="email" name="email" formControlName="email" autocomplete="off" class="form-control input-sm" placeholder="Email Address">
                    
                    
                    
                </div>
                <div *ngIf="f.email.touched && f.email.invalid" class="error">
                    <div *ngIf="f.email.errors.required" style="color:red" >Email is required.</div>
                    <div *ngIf="f.email.errors.email" style="color:red">Enter valid email id</div>
                   
                </div>
                </div>
               
                <button type="submit" class="btn" [disabled]="!forform.valid">Reset Password</button><br><br>
                <p style="color:gray"><a (click)="signin()" style="cursor:pointer">Sign In</a></p>
            </form>
        </div>
    </div>
    </div>
    <div class="col-md-3">
    </div>
       
</div>









