<nb-card>
    <nb-card-header><h5>Tasks</h5></nb-card-header>
    <nb-card-body>
  

    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link " data-toggle="tab" href="#panel11" (click)="task()" role="tab" style="text-decoration:none;">Court Tasks</a>
      </li>
      <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#panel12" (click)="othertask()"  role="tab" style="text-decoration:none;">Other Tasks</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#panel13" (click)="selftask()"  role="tab" style="text-decoration:none;">Self Tasks</a>
      </li>
    </ul>
    <div class="tab-content pt-0">

     
      <div class="tab-pane fade " id="panel11" role="tabpanel">
        <br>
    
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
            </div>
            </div>   
            <div class="col-md-6">
              <button class="btn btn-primary"  (click)="addtask()" style="float:right"> Add </button>
            </div>    
            <div class="col-md-12">
          
              
              <br><br>
              <table class="table table-hover" >
                <thead>
                <tr>
         
                  <th>Sr.No</th>
                  <th>Task Date</th>
                  <th>Case Name</th>
                  <th>Employee</th>
                  <th>Task Name</th>
                  <th>Status</th>
                  <th>Note</th>
                  
                  <th>View</th>
                  <th>Edit</th>
                  <th>Delete</th>
                  
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let t of tasks | filter: searchText; let i = index"  >
         
                  <td>{{i + 1}}</td>
                  <td>{{t.task_date}}</td>
                  <td>{{t.case_name}}</td>
                  <td>{{t.emp_id}}</td>
                  <td>{{t.task_name}}</td>
                  <td>{{t.status}}</td>
                  <td>{{t.note}}</td>
                  <td><a (click)="viewtask(t)" >
                    <i class="fa fa-eye" style="color: #00A0F0;font-size: 17px;margin-left:10px;" ></i></a>  </td>
                    <td><a (click)="updatetask(t)"  >
                      <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a>  </td>
                      <td><a (click)="deletetask(t)" >
                        <i class="fa fa-trash" style="color:maroon;font-size: 17px;margin-left:10px;"></i></a>  </td>
                  </tr>
                </tbody>
              </table>
              <!--<div class="alert alert-info alert-dismissible" role="alert" >
                <i class="fa fa-info-circle"></i> 
                No Records found
              </div>-->
            </div>
          </div>
        </div>
      </div>
      
      <!--/.Panel 1-->
    
     
      <div class="tab-pane fade in show active" id="panel12" role="tabpanel">
        <br>
        <div class="container">
        
            <div class="row">
              <div class="col-md-12">
                <button class="btn btn-primary"  (click)="addothertask()" style="float:right"> Add </button>
              </div>   
              </div>
              <br>
              <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <input type="text" placeholder="Search Employee" class="form-control" name="emp_search" [(ngModel)]="emp_search" (blur)="search(emp_search,case_search,status_search)">
              </div>
              </div>   
              <div class="col-md-4">
                <div class="form-group">
                  <input type="text" placeholder="Search Task " class="form-control" name="case_search" [(ngModel)]="case_search" (blur)="search(emp_search,case_search,status_search)">
              </div>
              </div> 
              <div class="col-md-4">
                <div class="form-group">
                  <input type="text" placeholder="Search Status" class="form-control" name="status_search" [(ngModel)]="status_search" (blur)="search(emp_search,case_search,status_search)">
              </div>
              </div> 
            <div class="col-md-12">
              <!--<h2 class="text-center" >Clients</h2>-->
              
              <br><br>
              <table class="table table-hover" >
                <thead>
                <tr>
         
                  <th>Sr.No</th>
                  <th>Task Date</th>
                  <th>Firm Name</th>
                  <th>Employee</th>
                  <th>Task Name</th>
                  <th>Status</th>
                  <th>Note</th>
                  
                  <th>Action</th>
                  <th>Edit</th>
                  <th>Delete</th>
                  
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let t of othertasks | filter: searchText; let i = index"  >
                  <td>{{i + 1}}</td>
                  <td>{{t.task_date}}</td>
                  <td>{{t.firm_name}}</td>
                  <td>{{t.emp_id}}</td>
                  <td>{{t.task_name}}</td>
                  <td>{{t.status}}</td>
                  <td>{{t.note}}</td>
                  <td><a (click)="editstatus(t)" *ngIf="t.status=='Open'">
                    <i class="fa fa-check" style="color: #00A0F0;font-size: 17px;margin-left:10px;" ></i></a>
                    <div [style.display]="viewformModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="viewModal" aria-hidden="true">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h6 class="modal-title" id="viewModal">Other Task</h6>
                          </div>
                          <div class="modal-body">
                            <form name="form" [formGroup]="othertaskform"  (ngSubmit)="otherstatusupdate(othertaskform)"  autocomplete="off" role="form" >
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="status" class="label">Status*</label>
                                    <input type="hidden"  nbInput fullWidth id="othertask_id" name="othertask_id" formControlName="othertask_id" autocomplete="off" >
                                   
                                    <select name="status" formControlName="status" class="form-control" >
                                      <option value="Open" >Open</option>
                                      <option value="Close">Close</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                             
                     <button type="submit" class="btn btn-primary" style="float:right">Update</button>
                     
                        </form>
             
            
         </div>
                   
                          <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal" (click) = "hide()">Cancel</button>
                          </div>
                        </div>
                      </div>
                      </div>  </td>

                    <td><a (click)="updateothertask(t)"  >
                      <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a>  </td>
                      <td><a (click)="deleteothertask(t)" >
                        <i class="fa fa-trash" style="color:maroon;font-size: 17px;margin-left:10px;"></i></a>  </td>
                  </tr>
                </tbody>
              </table>
              <!--<div class="alert alert-info alert-dismissible" role="alert" >
                <i class="fa fa-info-circle"></i> 
                No Records found
              </div>-->
            </div>
          </div>
        </div>

    </div>
      </div>
      <!--/.Panel 2-->
  
    </nb-card-body>
    </nb-card>
  
