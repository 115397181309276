

<nb-card>
    <nb-card-header>Add Task</nb-card-header>
    <nb-card-body>
        <form [formGroup]="addtaskform" (ngSubmit)="createtask(addtaskform)"  autocomplete="off" role="form" >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="emp_name" class="label">Employee Name*</label>

            <select  class="form-control" id="emp_name" name="emp_name" formControlName="emp_name">
                <option [ngValue]="null" [disabled]="true">Select Employee</option>
                <option *ngFor="let r of employees" [value]="r.emp_id">{{r.emp_name}}</option>
            </select>
            <div *ngIf="f.emp_name.touched && f.emp_name.invalid" class="alert alert-danger">
              <div *ngIf="f.emp_name.errors.required">Employee Name is required.</div>
             
          </div>
        </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="case_name" class="label">Company Name</label>
            <select  class="form-control" id="firm_name" name="firm_name" formControlName="firm_name">
                <option [ngValue]="null" [disabled]="true">Select Company</option>
                <option *ngFor="let r of firmNames" [value]="r.firm_name">{{r.firm_name}}</option>
            </select>
            <!--<div *ngIf="f.firm_name.touched && f.firm_name.invalid" class="alert alert-danger">
              <div *ngIf="f.firm_name.errors.required">Firm Name is required.</div>
             
          </div>-->
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="task_date" class="label">Task Date*</label><br>
              <input nbInput id="task_date" name="task_date"  formControlName="task_date"
              placeholder="Date Picker" [nbDatepicker]="formpicker">
       <nb-datepicker #formpicker></nb-datepicker>
              <div *ngIf="f.task_date.touched && f.task_date.invalid" class="alert alert-danger">
                <div *ngIf="f.task_date.errors.required">Task Date is required.</div>
               
            </div>
            </div>
          </div>
        
        <div class="col-lg-6">
          <div class="form-group">
            <label for="task_name" class="label">Task Name*</label>
            <input type="text"  nbInput fullWidth id="task_name" placeholder="Task Name" name="task_name" formControlName="task_name" autocomplete="off">
            <div *ngIf="f.task_name.touched && f.task_name.invalid" class="alert alert-danger">
              <div *ngIf="f.task_name.errors.required">Task Name is required.</div>
             
          </div>
          </div>
        </div>
      </div>
<br>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="description" class="label">Description*</label>
                
              <textarea rows="10" cols="8"  nbInput fullWidth id="description" name="description" formControlName="description" autocomplete="off" placeholder="Description"></textarea>
              <div *ngIf="f.description.touched && f.description.invalid" class="alert alert-danger">
                <div *ngIf="f.description.errors.required">Description is required.</div>
               
            </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
              <label for="status" class="label">Status*</label>
                <select name="status" formControlName="status" class="form-control" >
                    
                  <option value="Open">Open</option>
                  <option value="Close">Close</option>
                </select>
                <div *ngIf="f.status.touched && f.status.invalid" class="alert alert-danger">
                  <div *ngIf="f.status.errors.required">Status is required.</div>
                 
              </div>
                
        </div>
    </div>
        
        </div>
      <br>
      <div class="row">
        <div class="col-md-6">
          <button type="button" class="btn btn-success"  (click)="back()" style="margin:10px" >Back</button>
         
              
              <button type="submit" class="btn btn-primary" [disabled]="!addtaskform.valid">Add</button>
            </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  




