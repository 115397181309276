import { Component, OnInit } from '@angular/core';
import {TaskService} from 'app/services/task.service';
import { Task } from 'app/admin/task';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-addothertasks',
  templateUrl: './addothertasks.component.html',
  styleUrls: ['./addothertasks.component.scss']
})
export class AddothertasksComponent implements OnInit {
  addtaskform: FormGroup;
  employees:any= [];
  firmNames:any= [];

  constructor(private fb: FormBuilder,private dataService: TaskService,private router:Router) { }

  ngOnInit(){
    this.addtaskform = new FormGroup({
      emp_name: new FormControl(),
      task_name: new FormControl(),
      task_date: new FormControl(),
      description: new FormControl(),
      firm_name: new FormControl(),
      status: new FormControl(),
    

   });
   this.addtaskform = this.fb.group({
    emp_name: ['', Validators.required],
    task_name: ['', [Validators.required]],
    task_date: ['', [Validators.required]],
    description: ['', Validators.required],
    firm_name: [''],
    status: ['Open', Validators.required],
    
   
  });
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
    this.dataService.getemp(client_id)
              .subscribe((data: any) => {
                this.employees=data;
               
            });
            this.dataService.getfirmnames(client_id)
            .subscribe((data: any) => {
              this.firmNames=data;
             
          });
          }
  }

  get f(){
    return this.addtaskform.controls;
  }

          back(): void {
            this.router.navigate(['admin/othertasks']);
          };
        
          createtask(addtaskform)
          {
            let formdate=addtaskform.value.task_date;
    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let todaysdate= year + "-" + month + "-" + date;

            if (localStorage.length > 0) {
              let client_id = localStorage.getItem('client_id');
              let username = localStorage.getItem('name');
              let user_id = localStorage.getItem('user_id');

              var as=new Date(addtaskform.value.task_date);
    let d = ("0" + as.getDate()).slice(-2);
    let m = ("0" + (as.getMonth() + 1)).slice(-2);
    let y = as.getFullYear();
    let task_date= y + "-" + m + "-" + d;

              if(task_date >= todaysdate)
             {
                      this.dataService.addothertasks(user_id,addtaskform.value.emp_name,addtaskform.value.firm_name,task_date,addtaskform.value.task_name,addtaskform.value.description,addtaskform.value.status,client_id)
              .pipe()
              .subscribe(
                  data => {
                      this.router.navigate(['admin/othertasks']);
                  },
                  error => {
                  });
                      
                    }
                    else
                    {
                      alert("Select date greater than current date");
                    } 
            }
                
                
            
                  }
        
          get firm_name() { return this.addtaskform.get('firm_name'); }
          get task_date() { return this.addtaskform.get('task_date'); }
          get task_name() { return this.addtaskform.get('task_name'); }
          get description() { return this.addtaskform.get('description'); }
          get note() { return this.addtaskform.get('note'); }
          get emp_name() { return this.addtaskform.get('emp_name'); }
          get status() { return this.addtaskform.get('status'); }
         
        
        }
        