import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Casedate} from 'app/admin/casedate';
import {credential} from './credential';
@Injectable({
  providedIn: 'root'
})
export class CasedateService {
  redirectUrl: string;
  baseUrl=credential.login_url;
  constructor(private httpClient : HttpClient) { }
  getallcases(id:number,client_id:string) : Observable<Casedate[] > {
    return this.httpClient.get<Casedate[]>(this.baseUrl+'/loadccdates.php?client_id='+client_id)
  }

  public addcasedate(user_id,case_no,case_name,client_name,stage,prev_date,next_date,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/cc_date_insert.php', {user_id,case_no,case_name,client_name,stage,prev_date,next_date,client_id})
        .pipe(map(Case => {
            return Case;
        }));
  }

  public updatecasedate(case_date_id,user_id,case_no,case_name,client_name,stage,prev_date,next_date,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/cc_date_update.php', {case_date_id,user_id,case_no,case_name,client_name,stage,prev_date,next_date,client_id })
      .pipe(map(Case => {
            return Casedate;
        }));
   
  }
  removecasedate(case_date_id: number,client_id:string): Observable<Casedate[]> {
    return this.httpClient.delete<Casedate[]>(this.baseUrl+'/cc_date_delete.php?case_date_id='+case_date_id+'&client_id='+client_id);
  }
  public getcasedate(case_date_id: number,client_id:string): Observable<Casedate[]>
  {
    return this.httpClient.get<Casedate[]>(
      this.baseUrl + '/getccdates.php?case_date_id=' + case_date_id +'&client_id='+client_id
      );
  }

  public getclientnames(client_id:string): Observable<Casedate[]>
  {
    return this.httpClient.get<Casedate[]>(
      this.baseUrl + '/getclientnames.php?client_id='+client_id
      );
  }

  public getcasenames(client_id:string,client_name:string): Observable<Casedate[]>
  {
    return this.httpClient.get<Casedate[]>(
      this.baseUrl + '/getcasenames.php?client_id='+client_id+'&client_name='+client_name
      );
  }

  public getcaseno(client_id:string,client_name:string,case_name:string): Observable<Casedate[]>
  {
    return this.httpClient.get<Casedate[]>(
      this.baseUrl + '/getcaseno.php?client_id='+client_id+'&client_name='+client_name+'&case_name='+case_name
      );
  }

  public getcasedetails(client_id:string,case_id:string): Observable<Casedate[]>
  {
    return this.httpClient.get<Casedate[]>(
      this.baseUrl + '/getdashboardcasedetails.php?client_id='+client_id+'&case_id='+case_id
      );
  }
  

}
