import { Component, OnInit } from '@angular/core';
import {ReceiptService} from 'app/services/receipt.service';
import { Receipt } from 'app/admin/receipt';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-addotherreceipt',
  templateUrl: './addotherreceipt.component.html',
  styleUrls: ['./addotherreceipt.component.scss']
})
export class AddotherreceiptComponent implements OnInit {

  recform: FormGroup;
  firmNames:any=[];
  works:any=[];
  balance1:string;
  constructor(private fb: FormBuilder,private dataService: ReceiptService,private router:Router) { 
   }

  ngOnInit() {
    
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
      

    this.recform = new FormGroup({
      firm_name: new FormControl(),
      rdate: new FormControl(),
      work: new FormControl(),
      agreed_amount: new FormControl(),
      paid: new FormControl(),
      due: new FormControl(),
      balance: new FormControl(),
      installment: new FormControl()

   });

   this.recform = this.fb.group({
    firm_name: ['', Validators.required],
    paid:  ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
    rdate: ['', Validators.required], 
    work: ['', Validators.required], 
    due: [], 
    agreed_amount: [], 
    balance: [], 
    installment: ['', Validators.required], 
  });

  this.dataService.getfirmnames(client_id)
              .subscribe((data: any) => {
                this.firmNames=data;
               
            });
          }


}

get f(){
  return this.recform.controls;
}

showwork(firm_name)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

    this.dataService.getworks(client_id,firm_name)
              .subscribe((data: any) => {
                this.works=data;
   

 });
}
}



  otherback(): void {
    this.router.navigate(['admin/otherreceipts']);
  };

  showdue(paid,agreed_amount,balance)
  {
   let total_balance=parseInt(this.balance1)+ parseInt(paid);
   let due=parseInt(agreed_amount)-(parseInt(this.balance1)+ parseInt(paid));
   this.recform.patchValue({
    due:due,balance:total_balance

 });
}

 showamount(firm_name,work)
  {
 
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.getamount(client_id,work,firm_name)
              .subscribe((data: any) => {
                this.recform.patchValue({
                  agreed_amount:data[0].agreed_amount,due: data[0].due, balance: data[0].balance
         
               });
               this.balance1=data[0].balance;               
            });
          
          }

  }


  addreceipt(recform)
  {
   
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(recform.value.rdate);
    let d = ("0" + as.getDate()).slice(-2);
    let m = ("0" + (as.getMonth() + 1)).slice(-2);
    let y = as.getFullYear();
    let rdate= y + "-" + m + "-" + d;
     
   this.dataService.addotherReceipt(user_id,recform.value.firm_name,rdate,recform.value.work,recform.value.paid,recform.value.due,recform.value.balance,recform.value.installment,client_id)
      .pipe()
      .subscribe(
          data => {
              this.router.navigate(['admin/otherreceipts']);
          },
          error => {
          });     
            } 
              
          }
         
  
  
  get case_name() { return this.recform.get('case_name'); }
  get rdate() { return this.recform.get('rdate'); }
  get work() { return this.recform.get('work'); }

  get due() { return this.recform.get('due'); }

  get paid() { return this.recform.get('paid'); }

 

}
