import { Component, OnInit } from '@angular/core';
import {FirmService} from 'app/services/firm.service';
import { Firm } from 'app/admin/firm';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
@Component({
  selector: 'ngx-addfirm',
  templateUrl: './addfirm.component.html',
  styleUrls: ['./addfirm.component.scss']
})
export class AddfirmComponent implements OnInit {
  firmform: FormGroup;
  dynamicArray: Array<Firm> = [];  
  newDynamic: any = {}; 
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";  
   

  
  constructor(private fb: FormBuilder,private dataService: FirmService,private router:Router) {
  
   }
  ngOnInit() {
    
    this.newDynamic = {work: "",agreed_amount:""};  
      this.dynamicArray.push(this.newDynamic);  
    
    /*this.firmform = this.fb.group({
      firm_name: ['', Validators.required],
      city: ['', Validators.required],
      contact_person: ['', Validators.required],
      mobile: ['', [Validators.required,Validators.pattern("[0-9]{10}")]],
      
    });*/
  }

  addRow(index) {    
    this.newDynamic = {work: "",agreed_amount:""};  
    this.dynamicArray.push(this.newDynamic); 
    console.log(this.dynamicArray);  
    return true;  
}  
  
deleteRow(index) {  
    if(this.dynamicArray.length ==1) {  
     
        return false;  
    } else {  
        this.dynamicArray.splice(index, 1);  
        
        return true;  
    }  
}  

  back(): void {
    this.router.navigate(['admin/company']);
  };


  onSubmit(f)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
 console.log(this.dynamicArray);
      let len = this.dynamicArray.length;

		var wrk=[];
		var amount=[];
    
		
    for(var i=0; i < len; i++)
    {
   
      wrk.push(this.dynamicArray[i]["work"]);
      amount.push(this.dynamicArray[i]["agreed_amount"]);
     
    }
  
      this.dataService.validatefirm(f.firm_name,client_id)
      .pipe()
      .subscribe(
        
          data => {
            if(data[0]["result"]=="Already Inserted")
            {
              alert("Firm already exists");
            }
            else{

    this.dataService.addfirms(f.firm_name,f.city,f.contact_person,f.mobile,wrk,amount,user_id,client_id)
      .pipe()
      .subscribe(
          data => {
              this.router.navigate(['admin/company']);
          },
          error => {
          });
        }
        },
        error => {
        });
    }
  }
  

  get firm_name() { return this.firmform.get('firm_name'); }
  get city() { return this.firmform.get('city'); }
  get contact_person  () { return this.firmform.get('contact_person'); }
  get mobile() { return this.firmform.get('mobile'); }
  
 

}




