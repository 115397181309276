import { Component, OnInit } from '@angular/core';
import {ReportService} from 'app/services/report.service';
import { Receipt } from 'app/admin/receipt';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-duepaymentreport',
  templateUrl: './duepaymentreport.component.html',
  styleUrls: ['./duepaymentreport.component.scss']
})
export class DuepaymentreportComponent implements OnInit {
  dueform: FormGroup;
  dueform2: FormGroup;
  paymentdues:Receipt[];
  list:boolean=false;
  //public total_due:string;

  
  constructor(private fb: FormBuilder,private dataService: ReportService,private router:Router) { 
    
   }

  ngOnInit() { 
    this.dueform = new FormGroup({
      from_date: new FormControl(),
      to_date: new FormControl()
   });
   this.dueform = this.fb.group({
    from_date: ['', Validators.required],
    to_date: ['', Validators.required]
  });
  this.dueform2 = new FormGroup({
    total_due: new FormControl(),
    total_paid: new FormControl()
 });
    
  }

  getdue(dueform)
  {
    this.list=true;
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(dueform.value.from_date);
        let d = ("0" + as.getDate()).slice(-2);
        let m = ("0" + (as.getMonth() + 1)).slice(-2);
        let y = as.getFullYear();
        let from_date= y + "-" + m + "-" + d;
  
        var as1=new Date(dueform.value.to_date);
        let d1 = ("0" + as1.getDate()).slice(-2);
        let m1 = ("0" + (as1.getMonth() + 1)).slice(-2);
        let y1 = as1.getFullYear();
        let to_date= y1 + "-" + m1 + "-" + d1;


      this.dataService.duepayment(user_id,from_date,to_date,client_id)
      .subscribe(response =>{
          this.paymentdues = response.map(item =>
          {
            return new Receipt(
              item.r_id,
            item.or_id,
            item.client_id,
            item.user_id,
            item.receipt_no,
            item.rdate,
              item.case_no,
              item.paid,
              item.due,
              item.balance,
              item.case_name,
              item.firm_name,
              item.client_name,
              item.agreed_amount,
              item.total_due,
              item.total_paid,
              item.paid,
              item.installment
            
            );
          });
          this.dueform2.patchValue({
            total_due:response[0].total_due,total_paid:response[0].total_paid
        });
      });
   
            } 
              
          }
          collection(): void {
            this.router.navigate(['admin/duepayment']);
          };

          firm(): void {
            this.router.navigate(['admin/companyduepayment']);
          };
          get case_no() { return this.dueform.get('case_no'); }
          get case_name() { return this.dueform.get('case_name'); }
          get client_name() { return this.dueform.get('client_name'); }
          get rdate() { return this.dueform.get('rdate'); }
          get paid() { return this.dueform.get('paid'); }
          get due() { return this.dueform.get('due'); }
          get balance() { return this.dueform.get('balance'); }
        
 

}

