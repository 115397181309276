<nb-card>
    <nb-card-header><h5>Task Report</h5></nb-card-header>
    <nb-card-body>
      <ul class="nav nav-pills">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#panel11" role="tab" (click)="task()" style="text-decoration:none;">Court Tasks</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#panel12" (click)="othertask()"  role="tab" style="text-decoration:none;">Other Tasks</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#panel13" (click)="selftask()"  role="tab" style="text-decoration:none;">Self Task</a>
        </li>
      </ul>
      <div class="tab-content pt-0">
        <div class="tab-pane fade in show active" id="panel11" role="tabpanel">
          <br>
        <form [formGroup]="taskreportform" (ngSubmit)="gettask(taskreportform)"  autocomplete="off" role="form" >
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="from_date" class="label">From </label><br>
      
                  <input nbInput id="from_date" name="from_date"  formControlName="from_date"
                  placeholder="Form Picker" [nbDatepicker]="formpicker">
           <nb-datepicker #formpicker></nb-datepicker>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="to_date" class="label">To </label><br>
                  <input nbInput id="to_date" name="to_date"  formControlName="to_date"
                  placeholder="Form Picker" [nbDatepicker]="formpicker1">
           <nb-datepicker #formpicker1></nb-datepicker>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="emp_name" class="label">Employee</label>
                  <select  class="form-control" id="emp_name" name="emp_name" formControlName="emp_name">
                    <option [ngValue]="null" [disabled]="true">Select Employee</option>
                      <option value="All">All</option>
                    <option *ngFor="let r of employees" [value]="r.emp_id">{{r.emp_name}}</option>
                </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="status" class="label">Status</label>
                  <select  class="form-control" id="status" name="status" formControlName="status">
                    <option [ngValue]="null" [disabled]="true">Select Status</option>
                      <option value="All">All</option>
                      <option value="Open">Open</option>
                      <option value="Close">Close</option>
                    
                </select>
                </div>
              </div>
              <div class="col-md-2">
                <label class="label"></label>
                <button type="submit" class="btn btn-primary" [disabled]="!taskreportform.valid" style="margin-top:20px">Show</button>
                </div>
              
            </div>
            </form>
            <br><br>
  <div class="container">
      <div class="row" *ngIf="list">
        <div class="col-md-6">
          <div class="form-group">
            <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
        </div>
        </div>       
        <div class="col-md-12">
          <br>
          <table class="table table-hover" >
            <thead>
            <tr>
     
              <th>Sr.No</th>
              <th>Task Date</th>
              <th>Employee</th>
              <th>Client Name</th>
              <th>Case Name</th>
              <th>Task Name</th>
              <th>Description</th>
              <th>Note</th>
              <th>Status</th>
              
              
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let t of tasks | filter: searchText; let i = index"  >
     
              <td>{{i + 1}}</td>
              <td>{{t.task_date}}</td>
              <td>{{t.emp_id}}</td>
              <td>{{t.client_name}}</td>
              <td>{{t.case_name}}</td>
              <td>{{t.task_name}}</td>
              <td>{{t.description}}</td>
              <td>{{t.note}}</td>
              <td>{{t.status}}</td>
              </tr>
            </tbody>
          </table>
          <!--<div class="alert alert-info alert-dismissible" role="alert" >
            <i class="fa fa-info-circle"></i> 
            No Records found
          </div>-->
        </div>
      </div>
    </div>
    </div><!--panel1-->
  
    </div>

    </nb-card-body>
    </nb-card>
  

