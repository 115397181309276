<nb-card>
    <nb-card-header>Edit Call</nb-card-header>
    <nb-card-body>
        <form [formGroup]="editcallsform" (ngSubmit)="editcalls(editcallsform)"   autocomplete="off" role="form" >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="emp_name" class="label">Employee Name*</label>
            <input type="hidden" id="call_id" name="call_id" class="form-control" formControlName="call_id">
            <select  class="form-control" id="emp_name" name="emp_name" formControlName="emp_name" >
                <option [ngValue]="null" [disabled]="true">Select Employee</option>
                <option *ngFor="let r of employees" [value]="r.emp_id">{{r.emp_name}}</option>
            </select>
        </div>
        </div>
        <div class="col-md-6" *ngIf="case">
          <div class="form-group">
            <label for="case_name" class="label">Case Name*</label>
            <select  class="form-control" id="case_name" name="case_name" formControlName="case_name" >
                <option [ngValue]="null" [disabled]="true">Select Case</option>
                <option *ngFor="let r of caseNames" [value]="r.case_name">{{r.case_name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="task">
          <div class="form-group">
            <label for="task_name" class="label">Task Name*</label>
            <input type="text"  nbInput fullWidth id="task_name" placeholder="Task Name" name="task_name" formControlName="task_name" autocomplete="off">

            <div *ngIf="f.task_name.touched && f.task_name.invalid" class="alert alert-danger">
              <div *ngIf="f.task_name.errors.required">task Name is required.</div>
             
          </div>
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="call_date" class="label">Call Date*</label><br>
              <input nbInput id="call_date" name="call_date"  formControlName="call_date"
              placeholder="Form Picker" [nbDatepicker]="formpicker">
       <nb-datepicker #formpicker></nb-datepicker>
             
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="status" class="label">Status*</label>
                <select name="status" formControlName="status" class="form-control"  >
                    
                  <option value="Open">Open</option>
                  <option value="Close">Close</option>
                </select>
                
        </div>
    </div>
        
      </div>
<br>
      <div class="row">
        <div class="col-lg-12">
            <div class="form-group">
              <label for="note" class="label">Note*</label>
              <textarea   nbInput fullWidth id="note" placeholder="Note" name="note" formControlName="note" autocomplete="off"></textarea>
            </div>
          </div>
        
        
        </div>
      <br>
      <div class="row">
        <div class="col-md-6">
          <button type="button" class="btn btn-success"  (click)="back()" style="margin:10px">Back</button>
          
                <button type="submit" class="btn btn-primary" [disabled]="!editcallsform.valid">Update</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  




