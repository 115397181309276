export class Firm {
        public firm_id: number;
        public client_id:string;
        public firm_name:string;
        public city:string;
        public contact_person:string;
        public mobile:string;
        public firm_detail_id:string;
        public user_id:string;
        public work_date:string;
        public work:string;
        public agreed_amount:string;
        
        
        
        constructor(firm_id:number,client_id:string,firm_name: string,city:string,contact_person:string,mobile:string,firm_detail_id:string,user_id:string,work_date:string,work:string,agreed_amount:string) 
        {
          this.firm_id=firm_id;
          this.client_id=client_id;
          this.firm_name=firm_name;
          this.city=city;
          this.contact_person =contact_person;
          this.mobile=mobile;
          this.firm_detail_id=firm_detail_id;
          this.user_id=user_id;
          this.work_date=work_date;
          this.work=work;
          this.agreed_amount=agreed_amount;
          
        }
    }
          