import { Component, OnInit } from '@angular/core';
import {CaseserviceService} from 'app/services/caseservice.service';
import { Case } from 'app/admin/case';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';


@Component({
  selector: 'ngx-editcase',
  templateUrl: './editcase.component.html',
  styleUrls: ['./editcase.component.scss']
})
export class EditcaseComponent implements OnInit {
  editcase: FormGroup;
  courtNames:any=[];

  constructor(private fb: FormBuilder,private dataService: CaseserviceService,private router:Router) { 
    
  }


  ngOnInit(): void {
    this.editcase = new FormGroup({
      case_id: new FormControl(),
      case_no: new FormControl(),
      case_name: new FormControl(),
      court_name: new FormControl(),
      judge_name: new FormControl(),
      case_date: new FormControl(),
      client_name: new FormControl(),
      party_one: new FormControl(),
      party_two: new FormControl(),
      file_no: new FormControl(),
      stage: new FormControl(),
      agreed_amount: new FormControl(),
      owner_role: new FormControl(),
      
   });

   if (localStorage.length > 0) {
     
    let client_id = localStorage.getItem('client_id');
    let username = localStorage.getItem('name');

    this.dataService.getcourtnames(client_id)
              .subscribe((data: any) => {
                this.courtNames=data;
               
            });
          
  let Id = window.localStorage.getItem("editId");
  if(!Id) {
    this.router.navigate(['admin/courtcase']);
    return;
  }
  this.dataService.getcourtcaseId(+Id,client_id)
    .subscribe( data => {
     //this.viewform.controls[this.client_mobile].setValue('client_name')
      //this.client_email.nativeElement.value = 'This is new value';
      this.editcase.patchValue({
        case_id:data[0].case_id,case_no:data[0].case_no, court_name: data[0].court_name,judge_name: data[0].judge_name, case_name: data[0].case_name, case_date: data[0].case_date, client_name: data[0].client_name,party_one: data[0].party_one,party_two: data[0].party_two,stage: data[0].stage,file_no: data[0].file_no,owner_role: data[0].owner_role,agreed_amount: data[0].agreed_amount

     });

     /*this.dataService.getjudge(client_id,data[0].court_name)
     .subscribe((data: any) => {
       this.editcase.patchValue({
       
         judge_name:data[0].judge_name

      });
      
   });*/
    });
  }
  }

  showjudge(court_name)
  {
 
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.getjudge(client_id,court_name)
              .subscribe((data: any) => {
                this.editcase.patchValue({
                
                  judge_name:data[0].judge_name
         
               });
               
            });
          
          }

  }
 
  

  


  back(): void {
    this.router.navigate(['admin/courtcase']);
  }
  
  courtcaseedit(editcase)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(editcase.value.case_date);
      let d = ("0" + as.getDate()).slice(-2);
      let m = ("0" + (as.getMonth() + 1)).slice(-2);
      let y = as.getFullYear();
      let c_date= y + "-" + m + "-" + d;
      
    
    this.dataService.updatecourtcase(editcase.value.case_id,user_id,editcase.value.case_no,editcase.value.court_name,editcase.value.judge_name,c_date,editcase.value.case_name,editcase.value.client_name,editcase.value.party_one,editcase.value.party_two,editcase.value.stage,editcase.value.file_no,editcase.value.owner_role,editcase.value.agreed_amount,client_id)
  
    .pipe()
    .subscribe(
        data => {
            this.router.navigate(['admin/courtcase']);
        },
        error => {
        });
      }
  }
  
  get case_no() { return this.editcase.get('case_no'); }
  get case_name() { return this.editcase.get('case_name'); }
  get court_name() { return this.editcase.get('court_name'); }
  get case_date() { return this.editcase.get('case_date'); }
  get client_name() { return this.editcase.get('client_name'); }
  get party_one() { return this.editcase.get('party_one'); }
  get party_two() { return this.editcase.get('party_two'); }
  get file_no() { return this.editcase.get('file_no'); }
  get stage() { return this.editcase.get('stage'); }
  get owner_role() { return this.editcase.get('owner_role'); }
  get agreed_amount() { return this.editcase.get('agreed_amount'); }
}

