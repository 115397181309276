

<nb-card>
    <nb-card-header>Employee</nb-card-header>
    <nb-card-body>
        <form [formGroup]="empview"  autocomplete="off" role="form" >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="emp_name" class="label">First Name</label>
            <input type="hidden" readonly nbInput fullWidth id="emp_id" name="emp_id" formControlName="emp_id" autocomplete="off" >
            <input type="text" readonly nbInput fullWidth id="emp_name" name="emp_name" formControlName="emp_name" autocomplete="off" placeholder="Name">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="emp_email" class="label">Email</label>
            <input type="email" readonly nbInput fullWidth id="emp_email" name="emp_email" formControlName="emp_email" autocomplete="off"  placeholder="Email">
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="mobile" class="label">Mobile</label>
            <input type="text" readonly  nbInput fullWidth id="mobile" placeholder="Mobile" name="mobile" formControlName="mobile" autocomplete="off">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="city" class="label">City</label>
            <input type="text" readonly  nbInput fullWidth id="city" name="city" formControlName="city" autocomplete="off" placeholder="City">
          </div>
        </div>
      </div>
<br>
      <div class="row">
        <div class="col-lg-6">
            <div class="form-group">
              <label for="role" class="label">Role</label>
                <select name="role" id="role" disabled="disabled" formControlName="role" disabled class="form-control" >
                  <option>Choose Role</option>
                  <option *ngFor="let r of roles" [value]="r.role">{{r.role}}</option>
                </select>
                </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="address" class="label">Address</label>
            <input type="text" readonly  nbInput fullWidth id="address" name="address" formControlName="address" autocomplete="off" placeholder="Address">
                      </div>
        </div>
      </div>
      <br>
      <div class="row">
            <div class="col-lg-1">
              <button type="button"  (click)="back()" class="btn btn-success">Back</button>
            </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  




