<nb-card>
    <nb-card-header><h5>Self Tasks</h5></nb-card-header>
    <nb-card-body>
  <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
        </div>
        </div>
        <div class="col-md-6">
            <button class="btn btn-primary"  (click)="addselftask()" style="float:right"> Add</button>
          </div>    
          
        <div class="col-md-12">
          <!--<h2 class="text-center" >Clients</h2>-->
          
          <br><br>
          <table class="table table-hover" >
            <thead>
            <tr>
     
              <th>Sr.No</th>
              <th>Case Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Task Name</th>
              <th>Status</th>
              <th>View</th>
              <th>Edit</th>
              <th>Delete</th>
              
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let t of selftasks | filter: searchText; let i = index"  >
     
              <td>{{i + 1}}</td>
              <td>{{t.case_name}}</td>
              <td>{{t.start_date}}</td>
              <td>{{t.end_date}}</td>
              <td>{{t.task_name}}</td>
              <td>{{t.status}}</td>
              <td><a (click)="viewselftask(t)" >
                <i class="fa fa-eye" style="color: #00A0F0;font-size: 17px;margin-left:10px;" ></i></a>  </td>
                <td><a (click)="updateselftask(t)"  >
                  <i class="fa fa-edit" style="color:green;font-size: 17px;margin-left:10px;"></i></a>  </td>
                  <td><a (click)="deleteselftask(t)" >
                    <i class="fa fa-trash" style="color:maroon;font-size: 17px;margin-left:10px;"></i></a>  </td>
              </tr>
            </tbody>
          </table>
          <!--<div class="alert alert-info alert-dismissible" role="alert" >
            <i class="fa fa-info-circle"></i> 
            No Records found
          </div>-->
        </div>
      </div>
    </div>
    </nb-card-body>
    </nb-card>
  
