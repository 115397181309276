

<nb-card>
    <nb-card-header>Add Court</nb-card-header>
    <nb-card-body>
        <form [formGroup]="courtform" (ngSubmit)="createcourt(courtform)"  autocomplete="off" role="form" >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="court_name" class="label">Court Name*</label>

            <input type="text"  nbInput fullWidth id="court_name" name="court_name" formControlName="court_name" autocomplete="off" placeholder="Name">
            <div *ngIf="f.court_name.touched && f.court_name.invalid" class="alert alert-danger">
              <div *ngIf="f.court_name.errors.required">Court Name is required.</div>
          </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="judge_name" class="label">Judge Name*</label>
            <input type="text"  nbInput fullWidth id="judge_name" name="judge_name" formControlName="judge_name" autocomplete="off"  placeholder="Judge Name">
            <div *ngIf="f.judge_name.touched && f.judge_name.invalid" class="alert alert-danger">
              <div *ngIf="f.judge_name.errors.required">Judge Name is required.</div>
          </div>
          </div>
        </div>
      </div><br>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="court_type" class="label">Court Type*</label>
            <input type="text"  nbInput fullWidth id="court_type" placeholder="Court Type" name="court_type" formControlName="court_type" autocomplete="off">
            <div *ngIf="f.court_type.touched && f.court_type.invalid" class="alert alert-danger">
              <div *ngIf="f.court_type.errors.required">Court Type is required.</div>
          </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="city" class="label">City*</label>
            <input type="text"  nbInput fullWidth id="city" name="city" formControlName="city" autocomplete="off" placeholder="City">
            <div *ngIf="f.city.touched && f.city.invalid" class="alert alert-danger">
              <div *ngIf="f.city.errors.required">City is required.</div>
          </div>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        
            <div class="col-lg-6">
              <button type="button" class="btn btn-success"   (click)="back()" style="margin:10px">Back</button>
          
              <button type="submit" class="btn btn-primary" [disabled]="!courtform.valid">Add</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  





