<nb-card>
    <nb-card-header>Add Case Date</nb-card-header>
    <nb-card-body>
        <form [formGroup]="casedateform" (ngSubmit)="createcasedate(casedateform)"  autocomplete="off" role="form" >
      
      <div class="row">
        
          <div class="col-md-6">
            <div class="form-group">
              <label for="client_name" class="label">Client Name*</label>
              <input type="text"  readonly nbInput fullWidth id="client_name" name="client_name" formControlName="client_name" autocomplete="off" placeholder="Client Name">
            </div>
          </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="case_name" class="label">Case Name*</label>
            <input type="text"  readonly nbInput fullWidth id="case_name" name="case_name" formControlName="case_name" autocomplete="off" placeholder="Case Name">
          </div>
        </div>
      </div>
      
<br>
<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label for="case_no" class="label">Case No</label>
      <input type="text"  readonly nbInput fullWidth id="case_no" name="case_no" formControlName="case_no" autocomplete="off" placeholder="Case No.">
    </div>
  </div>
  <div class="col-lg-6">
    <div class="form-group">
      <label for="court_name" class="label">Court Name</label>
      <input type="text" readonly  nbInput fullWidth id="court_name" name="court_name" formControlName="court_name" autocomplete="off" placeholder="Court Name">
      </div>
    </div>
</div><br>
<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label for="party_two" class="label">Defendant/Respondent/Opponent/Accused</label>

      <input type="text" readonly  nbInput fullWidth id="party_two" name="party_two" formControlName="party_two" autocomplete="off" placeholder="Defendant/Respondent/Opponent/Accused">
      
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label for="stage" class="label">Stage</label>

      <input type="text" nbInput fullWidth id="stage" name="stage" formControlName="stage" autocomplete="off" placeholder="Stage">
    </div>
  </div>
</div>
<br>

      <div class="row">
        
        <div class="col-md-6">
            <div class="form-group">
              <label for="prev_date" class="label">Previous Date* </label><br>
              <input nbInput id="prev_date" name="prev_date"  formControlName="prev_date"
              placeholder="Date Picker" [nbDatepicker]="formpicker">
       <nb-datepicker #formpicker></nb-datepicker>
            </div>
          </div>
  
      <div class="col-md-6">
        <div class="form-group">
          <label for="next_date" class="label">Next Date*</label><br>
          <input nbInput id="next_date" name="next_date"  formControlName="next_date"
          placeholder="Date Picker" [nbDatepicker]="formpicker1">
   <nb-datepicker #formpicker1></nb-datepicker>
       
        <div *ngIf="f.next_date.touched && f.next_date.invalid" class="alert alert-danger">
          <div *ngIf="f.next_date.errors.required">Next Date is required.</div>
      </div>
      </div>
       </div>
    </div>
    <br>
      <div class="row">
        
            <div class="col-lg-1">
              <button type="button" class="btn btn-success"  (click)="back()" >Back</button>
            </div>
            <div class="col-lg-1">
              <button type="submit" class="btn btn-primary" [disabled]="!casedateform.valid">Add</button>
              </div>
      </div>
     
     
      </form>
    </nb-card-body>
  </nb-card>
  






