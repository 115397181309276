import { Component, OnInit } from '@angular/core';
import {TaskService} from 'app/services/task.service';
import { Task } from 'app/admin/task';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-viewtask',
  templateUrl: './viewtask.component.html',
  styleUrls: ['./viewtask.component.scss']
})
export class ViewtaskComponent implements OnInit {
  viewtaskform: FormGroup;
  employees:any= [];
  caseNames:any= [];
  case:boolean=false;

  constructor(private fb: FormBuilder,private dataService: TaskService,private router:Router) { }

  ngOnInit(){
    this.viewtaskform = new FormGroup({
      task_id: new FormControl(),
      emp_name: new FormControl(),
      task_name: new FormControl(),
      task_date: new FormControl(),
      description: new FormControl(),
      case_name: new FormControl(),
      status: new FormControl(),
      start_date:new FormControl(),
      end_date: new FormControl(),
      note: new FormControl()


    

   });
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      
    this.dataService.getemp(client_id)
              .subscribe((data: any) => {
                this.employees=data;
               
            });
            this.dataService.getcasenames(client_id)
            .subscribe((data: any) => {
              this.caseNames=data;
             
          });

          let Id = window.localStorage.getItem("editId");
    if(!Id) {
      this.router.navigate(['admin/court']);
      return;
    }
    this.dataService.gettaskId(+Id,client_id)
      .subscribe( data => {
       //this.viewform.controls[this.client_mobile].setValue('client_name')
        //this.client_email.nativeElement.value = 'This is new value';
        if(data[0].case_name==null || data[0].case_name=="" )
       {
         this.case=false;
        this.viewtaskform.patchValue({
          task_id:data[0].task_id,emp_name: data[0].emp_id, task_name: data[0].task_name, task_date: data[0].task_date,description: data[0].description,status: data[0].status,start_date: data[0].start_date,end_date: data[0].end_date,note: data[0].note
      
       });

       }
       else
       {
         this.case=true;
        this.viewtaskform.patchValue({
          task_id:data[0].task_id, case_name: data[0].case_name, emp_name: data[0].emp_id, task_name: data[0].task_name, task_date: data[0].task_date,description: data[0].description,status: data[0].status,start_date: data[0].start_date,end_date: data[0].end_date,note: data[0].note
      
        
      
       });
       }
      });
          }
  }

  
          back(): void {
            this.router.navigate(['admin/task']);
          };
          get case_name() { return this.viewtaskform.get('case_name'); }
          get task_date() { return this.viewtaskform.get('task_date'); }
          get task_name() { return this.viewtaskform.get('task_name'); }
          get description() { return this.viewtaskform.get('description'); }
          get note() { return this.viewtaskform.get('note'); }
          get emp_name() { return this.viewtaskform.get('emp_name'); }
          get status() { return this.viewtaskform.get('status'); }

}
