import { Component, OnInit } from '@angular/core';
import { Calls } from 'app/admin/calls';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { CallsService } from 'app/services/calls.service';

@Component({
  selector: 'ngx-empcalls',
  templateUrl: './empcalls.component.html',
  styleUrls: ['./empcalls.component.scss']
})
export class EmpcallsComponent implements OnInit {
  calls: Calls[];
  cat:number;
  public searchText:string;
  constructor(private dataService: CallsService ,private router:Router) { }

  ngOnInit(): void {
    this.getcalldetails();
  }
  getcalldetails()
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
    this.dataService.getallempcalls(this.cat,client_id,user_id).subscribe(response =>
      {
        this.calls = response.map(item =>
        {
          return new Calls(
            item.call_id,
            item.client_id,
            item.user_id,
            item.case_name,
            item.task_name,
             item.call_date,
              item.emp_id,
              item.note, 
              item.status
          );
        });
      });

    }
  }
  
  addcalls(): void {
    this.router.navigate(['employee/addcalls']);
  };
  back(): void {
    this.router.navigate(['employee/calls']);
  };
  
  viewcalls(c: Calls): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.call_id.toString());
    this.router.navigate(['employee/viewcalls']);
  };
  deletecalls(c:Calls)
  {
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
    this.dataService.removecalls(c.call_id,client_id)
    
    .subscribe( data => {
      //this.employees = this.employees.filter(u => u !== emp);
      this.getcalldetails();
    })
  }
  }
  updatecalls(c: Calls): void {
    window.localStorage.removeItem("editId");
    window.localStorage.setItem("editId", c.call_id.toString());
    this.router.navigate(['employee/editcalls']);
  };
  
  }