import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Case} from 'app/admin/case';
import {credential} from './credential';

@Injectable({
  providedIn: 'root'
})
export class CaseserviceService {

  redirectUrl: string;
  baseUrl=credential.login_url;
  constructor(private httpClient : HttpClient) { }
  getAllCases(id:number,client_id:string) : Observable<Case[] > {
    return this.httpClient.get<Case[]>(this.baseUrl+'/loadcourtcases.php?client_id='+client_id)
  }

  public addCourtCase(user_id,case_no,court_name,judge_name,case_date,case_name,client_name,party_one,party_two,stage,file_no,owner_role,agreed_amount,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/courtcase_insert.php', {user_id,case_no,court_name,judge_name,case_date,case_name,client_name,party_one,party_two,stage,file_no,owner_role,agreed_amount,client_id})
        .pipe(map(Case => {
            return Case;
        }));
  }

  public updatecourtcase(case_id,user_id,case_no,court_name,judge_name,case_date,case_name,client_name,party_one,party_two,stage,file_no,owner_role,agreed_amount,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/courtcase_update.php', {case_id,user_id,case_no,court_name,judge_name,case_date,case_name,client_name,party_one,party_two,stage,file_no,owner_role,agreed_amount,client_id })
      .pipe(map(Case => {
            return Case;
        }));
   
  }
  removecourtcase(case_id: number,client_id:string): Observable<Case[]> {
    return this.httpClient.delete<Case[]>(this.baseUrl+'/courtcase_delete.php?case_id='+case_id+'&client_id='+client_id);
  }
  public getcourtcaseId(case_id: number,client_id:string): Observable<Case[]>
  {
    return this.httpClient.get<Case[]>(
      this.baseUrl + '/getcourtcase.php?case_id=' + case_id +'&client_id='+client_id
      );
  }

  public getcourtnames(client_id:string): Observable<Case[]>
  {
    return this.httpClient.get<Case[]>(
      this.baseUrl + '/getcourtnames.php?client_id='+client_id
      );
  }

  public getjudge(client_id:string,court_name:string): Observable<Case[]>
  {
    return this.httpClient.get<Case[]>(
      this.baseUrl + '/getjudge.php?client_id='+client_id+'&court_name='+court_name
      );
  }
  

}


