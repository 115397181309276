export class Empexpense {
    public expense_id: number;
    public client_id:string;
    public user_id: string;
    public emp_id:string;
    public expense_date:string;
    public expense:string;
    public amount:string;
    public total_amount:string;
    
    
    constructor(expense_id:number,client_id:string,user_id: string,emp_id:string,expense_date:string,expense:string,amount:string,total_amount:string) {
      this.expense_id = expense_id;
      this.client_id=client_id;
      this.user_id=user_id;
      this.emp_id=emp_id;
      this.expense_date=expense_date;
      this.expense = expense;
      this.amount=amount;
      this.total_amount=total_amount;
      
    }
}
