import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Selftask} from 'app/employee/selftask';
import {credential} from './credential';
@Injectable({
  providedIn: 'root'
})
export class SelftaskService {
  redirectUrl: string;
  baseUrl=credential.login_url;
  constructor(private httpClient : HttpClient) { }
  getalltasks(id:number,client_id:string,user_id:string) : Observable<Selftask[] > {
    return this.httpClient.get<Selftask[]>(this.baseUrl+'/loadselftask.php?client_id='+client_id+'&user_id='+user_id)
  }

  public addselftasks(user_id,case_name,start_date,end_date,task_name,description,status,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/selftask_insert.php', {user_id,case_name,start_date,end_date,task_name,description,status,client_id})
        .pipe(map(Task => {
            return Task;
        }));
  }

  public updateselftasks(selftask_id,user_id,case_name,start_date,end_date,task_name,description,status,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/selftask_update.php', {selftask_id,user_id,case_name,start_date,end_date,task_name,description,status,client_id })
      .pipe(map(Task => {
            return Task;
        }));
   
  }
  removeselftasks(selftask_id: number,client_id:string,user_id:string): Observable<Selftask[]> {
    return this.httpClient.delete<Selftask[]>(this.baseUrl+'/selftask_delete.php?selftask_id='+selftask_id+'&client_id='+client_id+'&user_id='+user_id);
  }
  public getselftaskId(eid: number,client_id:string): Observable<Selftask[]>
  {
    return this.httpClient.get<Selftask[]>
    (this.baseUrl + '/getselftasks.php?selftask_id='+eid +'&client_id='+client_id);
  }
  public getemp(client_id:string): Observable<Selftask[]>
  {
    return this.httpClient.get<Selftask[]>
    (this.baseUrl + '/getempnames.php?client_id='+client_id);
  }
  public getcasenames(client_id:string): Observable<Selftask[]>
  {
    return this.httpClient.get<Selftask[]>
    (this.baseUrl + '/getcases.php?client_id='+client_id);
  }
 
}
