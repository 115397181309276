import { NbMenuItem } from '@nebular/theme';
import { NbIconLibraries } from '@nebular/theme';


export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home-outline',
    link: '/admin/dashboard',
    home: true,
  },
  /*{
    title: 'IoT Dashboard',
    icon: 'home-outline',
    link: '/pages/iot-dashboard',
  },
  {
    title: 'FEATURES',
    group: true,
  },*/
  {
    title: 'Masters',
    icon: 'menu-2-outline',
    children: [
      {
    title: 'Employees',
    icon: 'arrow-right-outline',
    link: '/admin/employee'
    
  },
  
    /* {
    title: 'Courts',
    icon: 'arrow-right-outline',
    link: '/admin/court'
    
  },*/
  {
    title: 'Company',
    icon: 'arrow-right-outline',
    link: '/admin/company'
    
  },
 ],
  },
  {
    title: 'New Case',
    icon: 'briefcase-outline',
    link: '/admin/courtcase',
  },
  {
    title: 'Court Case Date',
    icon: 'clipboard-outline',
    link: '/admin/casedate',
  },

  {
    title: 'Calendar',
    icon: 'calendar-outline',
    link: '/admin/calendar',
  },
  
  {
    title: 'Task',
    icon: 'checkmark-square-outline',
    link: '/admin/task',
  },
 
  {
    title: 'Receipt',
    icon: 'file-outline',
    link: '/admin/receipt'
  },
  {
    title: 'Expenses',
    icon: 'activity-outline',
    link: '/admin/expense'
  },

  {
    title: 'Meetings',
    icon: 'people-outline',
    link: '/admin/meetings'
  },

  {
    title: 'Calls',
    icon: 'phone-outline',
    link: '/admin/calls'
  },

  {
    title: 'Reports',
    icon: 'file-text-outline',
    children: [
      {
        title: 'Datewise Case Date Report',
      
        link: '/admin/casedatereport'
        
      },
      {
    title: 'Datewise Task Report',

    link: '/admin/taskreport'
    
  }, 
  
  {
    title: 'Datewise Collection Report',
  
    link: '/admin/datewisecollection'
    
  },
  
  {
    title: 'Datewise Payment Due Report',
   
    link: '/admin/duepayment'
    
  },
  {
    title: 'Expenses Report',
  
    children: [
      {
        title: 'Office Expenses',
        link: '/admin/expensereport'
        
      },
    
      {
        title: 'Employee Expenses',
        link: '/admin/empexpensereport'
        
      },
    ]
    
  },
 ],
  },
  /*
  {
    title: 'Extra Components',
    icon: 'message-circle-outline',
    children: [
      {
        title: 'Calendar',
        link: '/pages/extra-components/calendar',
      },
      {
        title: 'Progress Bar',
        link: '/pages/extra-components/progress-bar',
      },
      {
        title: 'Spinner',
        link: '/pages/extra-components/spinner',
      },
      {
        title: 'Alert',
        link: '/pages/extra-components/alert',
      },
      {
        title: 'Calendar Kit',
        link: '/pages/extra-components/calendar-kit',
      },
      {
        title: 'Chat',
        link: '/pages/extra-components/chat',
      },
    ],
  },
  {
    title: 'Maps',
    icon: 'map-outline',
    children: [
      {
        title: 'Google Maps',
        link: '/pages/maps/gmaps',
      },
      {
        title: 'Leaflet Maps',
        link: '/pages/maps/leaflet',
      },
      {
        title: 'Bubble Maps',
        link: '/pages/maps/bubble',
      },
      {
        title: 'Search Maps',
        link: '/pages/maps/searchmap',
      },
    ],
  },
  {
    title: 'Charts',
    icon: 'pie-chart-outline',
    children: [
      {
        title: 'Echarts',
        link: '/pages/charts/echarts',
      },
      {
        title: 'Charts.js',
        link: '/pages/charts/chartjs',
      },
      {
        title: 'D3',
        link: '/pages/charts/d3',
      },
    ],
  },
  {
    title: 'Editors',
    icon: 'text-outline',
    children: [
      {
        title: 'TinyMCE',
        link: '/pages/editors/tinymce',
      },
      {
        title: 'CKEditor',
        link: '/pages/editors/ckeditor',
      },
    ],
  },
  {
    title: 'Tables & Data',
    icon: 'grid-outline',
    children: [
      {
        title: 'Smart Table',
        link: '/pages/tables/smart-table',
      },
      {
        title: 'Tree Grid',
        link: '/pages/tables/tree-grid',
      },
    ],
  },
  {
    title: 'Miscellaneous',
    icon: 'shuffle-2-outline',
    children: [
      {
        title: '404',
        link: '/pages/miscellaneous/404',
      },
    ],
  },*/
  /*{
    title: 'Auth',
    icon: 'lock-outline',
    children: [
      {
        title: 'Login',
        link: '/auth/login',
      },
      {
        title: 'Register',
        link: '/auth/register',
      },
      {
        title: 'Request Password',
        link: '/auth/request-password',
      },
      {
        title: 'Reset Password',
        link: '/auth/reset-password',
      },
      {
        title: 'Super Admin',
        link: '/auth/super-admin',
      },
    ],
  },*/
];
