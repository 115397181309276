import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Receipt} from 'app/admin/receipt';
import {credential} from './credential';
import {Casedate} from 'app/admin/casedate';
@Injectable({
  providedIn: 'root'
})
export class ReceiptService {
  redirectUrl: string;
  baseUrl=credential.login_url;
  constructor(private httpClient : HttpClient) { }
  getallreceipts(id:number,client_id:string) : Observable<Receipt[] > {
    return this.httpClient.get<Receipt[]>(this.baseUrl+'/loadreceipts.php?client_id='+client_id)
  }

  public addReceipt(user_id,case_no,case_name,client_name,rdate,paid,due,balance,installment,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/receipt_insert.php', {user_id,case_no,case_name,client_name,rdate,paid,due,balance,installment,client_id})
        .pipe(map(Case => {
            return Case;
        }));
  }

  public updateReceipt(r_id,user_id,case_no,case_name,client_name,rdate,paid,due,balance,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/receipt_update.php', {r_id,user_id,case_no,case_name,client_name,rdate,paid,due,balance,client_id})
      .pipe(map(Case => {
            return Receipt;
        }));
   
  }
  removeReceipt(r_id: number,client_id:string): Observable<Receipt[]> {
    return this.httpClient.delete<Receipt[]>(this.baseUrl+'/receipt_delete.php?r_id='+r_id+'&client_id='+client_id);
  }
  public getReceipt(r_id: number,client_id:string): Observable<Receipt[]>
  {
    return this.httpClient.get<Receipt[]>(
      this.baseUrl + '/getreceipts.php?r_id=' + r_id +'&client_id='+client_id
      );
  }


  public getcasedetails(client_id:string,case_no:string): Observable<Receipt[]>
  {
    return this.httpClient.get<Receipt[]>(
      this.baseUrl + '/getcasedetails.php?client_id='+client_id+'&case_no='+case_no
      );
  }

  public autoComplete(client_id:string,case_no:string): Observable<Receipt[]>
  {
    return this.httpClient.get<Receipt[]>(
      this.baseUrl + '/searchcaseno.php?client_id='+client_id+'&case_no='+case_no
      );
  }

  getallotherreceipts(id:number,client_id:string) : Observable<Receipt[] > {
    return this.httpClient.get<Receipt[]>(this.baseUrl+'/loadotherreceipts.php?client_id='+client_id)
  }

  public addotherReceipt(user_id,firm_name,rdate,work,paid,due,balance,installment,client_id) {
    return this.httpClient.post<any>(this.baseUrl + '/otherreceipts_insert.php', {user_id,firm_name,rdate,work,paid,due,balance,installment,client_id})
        .pipe(map(Receipt => {
            return Receipt;
        }));
  }

  removeotherReceipt(or_id: number,client_id:string): Observable<Receipt[]> {
    return this.httpClient.delete<Receipt[]>(this.baseUrl+'/otherreceipt_delete.php?or_id='+or_id+'&client_id='+client_id);
  }

  public getfirmnames(client_id:string): Observable<Receipt[]>
  {
    return this.httpClient.get<Receipt[]>
    (this.baseUrl + '/getfirmnames.php?client_id='+client_id);
  }

  public getworks(client_id:string,firm_name:string): Observable<Receipt[]>
  {
    return this.httpClient.get<Receipt[]>
    (this.baseUrl + '/getworks.php?client_id='+client_id+'&firm_name='+firm_name);
  }

  public getviewworks(client_id:string,firm_name:string): Observable<Receipt[]>
  {
    return this.httpClient.get<Receipt[]>
    (this.baseUrl + '/getviewworks.php?client_id='+client_id+'&firm_name='+firm_name);
  }

  public getamount(client_id:string,work:string,firm_name:string): Observable<Receipt[]>
  {
    return this.httpClient.get<Receipt[]>(
      this.baseUrl + '/getamount.php?client_id='+client_id+'&work='+work+'&firm_name='+firm_name
      );
  }


  public getotherReceipt(or_id: number,client_id:string): Observable<Receipt[]>
  {
    return this.httpClient.get<Receipt[]>(
      this.baseUrl + '/getotherreceipts.php?or_id=' + or_id +'&client_id='+client_id
      );
  }

  public validatecasereceipt(case_no:string,case_name:string,client_name:string,client_id:string): Observable<Receipt[]>
  {
    return this.httpClient.get<Receipt[]>
    (this.baseUrl + '/validatecasereceipt.php?client_id='+client_id+'&case_no='+case_no+'&case_name='+case_name+'&client_name='+client_name);
  }

  
  public getclientnames(client_id:string): Observable<Casedate[]>
  {
    return this.httpClient.get<Casedate[]>(
      this.baseUrl + '/getclientnames.php?client_id='+client_id
      );
  }

  public getcasenames(client_id:string,client_name:string): Observable<Casedate[]>
  {
    return this.httpClient.get<Casedate[]>(
      this.baseUrl + '/getduecases.php?client_id='+client_id+'&client_name='+client_name
      );
  }

  public getcaseno(client_id:string,client_name:string,case_name:string): Observable<Casedate[]>
  {
    return this.httpClient.get<Casedate[]>(
      this.baseUrl + '/getcaseno.php?client_id='+client_id+'&client_name='+client_name+'&case_name='+case_name
      );
  }

  public getheader(client_id:string): Observable<Casedate[]>
  {
    return this.httpClient.get<Casedate[]>(
      this.baseUrl + '/getprintheader.php?client_id='+client_id
      );
  }



}
