export class Receipt {
    public r_id: number;
    public or_id: number;
    public receipt_no:string;
    public client_id:string;
    public user_id: string;
    public rdate:string;
    public case_no:string;
    public case_name:string;
    public firm_name:string;
    public client_name:string;
    public paid:string;
    public due:string;
    public balance:string;
    public agreed_amount:string;
    public total_due:string;
    public total_paid:string;
    public work:string;
    public installment:string;
    
    
    
    
    constructor(r_id:number,or_id:number,client_id:string,user_id: string,receipt_no:string,rdate:string,case_no:string,paid:string,due:string,balance:string,case_name:string,firm_name:string,client_name:string,agreed_amount:string,total_due:string,total_paid:string,work:string,installment:string) {
      this.r_id = r_id;
      this.or_id=or_id;
      this.client_id=client_id;
      this.user_id=user_id;
      this.receipt_no=receipt_no;
      this.rdate=rdate;
      this.case_no = case_no;
      this.case_name=case_name;
      this.firm_name=firm_name;
      this.client_name=client_name;
      this.paid=paid;
      this.due=due;
      this.balance=balance;
      this.agreed_amount=agreed_amount;
      this.total_due=total_due;
      this.total_paid=total_paid;
      this.work=work;
      this.installment=installment;

      
    }
}