<nb-card>
    <nb-card-header><h5>Case Date Report</h5></nb-card-header>
    <nb-card-body>
        <form [formGroup]="ccreportform" (ngSubmit)="getccdates(ccreportform)"  autocomplete="off" role="form" >
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="from_date" class="label">From </label><br>
      
                  <input nbInput id="from_date" name="from_date"  formControlName="from_date"
                  placeholder="Form Picker" [nbDatepicker]="formpicker">
           <nb-datepicker #formpicker></nb-datepicker>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="to_date" class="label">To </label><br>
                  <input nbInput id="to_date" name="to_date"  formControlName="to_date"
                  placeholder="Form Picker" [nbDatepicker]="formpicker1">
           <nb-datepicker #formpicker1></nb-datepicker>                </div>
              </div>
              <div class="col-md-1">
                <button type="submit" class="btn btn-primary" [disabled]="!ccreportform.valid" style="margin-top:20px;">Show</button>
                </div>
              
            </div>
            </form>
            <br><br>
  <div class="container">
      <div class="row" *ngIf="list">
        <div class="col-md-6">
          <div class="form-group">
            <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
        </div>
        </div>       
        <div class="col-md-12">
          <br>
          <table class="table table-hover" >
            <thead>
            <tr>
     
              <th>Sr.No</th>
              <th>Prev. Date</th>
                <th>Court's Name</th>
                <th>Case No.</th>
                <th>Name of Parties</th>
                <th>Stage</th>
                <th>Next Date</th>
              
              
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let c of ccdates | filter: searchText; let i = index"  >
     
              <td>{{i + 1}}</td>
              <td>{{c.prev_date}}</td>
              <td>{{c.court_name}}</td>
              <td>{{c.case_no}}</td>
              <td><b>{{c.client_name}}</b> |  {{c.party_two}}</td>
              <td>{{c.stage}}</td>
              <td>{{c.next_date}}</td>
              </tr>
            </tbody>
          </table>
          <!--<div class="alert alert-info alert-dismissible" role="alert" >
            <i class="fa fa-info-circle"></i> 
            No Records found
          </div>-->
        </div>
      </div>
    </div>
    </nb-card-body>
    </nb-card>
  

