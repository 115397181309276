<nb-card>
    <nb-card-header><h5>Expenses Report</h5></nb-card-header>
    <nb-card-body>
        <form [formGroup]="expreportform" (ngSubmit)="getexpense(expreportform)"  autocomplete="off" role="form" >
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="from_date" class="label">From </label><br>
      
                  <input nbInput id="from_date" name="from_date"  formControlName="from_date"
                  placeholder="Form Picker" [nbDatepicker]="formpicker">
           <nb-datepicker #formpicker></nb-datepicker>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="to_date" class="label">To </label><br>
                  <input nbInput id="to_date" name="to_date"  formControlName="to_date"
                  placeholder="Form Picker" [nbDatepicker]="formpicker1">
           <nb-datepicker #formpicker1></nb-datepicker>                </div>
              </div>
              <div class="col-md-1">
                <button type="submit" class="btn btn-primary" [disabled]="!expreportform.valid" style="margin-top:20px;">Show</button>
                </div>
              
            </div>
            </form>
            <br><br>
  <div class="container">
      <div class="row" *ngIf="list">
        <div class="col-md-6">
          <div class="form-group">
            <input type="text" placeholder="Search Here" class="form-control" [(ngModel)]="searchText">
        </div>
        </div>
        <div class="col-md-6">
          <div style="float:right">
            <h5>Total Amount: Rs. {{total_amount}} </h5>
          </div>
          
        </div>  
       
        <div class="col-md-12">
          <br>
          <table class="table table-hover" >
            <thead>
            <tr>
     
              <th>Sr.No</th>
              <th>Date</th>
              <th>Expense</th>
              <th>Amount</th>
              
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let t of expenses | filter: searchText; let i = index"  >
     
              <td>{{i + 1}}</td>
              <td>{{t.expense_date}}</td>
              <td>{{t.expense}}</td>
              <td>{{t.amount}}</td>
              </tr>
            </tbody>
          </table>
          <!--<div class="alert alert-info alert-dismissible" role="alert" >
            <i class="fa fa-info-circle"></i> 
            No Records found
          </div>-->
        </div>
      </div>
    </div>
    </nb-card-body>
    </nb-card>
  

