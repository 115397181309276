import { Component, OnInit } from '@angular/core';
import {ReportService} from 'app/services/report.service';
import { Casedate } from 'app/admin/casedate';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'ngx-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  ccreportform: FormGroup;
  ccdates:Casedate[];
  list:boolean=false;

  
  constructor(private fb: FormBuilder,private dataService: ReportService,private router:Router) { 
    
   }

  ngOnInit() { 
    this.ccreportform = new FormGroup({
      from_date: new FormControl(),
      to_date: new FormControl()
   });
   this.ccreportform = this.fb.group({
    from_date: ['', Validators.required],
    to_date: ['', Validators.required]
  });

  this.list=true;
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');
      this.dataService.allcalendar(user_id,client_id)
      .subscribe(response =>{
          this.ccdates = response.map(item =>
          {
            return new Casedate(
              item.case_date_id,
              item.case_id,
              item.client_id,
              item.user_id,
              item.case_no,
              item.case_name,
              item.prev_date,
              item.client_name,
              item.next_date,
              item.stage,
              item.court_name,
            item.party_two

            );
          });
        });

              
            } 
              
          }
  

  getccdates(ccreportform)
  {
    this.list=true;
    if (localStorage.length > 0) {
      let client_id = localStorage.getItem('client_id');
      let username = localStorage.getItem('name');
      let user_id = localStorage.getItem('user_id');

      var as=new Date(ccreportform.value.from_date);
        let d = ("0" + as.getDate()).slice(-2);
        let m = ("0" + (as.getMonth() + 1)).slice(-2);
        let y = as.getFullYear();
        let from_date= y + "-" + m + "-" + d;
  
        var as1=new Date(ccreportform.value.to_date);
        let d1 = ("0" + as1.getDate()).slice(-2);
        let m1 = ("0" + (as1.getMonth() + 1)).slice(-2);
        let y1 = as1.getFullYear();
        let to_date= y1 + "-" + m1 + "-" + d1;
      this.dataService.calendar(user_id,from_date,to_date,client_id)
      .subscribe(response =>{
          this.ccdates = response.map(item =>
          {
            return new Casedate(
              item.case_date_id,
              item.case_id,
              item.client_id,
              item.user_id,
              item.case_no,
              item.case_name,
              item.prev_date,
              item.client_name,
              item.next_date,
              item.stage,
              item.court_name,
              item.party_two
            );
          });
        });

              
            } 
              
          }
        
 

}

